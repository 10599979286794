import { Row, Col, Checkbox, Space } from "antd";
import React from "react";
import { Text, Button } from "../../atoms";
import SelectionTable from "../../molecules/SelectionTable";
import SiteIdInput, { ISiteIdInputType } from "../../molecules/SiteIdInput";
import ActionButtons, { IButtonConfig } from "../../organisms/ActionButtons";

import "./styles.scss";

interface IContentTemplateType {
  title?: string;
  handleTourSelection?: () => void;
  showSelectionTables?: boolean;
  showButtons?: boolean;
  showSearchBar?: boolean;
  searchBarOptions?: ISiteIdInputType;
  showSwitchTasksSelection?: boolean;
  switchTableData?: {
    loading?: boolean;
    error?: string | null;
    dataSource: any;
    columns: any;
    selectedRowKeys?: Array<any>;
    onSelectionHandler: (keys: any, records: any) => void;
    disabled?: boolean;
    switchSelectAll?: boolean;
    onSwitchSelectAllHandler?: (e: any) => void;
  };
  showIAPTasksSelection?: boolean;
  iapTableData?: {
    loading?: boolean;
    error?: string | null;
    dataSource: any;
    columns: any;
    selectedRowKeys?: Array<any>;
    onSelectionHandler: (keys: any, records: any) => void;
    disabled?: boolean;
    iapSelectAll?: boolean;
    onIapSelectAllHandler?: (e: any) => void;
  };
  showCustomSelectionTable?: boolean;
  CustomSelectionTable?: JSX.Element;
  buttonsConfig?: Array<IButtonConfig>;
  showMainTable?: boolean;
  mainTable?: any;
  className?: string;
}

const ContentTemplate: React.FC<IContentTemplateType> = ({
  title,
  handleTourSelection,
  showSelectionTables,
  showButtons,
  showSearchBar,
  showSwitchTasksSelection,
  showIAPTasksSelection,
  switchTableData,
  iapTableData,
  showCustomSelectionTable,
  CustomSelectionTable,
  buttonsConfig,
  searchBarOptions,
  showMainTable,
  mainTable,
  children,
  className,
}) => {
  return (
    <section className={"flex flex--column template-container"}>
      {title && (
        <div className="title-container gap--bottom">
          <Text title level={4}>
            {title}
          </Text>
          {handleTourSelection && (
            <Button
              size="small"
              className="take-a-tour-button"
              type="default"
              onClick={handleTourSelection}
            >
              Take a tour
            </Button>
          )}
        </div>
      )}
      {children}
      {showSearchBar && searchBarOptions && (
        <div className="search-bar-container gap--bottom site-search-box">
          <SiteIdInput {...searchBarOptions} />
        </div>
      )}
      {showSelectionTables && (
        <Space align="start" direction="horizontal" className="gap--bottom">
          {showSwitchTasksSelection && switchTableData && (
            <SelectionTable
              onSelectionHandler={switchTableData.onSelectionHandler}
              getCheckboxProps={()=> ({ disabled: switchTableData.disabled })}
              tableProps={{
                dataSource: switchTableData.dataSource,
                columns: switchTableData.columns,
                loading: switchTableData.loading,
              }}
              error={switchTableData.error}
              selectedRowKeys={switchTableData.selectedRowKeys}
            />
          )}
          {showIAPTasksSelection && iapTableData && (
            <SelectionTable
              onSelectionHandler={iapTableData.onSelectionHandler}
              getCheckboxProps={()=> ({ disabled: iapTableData.disabled })}
              tableProps={{
                dataSource: iapTableData.dataSource,
                columns: iapTableData.columns,
                loading: iapTableData.loading,
              }}
              error={iapTableData.error}
              selectedRowKeys={iapTableData.selectedRowKeys}
            />
          )}
          {showCustomSelectionTable &&
            CustomSelectionTable &&
            CustomSelectionTable}
        </Space>
      )}
      {switchTableData && iapTableData && switchTableData.switchSelectAll && iapTableData.iapSelectAll && (
        <Row justify="space-between">
          <Col className="gap--bottom">
            <Checkbox onChange={switchTableData.onSwitchSelectAllHandler} value="switch">
              <h4>Switch Tasks</h4>
            </Checkbox>
            <Checkbox onChange={iapTableData.onIapSelectAllHandler} value="iap">
              <h4>IAP Tasks</h4>
            </Checkbox>
          </Col>
        </Row>
      )}
      {showButtons && (
        <div className="action-buttons-container gap--bottom">
          <ActionButtons buttonsConfig={buttonsConfig ? buttonsConfig : []} />
        </div>
      )}
      {showMainTable && mainTable && (
        <div className="main-table-container gap--top">{mainTable}</div>
      )}
    </section>
  );
};

export default ContentTemplate;
