import React, { useEffect, useState } from "react";
import { Button, Text } from "../../../atoms";
import { AllStageExpandedDropDownColumns } from "../../../constants/otherTableConfigs";
import ExpandableTable from "../../../organisms/ExpandableTable";
import API from "../../../services/API";
import { ActiveSchedulingJobsUrls } from "../../../services/apiUrls";
import { tableFilterParser } from "../../../utils";
import { columns } from "../tableConfig";

const PAGE_SIZE = 5;

const ActiveJobsTable: React.FC = () => {
  const [mainTableLoading, setMainTableLoading] = useState(false);
  const [mainTableData, setMainTableData] = useState<any>([]);
  const [params, setParams] = useState("");
  const [reloadTable, setReloadTable] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: PAGE_SIZE,
    total: undefined,
    showSizeChanger: true,
  });

  useEffect(() => {
    setMainTableLoading(true);
    API.get(ActiveSchedulingJobsUrls.activeSchedulingTableUrl)
      .then((res) => {
        if (res.status === 200) {
          setMainTableData(res.data.active_scheduling_job_list);
          setPagination((prev) => ({ ...prev, total: res.data.count }));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setMainTableLoading(false));
  }, [params, reloadTable]);

  const tableChangeHandler = ({ pagination, filters, sorter }: any) => {
    setPagination(pagination);
    setParams(tableFilterParser({ pagination, filters, sorter }));
  };

  return (
    <ExpandableTable
      tableProps={{
        onChange: (pagination, filters, sorter) =>
          tableChangeHandler({ pagination, filters, sorter }),
        dataSource: mainTableData,
        columns: columns,
        title: () => (
          <div className="flex flex--row">
            <Text className="flex--grow" title level={5}>
              Active Scheduling Jobs
            </Text>
            <Button
              onClick={() => {
                setMainTableLoading(true);
                setTimeout(() => setMainTableLoading(false), 2000);
              }}
              type="primary"
              size="small"
              loading={mainTableLoading}
            >
              Refresh
            </Button>
          </div>
        ),
        loading: mainTableLoading,
        pagination: pagination,
      }}
      expandedSelectionColumnValues={AllStageExpandedDropDownColumns}
    />
  );
};

export default ActiveJobsTable;
