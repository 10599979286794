import { Modal } from "antd";
import API from "../../../../services/API";
import { StagingUrls } from "../../../../services/apiUrls";
import DataTable from "../../../../atoms/Table";
import { StatusType } from "../../../../types";
import { Status } from "../../../../constants/status";

export const statusColumns = [
  {
    title: "Tasks",
    dataIndex: "task",
    key: "key",
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "key",
    render: (text: StatusType) => Status(text),
  },
];

export function modalPopUp(status_exe_id: string, serial: string) {
  API.get(
    StagingUrls.gxoDeviceStatus +
      `/?status_exe_id=${status_exe_id}&serial=${serial}`
  )
    .then((res) => {
      if (res.status === 200) {
        const data = res.data?.data;
        if (data) {
          let tableData = Object.entries(data).map(([key, value], i) => {
            if (key !== "serial") {
              return {
                key: i,
                task: key,
                message: value,
              };
            }
          });
          tableData = tableData.filter(function (data) {
            return data !== undefined;
          });
          Modal.destroyAll();
          Modal.info({
            title: `Execution Details of ${serial}`,
            content: (
              <DataTable
                columns={statusColumns}
                dataSource={tableData}
                size="small"
                bordered={false}
                pagination={false}
              ></DataTable>
            ),
            okText: "Close",
            closable: true,
          });
        }
      }
    })
    .catch(({ response }) => {
      if (response.status === 400) {
        Modal.info({
          title: `Execution Details of ${serial}`,
          content:
            "The task execution has not started yet, try checking for the status in sometime",
          okText: "Close",
          closable: true,
        });
      }
    });
}