import {
  DetailStatusColumns,
  PlanningExeTableStatusColumns,
  StagingStatusColumns
} from "../constants/otherTableConfigs";

export const getColumns = (colName: string) => {
  switch (colName) {
    case "switch tasks":
      return DetailStatusColumns;
    case "iap tasks":
      return DetailStatusColumns;
    case "task statuses":
      return PlanningExeTableStatusColumns;
    default:
      return [];
  }
};

export const getStagingColumns = (colName: string) => {
  switch (colName) {
    case "switch tasks":
      return StagingStatusColumns;
    case "iap tasks":
      return StagingStatusColumns;
    default:
      return [];
  }
};