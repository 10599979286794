import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Badge,
  Divider,
  Button,
  Select,
  TablePaginationConfig,
  Table,
  Modal,
  Tooltip,
  Tag,
} from "antd";
import { Text } from "../../../atoms";
import API from "../../../services/API";
import { StagingUrls } from "../../../services/apiUrls";
import { WorkbookValidationData } from "../../../types";
import { columns, statusColumns } from "../StagingTable/config/workbookValidationConfig";
import { tableFilterParser } from "../../../utils";
import {
  ReloadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

interface StagingTableType {
  showTitle?: boolean;
  prevStep?: () => void;
  nextStep?: () => void;
  allStage?: boolean;
  site: string[];
}

const PAGE_SIZE = 20;

const WorkbookValidation: React.FC<StagingTableType> = ({
  showTitle,
  prevStep,
  nextStep,
  site,
}) => {
  const [mainTableData, setMainTableData] = useState<WorkbookValidationData[]>([]);
  const [switchCount, setSwitchCount] = useState<number>(0);
  const [iapCount, setIapCount] = useState<number>(0);
  const [failedSwitchCount, setFailedSwitchCount] = useState<number>(0);
  const [failedIapCount, setFailedIapCount] = useState<number>(0);
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>();
  const [sorter, setSorter] = useState<any>();
  const [params, setParams] = useState("");
  const [overallStatus, setOverallStatus] = useState("Passed");
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: PAGE_SIZE,
    total: undefined,
    showSizeChanger: true,
  });
  const { Option } = Select;
  let siteOptions: any[] = [];

  site.forEach((siteNumber: string) => {
    siteOptions.push(<Option value={siteNumber}>{siteNumber}</Option>);
  });

  useEffect(() => {
    if (site) {
      setTableLoading(true);
      setSelectedSite(site[0]);
      API.get(StagingUrls.gxoWorkbookStatus + `/?site=${site[0]}`)
        .then((res) => {
          if (res.status === 200) {
            setMainTableData(res.data?.data?.validation);
            setSwitchCount(res?.data?.data?.switch_count);
            setIapCount(res?.data?.data?.iap_count);
            setTableLoading(false);
            getOverallStatus(res.data?.data?.validation);
          }
        })
        .catch((err) => {
          setTableLoading(false);
          console.log(err)
        });
    }
  }, []);

  const tableChangeHandler = ({ pagination, filters, sorter }: any) => {
    setSorter(sorter);
    setPagination(pagination);
    Object.keys(filters).forEach((keys: string) =>
      filters[keys] !== null ? setFilters(filters) : undefined
    );
    setParams(tableFilterParser({ pagination, filters, sorter }));
  };

  const changeSite = (value: any) => {
    setSelectedSite(value);
    setTableLoading(true);
    API.get(StagingUrls.gxoWorkbookStatus + `/?site=${value}`)
        .then((res) => {
          if (res.status === 200) {
            setMainTableData(res?.data?.data?.validation);
            setSwitchCount(res?.data?.data?.switch_count);
            setIapCount(res?.data?.data?.iap_count);
            getOverallStatus(res.data?.data?.validation);
          }
        })
        .catch((err) => {
          console.log(err);
          setMainTableData([]);
          setOverallStatus("Error")
          Modal.error({
            title: "Device error",
            content: `There aren't any devices associated to site: ${value}`,
          });
        });
        setTableLoading(false);
  };

  function dataFormatter(dict: any, serial: string): any {
    let tableData = Object.entries(dict).map(([key, value], i) => {
        return {
          key: i,
          task: key,
          message: value,
        };
    });
    return tableData;
  }

  const refreshResults = () => {
    setTableLoading(false);
    API.get(
      StagingUrls.gxoWorkbookStatus + `/?site=${selectedSite}`
    )
      .then((res) => {
        if (res.status === 200) {
          setMainTableData(res?.data?.data?.validation);
          setSwitchCount(res?.data?.data?.switch_count);
          setIapCount(res?.data?.data?.iap_count);
          getOverallStatus(res.data?.data?.validation);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const getOverallStatus = (tableData: WorkbookValidationData[]) => {
    let iapFailCount = 0;
    let swFailCount = 0;
    let inPCount = 0;
    tableData.map((val: WorkbookValidationData) : void => {
      if (val.device_type === "IAP") {
        if (val.validation_result === "In Progress") {
          inPCount = inPCount + 1;
        }
        else if (
          val.validation_result === "Failed" ||
          typeof val.validation_result === "object"
        ) {
          iapFailCount = iapFailCount + 1;
        }
      } else {
        if (val.validation_result === "In Progress") {
          inPCount = inPCount + 1;
          
        }
        else if (
          val.validation_result === "Failed" ||
          typeof val.validation_result === "object"
        ) {
          swFailCount = swFailCount + 1;
          setOverallStatus("Error");
        }
      }
    });
    if (swFailCount + iapFailCount > 0) {
      setOverallStatus("Error");
    }
    else if (inPCount > 0) {
      setOverallStatus("In Progress");
    }
    else {
      setOverallStatus("Passed");
    }
    setFailedSwitchCount(swFailCount);
    setFailedIapCount(iapFailCount);
  };

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: "10px" }}>
        <Col>
          <Text strong>Site ID selected: </Text>
          <Select
            size="small"
            defaultValue={site[0]}
            onChange={changeSite}
            style={{ width: 100 }}
          >
            {siteOptions}
          </Select>
        </Col>
        <Col>
          <span className="flex flex--row">
            <Button onClick={prevStep} className="refresh-button gap--right">
              Go back
            </Button>
            <Button
              className="next-store-btn"
              onClick={nextStep}
              type="primary"
            >
              Central Device Status
            </Button>
          </span>
        </Col>
      </Row>
      <Divider style={{ marginTop: "0" }} />
      <Table
        columns={columns}
        onChange={(pagination, filters, sorter, extra) =>
          tableChangeHandler({ pagination, filters, sorter, extra })
        }
        pagination={pagination}
        rowKey="serial"
        loading={tableLoading}
        title={() => (
          <div className="flex flex--row">
            <Text className="flex--grow" title level={5}>
              Preliminary execution task Results{" "}
              <Tooltip title={"Switch: " + failedSwitchCount + "/" + switchCount + " Error | IAP: " + failedIapCount + "/" + iapCount + " Error"} placement="top">
                {overallStatus === "Passed" ? (
                  <Tag
                    icon={<CheckCircleOutlined />}
                    color="success"
                    style={{ cursor: "pointer" }}
                  >
                    Passed
                  </Tag>
                ) : overallStatus === "In Progress" ? (
                  <Tag
                    icon={<SyncOutlined spin />}
                    color="processing"
                    style={{ cursor: "pointer" }}
                  >
                    In Progress
                  </Tag>
                ) : (
                  <Tag
                    icon={<CloseCircleOutlined />}
                    color="error"
                    style={{ cursor: "pointer" }}
                  >
                    Error
                  </Tag>
                )}
              </Tooltip>
              <Tooltip title="Get latest results" placement="right">
                <Button
                  onClick={refreshResults}
                  className="refresh-button"
                  type="primary"
                  icon={<ReloadOutlined />}
                  shape="circle"
                  size="small"
                  loading={tableLoading}
                ></Button>
              </Tooltip>
            </Text>
            <div>
              <span className="gap--right">
                <Text strong>Switch count: </Text>
                <Badge
                  count={switchCount ? switchCount : "-"}
                  style={{
                    backgroundColor: switchCount === 0 ? "red" : "#52c41a",
                  }}
                />
              </span>
              <span style={{ float: "right" }} className="gap--right">
                <Text strong>IAP count: </Text>
                <Badge
                  className="switch-count"
                  count={iapCount ? iapCount : "-"}
                  style={{
                    backgroundColor: iapCount === 0 ? "red" : "#52c41a",
                  }}
                />
              </span>
            </div>
          </div>
        )}
        expandable={{
          expandedRowRender: (record: any) => (
            <Table
              columns={statusColumns}
              pagination={{}}
              dataSource={dataFormatter(record?.task_result, record?.serial)}
              size="small"
              loading={tableLoading}
            />
          ),
        }}
        dataSource={mainTableData}
      />
    </>
  );
};

export default WorkbookValidation;
