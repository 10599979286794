import {
  Alert,
  Modal,
  Select,
  Row,
  Col,
  Tooltip,
  Button,
  Upload as AntUpload,
  Dropdown,
} from "antd";
import { getCookie } from "../../utils/cookies";
import { UploadFile } from "antd/lib/upload/interface";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import { useSpinner } from "../../context/Spinner.context";
import ExecutionSteps from "../../organisms/ExecutionSteps";
import API, { BASE_URL } from "../../services/API";
import { DownloadUrls, StagingUrls } from "../../services/apiUrls";
import ContentTemplate from "../../templates/ContentTemplate";
import { DownloadFile } from "../../utils/fileDownload";
import {
  gxoInitialStep,
} from "./Config_files/tourConfig";
import { checkRole } from "../../services/authorization";
import WorkbookValidation from "./Gxo_flow/workbookValidation";
import { showErrorModel, showInfoModel } from "../../utils";
import GxoExecution from "./Gxo_flow/gxoExecution";
import ValidationResult from "./Gxo_flow/validateResult";
import ActionButtons from "../../organisms/ActionButtons";
import { menu } from "../../constants/downloadMenu";
import {
  UploadOutlined,
  EllipsisOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { DOWNLOAD_TYPES } from "../../constants/downloadConstants";
import DeviceStatus from "./Gxo_flow/deviceStatus";

interface ITaskType {
  key: string;
  task: string;
}

const StagingPage: React.FC = () => {
  const [selectedSwitchTasks, setSelectedSwitchTasks] = useState<
    Array<ITaskType>
  >([]);
  const [selectedIAPTasks, setSelectedIAPTasks] = useState<Array<ITaskType>>(
    []
  );
  const [switchTasks, setSwitchTasks] = useState<Array<string>>([]);
  const [iapTasks, setIAPTasks] = useState<Array<string>>([]);
  const [execType, setExecType] = useState<"exec" | "rollback">('exec');
  const [switchTasksLoading, setSwitchTasksLoading] = useState<boolean>(false);
  const [iapTasksLoading, setIAPTasksLoading] = useState<boolean>(false);
  const [executionLoading, setExecutionLoading] = useState(false);
  const [rollbackLoading, setRollbackLoading] = useState(false);
  const [siteId, setSiteId] = useState<Array<string>>([]);
  const [bulkSiteFile, setBulkSiteFile] = useState<UploadFile | null>(null);
  const [workbookFile, setWorkbookFile] = useState<UploadFile[] | [] | any>([]);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState(gxoInitialStep);
  const [overrideWorkbook, setOverrideWorkbook] = useState(false);
  const [validationStep, setValidationStep] = useState<boolean>(false);

  let siteOptions: any[] = [];

  const { user } = useAuth();
  const { setSpinner } = useSpinner();
  const hasAccess = checkRole(user?.role?.toString().toUpperCase(), "staging");

  useEffect(() => {
    // Effect to Fetch Switch Tasks and IAP tasks
    // clear all values and errors
    setSwitchTasks([]);
    setIAPTasks([]);
    // fetch
    if (!(user?.role[0].toLowerCase() === "gxo_staging_user")) {
      fetchSwitchTasks();
      fetchIAPTasks();
    }
    return () => {setSpinner(false)}
  }, []);

  useEffect(() => {
    setSelectedIAPTasks([]);
    setSelectedSwitchTasks([]);
  }, [stepIndex]);

  const cleanUpAfterStep = (idx: number) => {
    setStepIndex(idx);
    setSelectedIAPTasks([]);
    setSelectedSwitchTasks([]);
  };

  const startExecution = () => {
    // creating formData to send the file (custom upload)
    const formData = new FormData();
    if (bulkSiteFile) {
      formData.append("site_list_file", bulkSiteFile as any);
    }
    formData.append("site_list", JSON.stringify(siteId));

    if (selectedSwitchTasks.length > 0 && selectedIAPTasks.length > 0){
        formData.append(
                  "task_types",
                  JSON.stringify({
                    switch: selectedSwitchTasks.map(({ task }) => task),
                    iap: [],
                  })
                );
        stagingStartExecution(formData)
        formData.delete("task_types")
        formData.append(
                          "task_types",
                          JSON.stringify({
                            switch: [],
                            iap: selectedIAPTasks.map(({ task }) => task),
                          })
                        );
        stagingStartExecution(formData)
    }
    else{
        formData.append(
                  "task_types",
                  JSON.stringify({
                    switch: selectedSwitchTasks.map(({ task }) => task),
                    iap: selectedIAPTasks.map(({ task }) => task),
                  })
                );
        stagingStartExecution(formData)
    }
  };

  const stagingStartExecution = (formData: any) =>{
        setExecutionLoading(true);
        API.post(
          StagingUrls.baseStagingUrl + `?user=${user?.name}`,
          formData,
          true,
          {
            "Content-Type": "multipart/form-data",
          }
        )
          .then((res) => {
            Modal.destroyAll();
            Modal.success({
              title: "Execution Started",
              content: res.data ? res.data.Message : "",
            });
          })
          .catch(({ response }) => {
            Modal.destroyAll();
            response &&
              Modal.error({
                title: "Error while Execution",
                content: response ? response.data.Message : "Execution Timed Out",
              });
          })
          .finally(() => {
            setExecutionLoading(false);
          });
  }

  const startRollback = () => {
    const formData = new FormData();
    if (bulkSiteFile) {
      formData.append("site_list_file", bulkSiteFile as any);
    }
    formData.append(
      "task_types",
      JSON.stringify({
        switch: selectedSwitchTasks.map(({ task }) => task),
        iap: selectedIAPTasks.map(({ task }) => task),
      })
    );
    formData.append("site_list", JSON.stringify(siteId));

    setRollbackLoading(true);
    API.post(StagingUrls.rollback + `?user=${user?.name}`, formData, true, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        Modal.success({
          title: "Rollback Started",
          content: res.data ? res.data.Message : "",
        });
      })
      .catch(({ response }) => {
        response &&
          Modal.error({
            title: "Error while Rollback",
            content: response ? response.data.Message : "Rollback Timed Out",
          });
      })
      .finally(() => {
        setRollbackLoading(false);
      });
  };

  const changeExecType = (e: any) => {
    setExecType(e.target.value);
  };

  const fetchSwitchTasks = () => {
    setSwitchTasksLoading(true);
    API.get(StagingUrls.baseStagingUrl + StagingUrls.switchTasksParam)
      .then(({ data }) => {
        setSwitchTasks(data.task_list);
        setSelectedSwitchTasks(
          data.task_list.map((value: string, key: string) => key)
        );
      })
      .catch(({ response }) => {
        setSwitchTasks([]);
      })
      .finally(() => setSwitchTasksLoading(false));
  };

  const fetchIAPTasks = () => {
    setIAPTasksLoading(true);
    API.get(StagingUrls.baseStagingUrl + StagingUrls.iapTasksParam)
      .then(({ data }) => {
        setIAPTasks(data.task_list)
        setSelectedIAPTasks(
          data.task_list.map((value: string, key: string) => key)
        );
      })
      .catch(({ response }) => {
        setIAPTasks([]);
      })
      .finally(() => setIAPTasksLoading(false));
  };

  const download = async (url: string, fileName: string, type: string) => {
    setSpinner(true);
    await DownloadFile(url, { fileName, phase: "PreStaging", type });
    setSpinner(false);
  };

  const workbookUpload = (data?: any) => {
    if (data) {
      let fileSizeValidation = true;
      const formData = new FormData();
      (data.files as []).forEach((file: any) => {
        formData.append("file", file.originFileObj as any);
        const isLt6M = file.size / 1024 / 1024 < 6;
        if (!isLt6M) {
          fileSizeValidation = false;
          showErrorModel(
            "Error while Upload",
            "File size must be smaller than 6MB!"
          );
        }
      });
      if (data?.override) {
        formData.append("override", data?.override);
      }
      if (fileSizeValidation) {
        setSpinner(true);
        API.post(
          BASE_URL + StagingUrls.workbook + `?user=${user?.name}`,
          formData,
          true,
          {
            "Content-Type": "multipart/form-data",
          }
        )
          .then((res) => {
            showInfoModel("Workbook Execution Status", res.data?.Message);
            setSpinner(false);
          })
          .catch(({ response }) => {
            setSpinner(false);
            if (response) {
              if (response.status === 406) {
                Modal.confirm({
                  title: "Do you want to overwrite the devices?",
                  okText: "Yes",
                  cancelText: "No",
                  content: response.data?.Message,
                  onOk: () => workbookUpload({ files: data.files, override: true }),
                });
              } else {
                showErrorModel(
                  "Error while Execution",
                  response.data ? response.data.Message : "Execution Timed Out"
                );
              }
            }
          })
          .finally(() => {
            setOverrideWorkbook(false);
            setSpinner(false);
          });
      }
    }
  };

  const selectedFiles = (files: any) => {
    setWorkbookFile(files.fileList);
    const firstFile = files?.fileList[0]?.name;
    if (firstFile) {
      if (
        firstFile?.startsWith("THD_Naas_2021_Store_Order_") ||
        firstFile?.startsWith("THD Naas 2021 Store Order") ||
        firstFile?.startsWith("THD_Naas_2022_Store_Order_") ||
        firstFile?.startsWith("THD Naas 2022 Store Order")
      ) {
        const site = `ST${firstFile.substring(26, 30)}`;
        // Check if selected site is part of selected site list
        const siteIndex = siteId.findIndex((siteNumber: string) => siteNumber == site)
        if (siteIndex === -1) {
          setSiteId([...siteId, site]);
          setValidationStep(true);
        }
        workbookUpload({ files: files?.fileList });
      } else {
        Modal.error({
          title: "File name error",
          content:
            "The file name must start with 'THD_Naas_2021_Store_Order' or 'THD Naas 2021 Store Order'",
        });
      }
    }
  };

  function handleChange(value: any) {
    setValidationStep(false);
    if (value.length !== 0) {
      const formData = new FormData();
      formData.append("site_list", JSON.stringify(value));
      API.post(StagingUrls.siteValidation, formData, true)
        .then((res) => {
          if (res.status === 200) {
            setSiteId([...value]);
            setValidationStep(true);
          }
        })
        .catch(({ response }) => {
          siteId.length === 0
            ? setValidationStep(false)
            : setValidationStep(true);
          showErrorModel(
            "Site ID validation error",
            response?.data ? response.data?.Message : "Execution Timed Out"
          );
        });
    } else {
      handleClear();
    }
  }

  function handleClear() {
    setSiteId([]);
    setValidationStep(false);
    setWorkbookFile([]);
  }

  const ExecutionStep = [
    {
      title: "Workbook upload/ Site Selection",
      content: (
        <>
          <Alert
            banner
            message="Please Upload the Workbook before you proceed with the Execution"
            type="info"
            showIcon
          />
          <ContentTemplate
            showMainTable
            mainTable={
              <div className="without-animation">
                <Row justify="start">
                  <Col span={12}>
                    <div style={{ overflowX: "auto", marginRight: "15px" }}>
                      <ActionButtons
                        buttonsConfig={[
                          {
                            type: "default",
                            variant: "upload-multiple",
                            defaultUpload: true,
                            disabled: !hasAccess,
                            hasToolTipWhenDisabled: true,
                            toolTipText:
                              "Your profile is not eligible to perform this action",
                            buttonText: "Select Workbook file",
                            className: "upload-workbook",
                            accept: ".xlsm",
                            onChange: selectedFiles,
                            maxCount: 1,
                            uploadConfig: {
                              showFileList: true,
                              customUpload: true,
                              multiple: false,
                              uploadUrl: StagingUrls.workbook,
                              data: {
                                override: overrideWorkbook ? "True" : "False",
                              },
                              defaultFileList: workbookFile ? workbookFile : [],
                            },
                            showCheckBox: false,
                            checkBoxText:
                              "Overwrite workbook if data already exists",
                            handleCheckBox: setOverrideWorkbook,
                          },
                        ]}
                      ></ActionButtons>
                    </div>
                  </Col>
                  <Col
                    style={{
                      borderLeft: "1px solid #ccc",
                      paddingLeft: "15px",
                    }}
                    span={12}
                  >
                    <div style={{ paddingBottom: "8px" }}>
                      Enter Site Ids{" "}
                      <Tooltip
                        placement="right"
                        title="Click outside the textbox after entering the site number or separate each site using ',' separator"
                      >
                        <InfoCircleOutlined />
                      </Tooltip>
                    </div>
                    <Select
                      mode="tags"
                      showSearch={false}
                      showArrow={false}
                      allowClear={true}
                      defaultActiveFirstOption={false}
                      filterOption={false}
                      placeholder="ST0001, ST0002, ST0003..."
                      style={{ width: "65%" }}
                      onChange={handleChange}
                      value={siteId}
                      tokenSeparators={[",", "\n"]}
                      notFoundContent={null}
                      onClear={handleClear}
                      disabled={!hasAccess}
                    ></Select>
                    <div style={{ marginTop: "15px" }}>
                      <AntUpload
                        maxCount={1}
                        disabled={!hasAccess}
                        accept=".xlsx"
                        showUploadList={false}
                        onChange={(info) => {
                          if (info.file.status === "done") {
                            setValidationStep(true);
                            setSiteId(info.file?.response?.sites);
                            Modal.success({
                              title: "Upload Status",
                              content: `Upload Message: ${
                                info.file.response && info.file.response.Message
                                  ? info.file.response.Message
                                  : info.file.response
                              }`,
                            });
                          } else if (info.file.status === "error") {
                            Modal.error({
                              title: "Upload Failed",
                              content: `Upload Error: ${
                                info.file.response && info.file.response.Message
                                  ? info.file.response.Message
                                  : "Execution Timed out"
                              }`,
                            });
                          }
                        }}
                        action={BASE_URL + StagingUrls.siteValidation}
                        headers={{
                          "X-CSRFToken": `${getCookie("csrftoken")}`,
                          Authorization: `Bearer ${localStorage.getItem(
                            "token"
                          )}`,
                        }}
                        withCredentials={true}
                      >
                        <Button icon={<UploadOutlined />}>
                          Upload Site Bulk File
                        </Button>
                      </AntUpload>
                      <Dropdown
                        className="dropdown-button"
                        placement="bottomLeft"
                        arrow
                        overlay={menu([
                          {
                            menuText: "Download Sample file",
                            cb: () =>
                              download(
                                DownloadUrls.downloadUrl,
                                "Bulk_Site_template.xlsx",
                                DOWNLOAD_TYPES.SITE_TEMPLATE
                              ),
                          },
                        ])}
                      >
                        <Button icon={<EllipsisOutlined />} />
                      </Dropdown>
                    </div>
                  </Col>
                </Row>
                <Tooltip
                  title={
                    siteId
                      ? validationStep
                        ? ""
                        : "Check the site ID format entered"
                      : "Enter site ID/ upload workbook"
                  }
                  placement="right"
                >
                  <Button
                    onClick={() => setStepIndex(() => stepIndex + 1)}
                    className="next-store-btn"
                    type="primary"
                    disabled={!validationStep}
                  >
                    Next
                  </Button>
                </Tooltip>
              </div>
            }
          />
        </>
      ),
      hideButton: true,
      disabled: workbookFile?.length === 0,
      toolTipText: "select site workbook file to stage the devices",
    },
    {
      title: "Preliminary Execution Status",
      content: (
        <>
          <Alert
            banner
            message={`${siteId.length} ${
              siteId.length === 1 ? "site" : "sites"
            } selected for the execution`}
            type="info"
            showIcon
          />
          <ContentTemplate
            showMainTable
            mainTable={
              <WorkbookValidation
                prevStep={() => setStepIndex(() => stepIndex - 1)}
                nextStep={() => setStepIndex(() => stepIndex + 1)}
                showTitle
                site={siteId}
              />
            }
          />
        </>
      ),
      hideButton: true,
    },
    {
      title: "Device Status",
      content: (
        <>
          <Alert
            banner
            message="Fetches current status of the devices from central as and when you you navigate to this step"
            type="info"
            showIcon
          />
          <ContentTemplate
            showMainTable
            mainTable={
              <DeviceStatus
                showTitle
                prevStep={() => setStepIndex(() => stepIndex - 1)}
                nextStep={() => setStepIndex(() => stepIndex + 1)}
                site={siteId}
              />
            }
          />
        </>
      ),
      hideButton: true,
    },
    {
      title: "Staging Execution/ Rollback",
      content: (
        <>
          <Alert
            banner
            message="Execute Switch & IAP related tasks, first select the task type and hit the execute button"
            type="info"
            showIcon
          />
          <ContentTemplate
            showMainTable
            mainTable={
              <GxoExecution
                showTitle
                prevStep={() => setStepIndex(() => stepIndex - 1)}
                nextStep={() => setStepIndex(() => stepIndex + 1)}
                site={siteId}
              />
            }
          />
        </>
      ),
      hideButton: true,
    },
    {
      title: "Central Validation",
      content: (
        <>
          <Alert
            banner
            message="Run the staging validation against central"
            type="info"
            showIcon
          />
          <ContentTemplate
            showMainTable
            mainTable={
              <ValidationResult
                showTitle
                prevStep={() => setStepIndex(() => stepIndex - 1)}
                nextStep={() => setStepIndex(() => stepIndex - 4)}
                site={siteId}
              />
            }
          />
        </>
      ),
      hideButton: true,
    },
  ];

  return (
    <>
      <ContentTemplate
        title="Staging"
      >
        <ExecutionSteps
          dotStyle
          steps={ExecutionStep}
          type="default"
          currentIndex={stepIndex}
          cleanupAfterStep={cleanUpAfterStep}
        />
      </ContentTemplate>
    </>
  );
};

export default StagingPage;
