export enum Roles {
  STD_USER = "STD_USER",
  PLANNING_USER = "PLANNING_USER",
  PRESTAGING_USER = "PRESTAGING_USER",
  STAGING_USER = "STAGING_USER",
  PRECHECK_USER = "PRECHECK_USER",
  POSTCHECK_USER = "POSTCHECK_USER",
  ADMIN_USER = "ADMIN_USER",
  GXO_USER = "GXO_STAGING_USER",
}
