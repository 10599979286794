import React, { useEffect } from "react";
import { Tooltip, Form, Input } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

export interface ISiteSelectionType {
  sites: Array<string>;
}

export interface ISiteIdInputType {
  onChangeHandler: (sel: ISiteSelectionType) => void;
  error: boolean;
  errorText: string;
  showInfoIcon: boolean;
  infoText?: string;
  inputType: "single" | "multiple";
  selectedId?: string[];
  className?: string;
  disabled?: boolean;
  hasToolTipWhenDisabled?: boolean;
  toolTipText?: string;
}

const SiteIdInput: React.FC<ISiteIdInputType> = ({
  onChangeHandler,
  errorText,
  showInfoIcon,
  infoText,
  inputType,
  selectedId,
  disabled,
  hasToolTipWhenDisabled,
  toolTipText,
}) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedId) {
      form.setFieldsValue({ site_id: selectedId.join(",") });
    }
  }, []);

  return (
    <Tooltip
      title={
        disabled && hasToolTipWhenDisabled && toolTipText ? toolTipText : ""
      }
    >
      <Form
        form={form}
        name="site_id_form"
        initialValues={{}}
        layout="vertical"
        style={{ width: "400px" }}
        onFieldsChange={(changedFields, _) => {
          const val = changedFields[0];
          if (val && val.errors?.length === 0) {
            const selection: ISiteSelectionType = {
              sites: (val.value as string)
                .split(",")
                .map((v) => v.trim())
                .filter((v) => v !== ""),
            };
            onChangeHandler(selection);
          } else {
            onChangeHandler({ sites: [] });
          }
        }}
      >
        <Form.Item
          label={
            inputType === "multiple"
              ? "Enter Site Ids:"
              : inputType === "single"
              ? "Enter Site Id:"
              : ""
          }
          name="site_id"
          rules={[
            {
              validator: (_, value: string) => {
                if (value !== "") {
                  const input = value.split(",");
                  if (input.length > (inputType === "multiple" ? 5 : 1)) {
                    return Promise.reject(
                      new Error(
                        errorText
                          ? errorText
                          : "Please enter only five sites, use upload option for bulk input!"
                      )
                    );
                  }
                  let error = null;
                  input.forEach((val) => {
                    if (!/(^ST\d{4}$)/gm.test(val.trim())) {
                      error = "Please check the entered site id. Eg: ST0001";
                      return;
                    }
                  });
                  if (error) {
                    return Promise.reject(new Error(error));
                  }
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input
            className="site-input-box"
            disabled={disabled}
            placeholder={
              inputType === "multiple"
                ? "ST0001, ST0002, ST0003..."
                : inputType === "single"
                ? "ST0001"
                : ""
            }
            suffix={
              showInfoIcon && (
                <Tooltip title={infoText}>
                  <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
                </Tooltip>
              )
            }
          />
        </Form.Item>
      </Form>
    </Tooltip>
  );
};

export default SiteIdInput;
