import React from "react";
import ContentTemplate from "../../templates/ContentTemplate";

const Tutorial = () => {
  return (
    <>
      <ContentTemplate title="Tutorial">
        <div
          style={{
            width: "40%",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
          }}
          className="form-container"
        >
            
        </div>
      </ContentTemplate>
    </>
  );
};

export default Tutorial;
