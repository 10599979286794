import ActiveJobs from "../pages/ActiveJobs";
import AllStageDetails from "../pages/AllStageDetails";
import StagingExecutionData from "../pages/StagingExecutionData";
import Feedback from "../pages/FeedbackPage";
import LandingPage from "../pages/LandingPage";
import PlanningPage from "../pages/PlanningPage";
import PostCheck from "../pages/PostCheckPage";
import PreStaging from "../pages/Pre-Staging";
import PreCheck from "../pages/PreCheckPage";
import StagingPage from "../pages/StagingPage";
import Tutorial from "../pages/TutorialPage";

export const Routes = [
  { path: "/", component: LandingPage },
  { path: "/planning", component: PlanningPage },
  { path: "/staging", component: StagingPage },
  { path: "/pre-staging", component: PreStaging },
  { path: "/staging-details", component: StagingExecutionData },
  { path: "/all-stage-details", component: AllStageDetails },
  { path: "/pre-check", component: PreCheck },
  { path: "/post-check", component: PostCheck },
  { path: "/active-jobs", component: ActiveJobs },
  { path: "/feedback", component: Feedback },
  { path: "/tutorial", component: Tutorial },
];

export const GxoRoutes = [
  { path: "/", component: StagingPage },
  { path: "/staging-details", component: StagingExecutionData },
  { path: "/feedback", component: Feedback },
];