import React from "react";
import ContentTemplate from "../../templates/ContentTemplate";
import ActiveJobsTable from "./ActiveJobsTable";

const ActiveJobs: React.FC = () => {
  return (
    <ContentTemplate
      title="Active Schedule Jobs"
      showMainTable
      mainTable={<ActiveJobsTable />}
    />
  );
};

export default ActiveJobs;
