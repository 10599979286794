import { Modal } from "antd";
import { useState } from "react";
import API from "../../services/API";
import { PhaseType } from "../../types";
import { useAuth } from "../../context/Auth";
import { StagingUrls } from "../../services/apiUrls";
import { DeleteRowOutlined } from "@ant-design/icons";
import { checkRole } from "../../services/authorization";
import { showConfirm, showErrorModel } from "../../utils";
import ActionButtons, { IButtonConfig } from "../ActionButtons";

export interface ITerminateButton {
  task_id: string;
  phase: PhaseType;
  status?: string;
}

const TerminateTask: React.FC<ITerminateButton> = ({
  task_id,
  phase,
  status
}) => {
  const { user } = useAuth();
  const hasAccess = checkRole(
    user?.role?.toString().toUpperCase(),
    phase?.toLowerCase()
  );
  const [task, setTask] = useState(task_id);

  const terminateExecution = (data?: {}) => {
    API.post(StagingUrls.taskTerminationUrl + `?user=${user?.name}`, {
      task_id: task_id
    })
      .then((res) => {
        Modal.success({
          title: "Execution Started",
          content: res.data ? res.data.Message : "",
        });
        setTask(res.data?.Message);
      })
      .catch(({ response }) => {
        showErrorModel(
          "Error",
          response.data ? response.data.Message : "Execution Timed Out"
        );
        if (response.data) {
          setTask(response.data.Message);
        }
      })
  };

  const buttonsConfig: Array<IButtonConfig> = [
    {
      type: "primary",
      variant: "default",
      buttonText: "Terminate",
      disabled: !hasAccess,
      className: "terminate-button",
      hasToolTipWhenDisabled: true,
      toolTipText: "Your profile is not eligible to perform this action",
      icon: <DeleteRowOutlined />,
      size: "small",
      onClick: () => showConfirm(
        "Terminate Execution",
        "Do you want to terminate the ongoing execution?",
        () => terminateExecution()
      ),
    },
  ];

  return task?.length === 36 && status !== "Error" && status !== "Completed" ? (
    <ActionButtons buttonsConfig={buttonsConfig ? buttonsConfig : []} />
  ) : status === "Error" && task?.length !== 36 ? (
    <>{task}</>
  ) : status === "Error" && task?.length === 36 ? (
    <>Task already completed</>
  ) : status === "Completed" ? (
    <>Task already completed</>
  ) : (
    <>{task}</>
  );
};

export default TerminateTask;
