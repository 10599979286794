import {
  AllStageExpandedDropDownOptions,
  PlanningDropDownOptions,
  TaskTypeDropDownOptions,
} from "../constants/otherTableConfigs";
import { AllStageDetailsData, ExecutionTableData, PhaseType } from "../types";

export const formatExecutionData = (
  data: ExecutionTableData[],
  phase: PhaseType
) =>
  data.map((record, i) => ({
    ...record,
    expand: true,
    downDownTableData: dropDownTableData(phase),
    key: record.exe_id,
  }));

const dropDownTableData = (phase: PhaseType | "AllStageDetails") => {
  switch (phase) {
    case "Planning":
      return PlanningDropDownOptions;
    case "AllStageDetails":
      return AllStageExpandedDropDownOptions;
    default:
      return TaskTypeDropDownOptions;
  }
};

export const formatFileList = (str: string, splitter: string) => {
  return str
    .replaceAll(/[\][,']+/g, " ")
    .split(splitter)
    .filter((value) => value !== "");
};

export const formatTaskListData = (data: Object[], phase?: string) => {
  if (phase && phase === "Planning")
    return data.map((val, i) => ({ ...val, key: i }));
  if (!data[0]) return [];
  const dataObj = data[0];
  return Object.entries(dataObj).map(([key, value], i) => {
    return {
      key: i,
      task_name: key,
      status: value,
    };
  });
};

export const countStatuses = (data: string[]) => {
  let dict: any = {};
  data.forEach((text) => {
    if (!dict[text]) {
      dict[text] = 1;
    } else {
      dict[text] = dict[text] + 1;
    }
  });
  return dict;
};

export const appendCount = (data: {}) => {
  return Object.entries(data).map(([key, value], i) => {
    return {
      value: `${value} - ${key}`,
      text: key,
    };
  });
};

export const allStageDetailsParser = (data: AllStageDetailsData[]) => {
  return data.map((record, i) => ({
    ...record,
    Planning: appendCount(countStatuses(record.Planning)),
    Staging: appendCount(countStatuses(record.Staging)),
    PreStaging: appendCount(countStatuses(record.PreStaging)),
    PreCheck: appendCount(countStatuses(record.PreCheck)),
    PostCheck: appendCount(countStatuses(record.PostCheck)),
    key: record.site_name,
    expand: true,
    downDownTableData: dropDownTableData("AllStageDetails"),
  }));
};
