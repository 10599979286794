import { Space } from "antd";
import { Status } from "../constants/status";
import { StatusType } from "../types";

export const renderAllStageStatus = (
  data: { text: string; value: string }[]
) => {
  return (
    <Space className="gap-0-imp" direction="vertical">
      {data.map(({ text, value }) => Status(text as StatusType, value))}
    </Space>
  );
};
