import { Menu } from "antd";
import { DownloadOutlined } from "@ant-design/icons";

export interface IMenuConfig {
  menuText: string;
  cb: () => void;
}

export const menu = (menuConfig: IMenuConfig[]): JSX.Element => (
    <Menu>
      {menuConfig.map((menuObj: IMenuConfig) => {
        return (
          <Menu.Item key="1" icon={<DownloadOutlined />} onClick={menuObj.cb}>
            {menuObj.menuText}
          </Menu.Item>
        );
      })}
    </Menu>
)
