export const preCheckSteps = [
  {
    selector: ".site-input-box",
    content: "Enter upto 5 site number here for execution",
  },
  {
    selector: ".download-db-button",
    content: "Download the db file from here and perform exe execution",
  },
  {
    selector: ".ant-upload",
    content: "upload the final db file here",
  },
  {
    selector: ".ant-table-container",
    content: "Run status will be appended to execution data table",
  },
  {
    selector: ".refresh-button",
    content:
      "If you do not see the status of current execution, refresh the table by clicking on the button",
  },
];
