import { Modal } from "antd";
import axios, { AxiosInstance, AxiosResponse } from "axios";
import { getCookie } from "../utils/cookies";
import { AuthUrls } from "./apiUrls";

export const BASE_URL = process.env.REACT_APP_BASE_URL;
//export const BASE_URL = 'http://127.0.0.1:8000/api';

class Service {
  protected readonly service: AxiosInstance;
  protected csrfToken: string; // if we need it

  constructor() {
    let service = axios.create({
      baseURL: BASE_URL,
      withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    });
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.handleSuccess = this.handleSuccess.bind(this);
    this.handleError = this.handleError.bind(this);
    this.get = this.get.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.csrfToken = "";
  }

  handleSuccess = (response: AxiosResponse<any, any>) => {
    return response;
  };

  handleError = (error: any) => {
    if (!error.response) return Promise.reject(error);

    if ([401, 502, 504].includes(error.response.status)) {
      switch (error.response.status) {
        case 401:
          // unauthorized - need to log user out
          if (
            !(error.response.request.responseURL as string).includes(
              AuthUrls.logout
            )
          ) {
            window.history.replaceState({}, "", "/logged-out");
            localStorage.clear();
            window.location.href = "/logged-out";
          }
          break;
        case 502:
          Modal.error({
            title: "Execution Timed Out",
            content: "Execution couldn't give back a response in time",
          });
          break;
        case 504:
          Modal.error({
            title: "Execution Timed Out",
            content: "Execution couldn't give back a response in time",
          });
          break;
      }
    } else return Promise.reject(error);
  };

  redirectTo = (document: any, path: any) => {
    document.location = path;
  };

  getCsrfToken = () => {
    return getCookie("csrftoken");
  };

  getAuthToken = () => {
    return localStorage.getItem("token");
  };

  get = (
    path: string,
    params = {},
    header?: Object,
    download?: boolean
  ): Promise<AxiosResponse<any, any>> => {
    let headers = {
      Authorization: this.getAuthToken() ? `Bearer ${this.getAuthToken()}` : "",
    };

    if (header) {
      headers = { ...headers, ...header };
    }

    return this.service.get(path, {
      headers: headers,
      params: params,
      ...(download ? { responseType: "blob" } : {}),
    });
  };

  post = (
    path: string | undefined,
    payload: any,
    withCredentials: boolean = true,
    header?: Object
  ): Promise<AxiosResponse<any, any>> => {
    let headers = {
      "X-CSRFToken": `${this.getCsrfToken()}`,
      Authorization: this.getAuthToken() ? `Bearer ${this.getAuthToken()}` : "",
    };

    if (header) {
      headers = { ...headers, ...header };
    }

    return this.service.request({
      method: "POST",
      url: path,
      headers,
      data: payload,
    });
  };

  put = (
    path: string,
    payload: any,
    isHeader?: boolean
  ): Promise<AxiosResponse<any, any>> => {
    let headers = {
      "X-CSRFToken": `${this.getCsrfToken()}`,
      Authorization: this.getAuthToken() ? `Bearer ${this.getAuthToken()}` : "",
    };

    return this.service.request({
      method: "PUT",
      url: path,
      headers,
      data: payload,
    });
  };

  patch = (
    path: any,
    payload: any,
    withCredentials: boolean = true,
    header?: Object
  ): Promise<AxiosResponse<any, any>> => {
    let headers = {
      "X-CSRFToken": `${this.getCsrfToken()}`,
      Authorization: this.getAuthToken() ? `Bearer ${this.getAuthToken()}` : "",
    };

    if (header) {
      headers = { ...headers, ...header };
    }

    return this.service.request({
      method: "PATCH",
      url: path,
      data: payload,
      headers,
    });
  };
}

export default new Service();
