import { Spin } from "antd";
import React, { createContext, useContext, useState } from "react";

interface ISpinnerContextType {
  setSpinner: (val: boolean) => void;
}

const Spinner = createContext<ISpinnerContextType>({} as ISpinnerContextType);

export const SpinnerContext: React.FC = (props) => {
  const [spinning, setSpinning] = useState<boolean>(false);

  const setSpinner = (val: boolean) => {
    setSpinning(val);
  };

  const initialSpinnerObj = {
    setSpinner,
  };

  return (
    <Spinner.Provider value={initialSpinnerObj}>
      <Spin spinning={spinning}>{props.children}</Spin>
    </Spinner.Provider>
  );
};

export const useSpinner = (): ISpinnerContextType => useContext(Spinner);
