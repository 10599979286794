import { Space } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { RightCircleOutlined } from "@ant-design/icons";
// import image1 from "../../assets/images/1.jpeg";
// import image2 from "../../assets/images/2.jpg";
import { Text } from "../../atoms";

import "./styles.scss";
import { RightArrow } from "../../assets";
import { useAuth } from "../../context/Auth";

const LandingPage: React.FC = () => {
  const { user } = useAuth();
  const history = useHistory();
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  user?.role[0].toLowerCase() === "gxo_staging_user" ? history.replace("/forbidden", { from: "/login" }) : null;
  const data = [
    {
      title: "Planning",
      desc: "Capture Inventory details from respective Central account. The major tasks involve fetching sites, fetching aps per site, fetching switches per site.",
      link: "/planning",
    },
    {
      title: "Pre-Staging",
      desc: "Populate the variables required for the staging process in the entire migration. The major tasks involve capturing and extracting variables from CSR Config, AT&T Central and populating other pre-calculated & constant variables for IAPs, IDFs and MDFs.",
      link: "/pre-staging",
    },
    {
      title: "Staging",
      desc: "Migrate or On-board devices and configuration from AT&T Central to THD Naas Cluster. Major tasks involve add device, license device, group devices, associate site to device and so on which also include tasks to perform rollback.",
      link: "/staging",
    },
    {
      title: "Pre-Check",
      desc: "Collects the device information from AT&T Central and devices for audit purposes",
      link: "/pre-check",
    },
    {
      title: "Post-Check",
      desc: "Collects the device information from THD Naas Central and does the comparision against data collected from pre-check phase",
      link: "/post-check",
    },
  ];

  return (
    <section className="landing-container">
      <Text className="landing-title" title level={3}>
        Aruba Migration Manager
      </Text>
      <Space
        split={<img src={RightArrow} alt="-->" width={50} height={50} />}
        direction="horizontal"
        className="buttons-section"
      >
        {data.map((obj, i) => (
          <div key={i} onClick={() => history.push(obj.link)} className="card">
            <h3 className="card-title">{obj.title}</h3>
            <p className="card-desc">{obj.desc}</p>
            <RightCircleOutlined className="card-icon" />
          </div>
        ))}
      </Space>
    </section>
  );
};

export default LandingPage;
