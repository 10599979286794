import { Roles } from "../constants/roles";

export function checkRole(role: string | undefined, stage: string): boolean | undefined {
  const roles = role?.split(",")
  const isAdmin = roles?.find((r) => r === Roles.ADMIN_USER) ? true : false;
  let userAccess = undefined;
  if (!isAdmin) {
    switch (stage) {
      case "planning": {
        userAccess = roles?.find((r) => r === Roles.PLANNING_USER)
          ? true
          : false;
        break;
      }
      case "prestaging": {
        userAccess = roles?.find((r) => r === Roles.PRESTAGING_USER)
          ? true
          : false;
        break;
      }
      case "staging": {
        userAccess = roles?.find((r) => r === Roles.STAGING_USER)
          ? true
          : roles?.find((r) => r === Roles.GXO_USER)
          ? true
          : false;
        break;
      }
      case "precheck": {
        userAccess = roles?.find((r) => r === Roles.PRECHECK_USER)
          ? true
          : false;
        break;
      }
      case "postcheck":
        {
          userAccess = roles?.find((r) => r === Roles.POSTCHECK_USER)
            ? true
            : false;
          break;
        }
    }
    return userAccess;
  } else {
    return true;
  }
}

export function checkAdmin(role: string | undefined): boolean {
  const roles = role?.split(",")
  const isAdmin = roles?.find((r) => r === Roles.ADMIN_USER) ? true : false;
  return isAdmin ? true : false;
};