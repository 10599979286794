import { Button, Modal } from "antd";
import React, { useEffect, useState } from "react";
import { srcTaskColumns, destTaskColumns } from "../../constants/mockData";
import { useAuth } from "../../context/Auth";
import { IButtonConfig } from "../../organisms/ActionButtons";
import API from "../../services/API";
import { PlanningUrls, StagingUrls } from "../../services/apiUrls";
import ContentTemplate from "../../templates/ContentTemplate";
import { showConfirm, showErrorModel } from "../../utils";
import PlanningTable from "./PlanningTable";
import Tour from "reactour";
import { planningSteps } from "./tourConfig";
import { checkRole } from "../../services/authorization";
import DataTable from "../../atoms/Table";

const PlanningPage: React.FC = () => {
  const [executionLoading, setExecutionLoading] = useState(false);
  const [srcTasks, setSrcTasks] = useState<Array<string>>([]);
  const [destTasks, setDestTasks] = useState<Array<string>>([]);
  const [srcTasksLoading, setSrcTasksLoading] = useState<boolean>(false);
  const [destTasksLoading, setDestTasksLoading] = useState<boolean>(false);
  const [reloadTable, setReloadTable] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);

  const { user } = useAuth();
  const hasAccess = checkRole(user?.role?.toString().toUpperCase(), "planning");

  useEffect(() => {
    // Effect to Fetch SRC tasks
    // clear all values and errors
    setSrcTasks([]);
    // fetch
    fetchSrcTasks();
    fetchStagingTasks();
  }, []);

  const startExecution = (data?: {}) => {
    setExecutionLoading(true);
    API.post(PlanningUrls.startExecution + `?user=${user?.name}`, {
      task_types: {
        src: srcTasks.map((e) => e),
      },
      ...data,
    })
      .then((res) => {
        Modal.success({
          title: "Execution Started",
          content: res.data ? res.data.Message : "",
        });
      })
      .catch(({ response }) => {
        if (response) {
          if (response.data.override_flag) {
            showConfirm(
              "Do you want to Override the data?",
              response.data.Message,
              () => startExecution({ override: "True" })
            );
          } else {
            showErrorModel(
              "Error while Execution",
              response.data ? response.data.Message : "Execution Timed Out"
            );
          }
        }
      })
      .finally(() => {
        setExecutionLoading(false);
        setReloadTable((prev) => !prev);
      });
  };

  const StartDestExecution = () => {
    setDestTasksLoading(true);
    API.post(StagingUrls.collect_sites + `?user=${user?.name}`, {
      task_types: {
        dest: destTasks.map((e) => e),
      },
    })
      .then((res) => {
        Modal.success({
          title: "Execution Complete",
          content: res.data ? res.data.Message : "",
        });
      })
      .catch(({ response }) => {
        showErrorModel(
          "Error while Running destination task",
          response.data ? response.data.Message : "Execution Timed Out"
        );
      })
      .finally(() => {
        setDestTasksLoading(false);
      });;
  }

  const fetchSrcTasks = () => {
    setSrcTasksLoading(true);
    API.get(PlanningUrls.srcTasks)
      .then(({ data }) => {
        setSrcTasks(data.task_list);
      })
      .catch(({ response }) => {
        setSrcTasks([]);
      })
      .finally(() => setSrcTasksLoading(false));
  };

  const fetchStagingTasks = () => {
    setDestTasksLoading(true);
    API.get(StagingUrls.baseStagingUrl + StagingUrls.planningStagingTasks)
      .then(({ data }) => {
        setDestTasks(data.task_list);
      })
      .catch(({ response }) => {
        setDestTasks([]);
      })
      .finally(() => setDestTasksLoading(false));
  }

  const srcButtonsConfig: Array<IButtonConfig> = [
    {
      type: "primary",
      variant: "default",
      buttonText: "Start Src Execution",
      disabled: !hasAccess,
      hasToolTipWhenDisabled: true,
      toolTipText: "Your profile is not eligible to perform this action",
      loading: executionLoading,
      onClick: () => startExecution(),
      className: "start-execution",
    },
  ];

  return (
    <>
      <Tour
        steps={planningSteps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
      <ContentTemplate
        title="Planning"
        handleTourSelection={() => setIsTourOpen((open) => !open)}
        showButtons
        showSelectionTables
        showCustomSelectionTable
        CustomSelectionTable={
          <>
            <DataTable
              bordered={true}
              dataSource={srcTasks.map((value, i) => ({
                key: i,
                task: value,
              }))}
              columns={srcTaskColumns}
              size="small"
              pagination={false}
              showHeader
              className="selection-table-max-width-100"
            ></DataTable>
            <div>
              <DataTable
                bordered={true}
                dataSource={destTasks.map((value, i) => ({
                  key: i,
                  task: value,
                }))}
                columns={destTaskColumns}
                size="small"
                pagination={false}
                showHeader
                className="selection-table-max-width-100"
              ></DataTable>
              <Button
                className="gap--top"
                type="primary"
                onClick={() => StartDestExecution()}
                loading={destTasksLoading}
              >
                Start Dest Execution
              </Button>
            </div>
          </>
        }
        buttonsConfig={srcButtonsConfig}
        showMainTable
        mainTable={
          <PlanningTable
            reloadTable={reloadTable}
            setReloadTable={() => setReloadTable((prev) => !prev)}
            showTitle
          />
        }
      />
    </>
  );
};

export default PlanningPage;
