import React from "react";
import { Table, TablePaginationConfig } from "antd";

import "./styles.scss";
import { FilterValue } from "antd/lib/table/interface";

export interface IDataTableType {
  dataSource: any;
  columns: any;
  bordered?: boolean;
  pagination?: false | TablePaginationConfig | undefined;
  loading?: boolean;
  scroll?: any;
  size?: "small" | "middle" | "large" | undefined;
  showHeader?: boolean;
  title?: any;
  expandable?: any;
  rowSelection?: any;
  style?: any;
  className?: any;
  onChange?: (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: any,
    extra: {
      currentDataSource: Array<any>;
      action: "paginate" | "sort" | "filter";
    }
  ) => void;
}

const DataTable: React.FC<IDataTableType> = (props) => {
  return <Table {...props} />;
};

export default DataTable;
