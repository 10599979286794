import React from "react";
import { Menu as AntMenu } from "antd";
import { useLocation, useHistory } from "react-router-dom";

const { Item, SubMenu } = AntMenu;

export interface MenuItem {
  type: "menu-item" | "sub-menu";
  displayText: string;
  items?: Array<MenuItem>;
  icon?: any;
  key: string;
  link?: string;
  onClickCallback?: () => void;
}

interface MenuPropsType {
  menuItems: Array<MenuItem>;
}

export const Menu: React.FC<MenuPropsType> = (props) => {
  const location = useLocation();
  const history = useHistory();
  const renderItems = (items: Array<MenuItem>) => {
    return items.map((menuObj) => {
      if (menuObj.type === "sub-menu" && menuObj.items) {
        return (
          <SubMenu
            key={menuObj.key}
            title={menuObj.displayText}
            icon={menuObj.icon ? <menuObj.icon /> : null}
          >
            {renderItems(menuObj.items)}
          </SubMenu>
        );
      }
      return (
        <Item
          key={menuObj.key}
          icon={menuObj.icon ? <menuObj.icon /> : null}
          onClick={
            menuObj.link
              ? () => history.push(menuObj.link as string)
              : menuObj.onClickCallback
              ? menuObj.onClickCallback
              : () => {}
          }
        >
          {menuObj.displayText}
        </Item>
      );
    });
  };

  const getCurrentSelection = () => {
    const val = props.menuItems.filter(
      (obj) => obj.link === location.pathname
    )[0];
    return val ? [val.key] : [];
  };

  return (
    <AntMenu
      defaultSelectedKeys={["home"]}
      selectedKeys={getCurrentSelection()}
      mode="inline"
    >
      {renderItems(props.menuItems)}
    </AntMenu>
  );
};
