import React from "react";
import { Form, Select, Input, Upload, Button, Space } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { Text } from "../../atoms";
import API from "../../services/API";
import { FeedbackUrl } from "../../services/apiUrls";
import { useSpinner } from "../../context/Spinner.context";
import { showErrorModel, showSuccessModel } from "../../utils";

const { Option } = Select;

interface IFeedbackForm {
  fromLogin?: boolean;
  onAbort?: () => void;
  showTitle?: boolean;
}

const FeedbackForm: React.FC<IFeedbackForm> = ({
  fromLogin,
  onAbort,
  showTitle,
}) => {
  const { setSpinner } = useSpinner();
  const [form] = Form.useForm();

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  const onReset = () => {
    form.resetFields();
  };

  const handleSubmit = (values: any) => {
    const formData = new FormData();
    if (values.attachments) {
      if (values.attachments.length > 0) {
        (values.attachments as []).forEach((file: any) => {
          formData.append("attachments", file.originFileObj as any);
        });
      }
    }
    formData.append("created_by", values.created_by);
    formData.append("description", values.description);
    formData.append("feedback_type", values.feedback_type);
    formData.append("phase_name", values.phase_name);
    formData.append("short_description", values.short_description);

    setSpinner(true);
    API.post(FeedbackUrl, formData, true, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        showSuccessModel("Feedback sent Successfully", res.data.Message);
        onReset()
      })
      .catch(({ response }) =>
        showErrorModel(
          "Feedback Error",
          response && response.data.Message
            ? response.data.Message
            : "Error while submitting feedback"
        )
      )
      .finally(() => setSpinner(false));
  };

  return (
    <React.Fragment>
      {showTitle && (
        <Text title level={3}>
          Feedback
        </Text>
      )}
      <Form
        className="gap--top"
        name="feedback"
        initialValues={{
          phase_name: "Planning",
          feedback_type: fromLogin ? "Login-Issue" : null,
        }}
        onFinish={handleSubmit}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Phase Name"
          name="phase_name"
          rules={[{ required: !fromLogin, message: "This Field is required" }]}
        >
          <Select
            disabled={fromLogin}
            placeholder="Select a Phase from the options"
            allowClear
          >
            <Option value="Planning">Planning</Option>
            <Option value="Prestaging">Pre-Staging</Option>
            <Option value="Staging">Staging</Option>
            <Option value="Pre-Check">Pre-Check</Option>
            <Option value="Post-Check">Post-Check</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Feedback Type"
          name="feedback_type"
          rules={[{ required: true, message: "This Field is required" }]}
        >
          <Select
            disabled={fromLogin}
            placeholder="Select a Feedback type from the options"
            onChange={() => console.log("Feedback type Changed")}
            allowClear
          >
            <Option value="Bug">Bug</Option>
            <Option value="Enhancement">Enhancement</Option>
            <Option value="UAT-Issue">UAT-Issue</Option>
            <Option value="Prod-Issue">Prod-Issue</Option>
            <Option value="Login-Issue">Login-Issue</Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Your Email"
          name="created_by"
          rules={[
            {
              required: true,
              message: "This Field is required",
            },
            {
              type: "email",
              message: "This is not a valid Email",
            },
          ]}
        >
          <Input placeholder="Email for farther contact" />
        </Form.Item>

        <Form.Item
          label="Issue Short Description"
          name="short_description"
          rules={[{ required: true, message: "This Field is required" }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Issue Description"
          name="description"
          rules={[{ required: true, message: "This Field is required" }]}
        >
          <Input.TextArea />
        </Form.Item>

        <Form.Item
          label="Attachments"
          name="attachments"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          rules={[{ required: false }]}
        >
          <Upload
            multiple
            listType="picture"
            beforeUpload={() => false}
            action=""
          >
            <Button icon={<UploadOutlined />}>Click to upload</Button>
          </Upload>
        </Form.Item>

        <Form.Item>
          <Space>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
            <Button type="default" htmlType="reset" onClick={onReset}>
              Reset
            </Button>
          </Space>
          <br />
          {fromLogin && (
            <>
              Or go back to{" "}
              <Button className="m-0 p-0" type="link" onClick={onAbort}>
                Login
              </Button>
            </>
          )}
        </Form.Item>
      </Form>
    </React.Fragment>
  );
};

export default FeedbackForm;
