import React, { useEffect, useState } from "react";
import { StagingExecutionData } from "../../../types";
import {
  Row,
  Col,
  Modal,
  Divider,
  Button,
  Tooltip,
  Select,
  TablePaginationConfig,
  Result,
  Empty,
  Typography,
} from "antd";
import API from "../../../services/API";
import { StagingUrls } from "../../../services/apiUrls";
import ExpandableTable from "../../../organisms/ExpandableTable";
import { columns } from "../StagingTable/config/deviceStatusTableConfig";
import { tableFilterParser } from "../../../utils";
import { Text } from "../../../atoms";
import {
  ReloadOutlined,
  CloudServerOutlined,
  WifiOutlined,
  DeploymentUnitOutlined,
} from "@ant-design/icons";

interface StagingTableType {
  showTitle?: boolean;
  prevStep?: () => void;
  nextStep?: () => void;
  allStage?: boolean;
  site: string[];
}

const PAGE_SIZE = 20;

const DeviceStatus: React.FC<StagingTableType> = ({
  showTitle,
  prevStep,
  nextStep,
  site,
}) => {
  const [switchTableData, setSwitchTableData] = useState<
    StagingExecutionData[]
  >([]);
  const [iapTableData, setIapTableData] = useState<StagingExecutionData[]>([]);
  const [vcTableData, setVcTableData] = useState<StagingExecutionData[]>([]);
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [switchTableLoading, setSwitchTableLoading] = useState<boolean>(false);
  const [iapTableLoading, setIapTableLoading] = useState<boolean>(false);
  const [vcTableLoading, setVcTableLoading] = useState<boolean>(false);
  const [params, setParams] = useState("");
  const [SWPagination, setSWPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: PAGE_SIZE,
    total: undefined,
    showSizeChanger: true,
  });
  const [IAPPagination, setIAPPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: PAGE_SIZE,
    total: undefined,
    showSizeChanger: true,
  });
  const { Title } = Typography;
  let siteOptions: any[] = [];
  const { Option } = Select;

  site.forEach((siteNumber: string) => {
    siteOptions.push(<Option value={siteNumber}>{siteNumber}</Option>);
  });

  useEffect(() => {
    setSelectedSite(site[0]);
    getDeviceStatus("switch", site[0]);
    getDeviceStatus("ap", site[0], "vc");
    getDeviceStatus("ap", site[0]);
  }, []);

  const changeSite = (value: any) => {
    setSelectedSite(value);
    getDeviceStatus("switch", value);
    getDeviceStatus("ap", value, 'vc');
    getDeviceStatus("ap", value);
  };

  const getDeviceStatus = (type: string, site: string, vc?: string) => {
    const formData = new FormData();
    formData.append("site", site);
    formData.append("type", type);
    if (vc) {
      formData.append("vc", "vc");
    }
    type === "switch" ? setSwitchTableLoading(true) : vc ? setVcTableLoading(true) : setIapTableLoading(true);
    API.post(StagingUrls.deviceStatusUrl, formData)
      .then((res) => {
        if (res?.data) {
          type === "switch" ? setSwitchTableData(res?.data?.data) :
            vc ? setVcTableData(res?.data?.data) : setIapTableData(res?.data?.data);
        }
      })
      .catch(({ response }) => {
        Modal.destroyAll();
        response &&
          Modal.error({
            title: "Error while Execution",
            content: response ? response.data.Message : "Execution Timed Out",
          });
      })
      .finally(() => {
        type === "switch"
          ? setSwitchTableLoading(false)
          : vc ? setVcTableLoading(false)
          : setIapTableLoading(false);
      });
  };

  const swTableChangeHandler = ({ pagination, filters, sorter }: any) => {
    setSWPagination(pagination);
    setParams(tableFilterParser({ pagination, filters, sorter }));
  };

  const iapTableChangeHandler = ({ pagination, filters, sorter }: any) => {
    setIAPPagination(pagination);
    setParams(tableFilterParser({ pagination, filters, sorter }));
  };

  const refreshSwitch = () => {
    getDeviceStatus("switch", selectedSite);
  };

  const refreshIap = () => {
    getDeviceStatus("ap", selectedSite);
  };

  const refreshVc = () => {
    getDeviceStatus("ap", selectedSite, 'vc');
  };

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: "10px" }}>
        <Col>
          <Text strong>Site ID selected: </Text>
          <Select
            size="small"
            defaultValue={site[0]}
            onChange={changeSite}
            style={{ width: 100 }}
          >
            {siteOptions}
          </Select>
        </Col>
        <Col>
          <span className="flex flex--row">
            <Button onClick={prevStep} className="refresh-button gap--right">
              Go back
            </Button>
            <Button
              onClick={nextStep}
              className="next-store-btn"
              type="primary"
            >
              Goto Execution
            </Button>
          </span>
        </Col>
      </Row>
      <Divider style={{ marginTop: "0" }} />
      <Row justify="space-around" gutter={16}>
        <Col span={12}>
          {switchTableData.length === 0 ? (
            <Result
              status="success"
              title="All Switches are online on Central!"
              icon={<CloudServerOutlined />}
              extra={[
                <Button
                  type="primary"
                  key="refresh"
                  onClick={refreshSwitch}
                  loading={switchTableLoading}
                >
                  Check current status
                </Button>,
              ]}
            />
          ) : (
            <ExpandableTable
              phase="Staging"
              className="execution-data-table gap--top"
              tableProps={{
                dataSource: switchTableData,
                columns: columns,
                className: "selection-table-max-width-100",
                pagination: SWPagination,
                onChange: (pagination, filters, sorter) =>
                  swTableChangeHandler({ pagination, filters, sorter }),
                title: showTitle
                  ? () => (
                      <div className="flex flex--row">
                        <Text className="flex--grow" title level={5}>
                          Offline Switches
                        </Text>
                        <Tooltip
                          title="Fetch offline switches"
                          placement="left"
                        >
                          <Button
                            onClick={refreshSwitch}
                            className="refresh-button"
                            type="primary"
                            icon={<ReloadOutlined />}
                            shape="circle"
                            size="small"
                            loading={switchTableLoading}
                          ></Button>
                        </Tooltip>
                      </div>
                    )
                  : null,
                loading: switchTableLoading,
              }}
              expandedSelectionColumnValues={[]}
            />
          )}
        </Col>
        <Col span={12}>
          {vcTableData.length === 0 ? (
            <Empty
              style={{
                fontSize: "24px",
                color: "rgba(0, 0, 0, 0.85)",
                textAlign: "center",
                lineHeight: "1.8",
              }}
              description={"VC is online on Central!"}
              image={
                <DeploymentUnitOutlined
                  style={{ fontSize: "54px", color: "#52c41a" }}
                />
              }
            >
              <Button
                type="primary"
                key="refresh"
                onClick={refreshVc}
                loading={vcTableLoading}
              >
                Check VC status
              </Button>
            </Empty>
          ) : (
            <ExpandableTable
              phase="Staging"
              className="execution-data-table gap--top"
              tableProps={{
                dataSource: vcTableData,
                columns: columns,
                className: "selection-table-max-width-100",
                pagination: false,
                title: showTitle
                  ? () => (
                      <div className="flex flex--row">
                        <Text className="flex--grow" title level={5}>
                          VC Status
                        </Text>
                        <Tooltip title="Fetch offline IAPs" placement="left">
                          <Button
                            disabled={vcTableLoading}
                            onClick={refreshVc}
                            className="refresh-button"
                            type="primary"
                            icon={<ReloadOutlined />}
                            size="small"
                            shape="circle"
                            loading={vcTableLoading}
                          ></Button>
                        </Tooltip>
                      </div>
                    )
                  : null,
                loading: vcTableLoading,
              }}
              expandedSelectionColumnValues={[]}
            />
          )}
          {iapTableData.length === 0 ? (
            <Result
              status="success"
              title="All IAPs are online on Central!"
              icon={<WifiOutlined />}
              extra={[
                <Button
                  type="primary"
                  key="refresh"
                  onClick={refreshIap}
                  loading={iapTableLoading}
                >
                  Check current status
                </Button>,
              ]}
            />
          ) : (
            <ExpandableTable
              phase="Staging"
              className="execution-data-table gap--top"
              tableProps={{
                dataSource: iapTableData,
                columns: columns,
                className: "selection-table-max-width-100",
                pagination: IAPPagination,
                onChange: (pagination, filters, sorter) =>
                  iapTableChangeHandler({ pagination, filters, sorter }),
                title: showTitle
                  ? () => (
                      <div className="flex flex--row">
                        <Text className="flex--grow" title level={5}>
                          Offline IAPs
                        </Text>
                        <Tooltip title="Fetch offline IAPs" placement="left">
                          <Button
                            disabled={iapTableLoading}
                            onClick={refreshIap}
                            className="refresh-button"
                            type="primary"
                            icon={<ReloadOutlined />}
                            size="small"
                            shape="circle"
                            loading={iapTableLoading}
                          ></Button>
                        </Tooltip>
                      </div>
                    )
                  : null,
                loading: iapTableLoading,
              }}
              expandedSelectionColumnValues={[]}
            />
          )}
        </Col>
      </Row>
    </>
  );
};

export default DeviceStatus;
