import { Layout, Result } from "antd";
import { Switch, Route, Link, Redirect } from "react-router-dom";
import { Routes, GxoRoutes } from "../routes/config";
import Header from "../organisms/Header";
import { Button, Text } from "../atoms";
import "./styles.scss";
import SideNav from "../organisms/SideNav";
import { useAuth } from "../context/Auth";
import { ProtectedRoute } from "../routes";
import LoginPage from "./LoginPage";
import ResetPasswordPage from "./ResetPasswordPage";
import Icon from "@ant-design/icons";
import { AccessDeniedSvg } from "../assets/vector/access_denied";
import React from "react";

const { Content, Footer } = Layout;
const ForbiddenIcon = (props: any) => (
  <Icon component={AccessDeniedSvg} {...props} />
);

const Wrapper: React.FC = ({ children }) => {

  return (
    <Layout className="container">
      <Header />
      <Layout>
        <SideNav />
        <Layout>
          <Content>
            {children}
          </Content>
          <Footer>
            <Text
              styles={{
                color: "rgba(0,0,0,.45)",
              }}
            >
              Copyright &copy; 2022 The Home Depot. All rights reserved
            </Text>
          </Footer>
        </Layout>
      </Layout>
    </Layout>
  );
};

const MainLayout: React.FC = () => {
  const { user } = useAuth();

  return (
    <Switch>
      {user?.role[0].toLowerCase() === "gxo_staging_user"
        ? GxoRoutes.map((route, i) => (
            <ProtectedRoute key={i} path={route.path} exact>
              <Wrapper>
                <route.component />
              </Wrapper>
            </ProtectedRoute>
          ))
        : Routes.map((route, i) => (
            <ProtectedRoute key={i} path={route.path} exact>
              <Wrapper>
                <route.component />
              </Wrapper>
            </ProtectedRoute>
          ))}
      <Route
        path="/login"
        render={() =>
          user === null ? (
            <LoginPage />
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
        }
        exact
      />
      <Route
        path="/password-reset"
        render={() =>
          user === null ? (
            <ResetPasswordPage />
          ) : (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )
        }
        exact
      />
      <Route
        path="/logged-out"
        render={() => (
          <Result
            status="403"
            title="Looks like you're logged out!"
            subTitle="Please login again to continue"
            extra={
              <Button type="primary">
                <Link to="/login">Login</Link>
              </Button>
            }
          />
        )}
      />
      <Route
        path="/forbidden"
        render={() => (
          <Result
            status="error"
            icon={<ForbiddenIcon style={{ color: "hotpink" }} />}
            title={
              <h2>
                <span style={{ color: "red" }}>403 </span>
                <span style={{ fontSize: "20px" }}>
                  Sorry! Access denied :(
                </span>
              </h2>
            }
            subTitle={
              <div>
                You do not have enough permission to open this website.
                <div>
                  If you're a new user, please reach out to site admin /
                  <a href="mailto:thd.dev@hpe.com?subject=THD_Migration Access Request">
                    {" "}
                    Click here
                  </a>{" "}
                  to send an email for access
                </div>
              </div>
            }
            extra={[
              <Button type="primary">
                {user?.role[0].toLowerCase() === "gxo_staging_user" ? (
                  <Link to="/">Back to staging</Link>
                ) : (
                  <Link to="/login">Back to Login</Link>
                )}
              </Button>,
            ]}
          />
        )}
        exact
      />
      <Route
        path="*"
        render={() => (
          <Result
            status="404"
            title="404"
            subTitle="Sorry, the page you visited does not exist."
            extra={
              <Button type="primary">
                <Link to="/">Back Home</Link>
              </Button>
            }
          />
        )}
      />
    </Switch>
  );
};

export default MainLayout;
