import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";

const { confirm } = Modal;

export const showConfirm = (
  confirmTitle: string,
  confirmDescription: string,
  onOk: () => void,
  onCancel?: () => void
) => {
  confirm({
    centered: true,
    title: confirmTitle,
    icon: <ExclamationCircleOutlined />,
    content: confirmDescription,
    onOk,
    onCancel,
  });
};

export const showErrorModel = (errorTitle: string, errorContent: string) => {
  Modal.error({
    centered: true,
    title: errorTitle,
    content: errorContent,
  });
};

export const showSuccessModel = (
  successTitle: string,
  successContent: string
) => {
  Modal.success({
    centered: true,
    title: successTitle,
    content: successContent,
  });
};

export const showInfoModel = (infoTitle: string, infoContent: string) => {
  Modal.info({
    centered: true,
    title: infoTitle,
    content: infoContent,
  });
};
