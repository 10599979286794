import React, { useState } from "react";
import { Upload as AntUpload, Dropdown, Modal, Tooltip } from "antd";
import { UploadOutlined, EllipsisOutlined } from "@ant-design/icons";
import { Button } from "../../atoms";

import "./styles.scss";
import { ShowUploadListInterface, UploadFile } from "antd/lib/upload/interface";
import { getCookie } from "../../utils/cookies";
import { BASE_URL } from "../../services/API";

export interface IUploadType {
  type: "default" | "dropdown";
  accept?: string;
  uploadUrl?: string;
  buttonText: string;
  menu?: JSX.Element;
  disabled?: boolean;
  data?: any;
  showFileList?: boolean | ShowUploadListInterface;
  customUpload?: boolean;
  beforeUploadCallback?: (file: any | null) => void;
  defaultFileList?: UploadFile[];
  multiple?: boolean;
  className?: string;
  hasToolTipWhenDisabled?: boolean;
  toolTipText?: string;
}

const Upload: React.FC<IUploadType> = ({
  uploadUrl,
  accept,
  buttonText,
  type,
  menu,
  disabled,
  data,
  showFileList,
  beforeUploadCallback,
  customUpload,
  defaultFileList,
  multiple,
  className,
  hasToolTipWhenDisabled,
  toolTipText,
}) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const uploadProps = {
    name: "file",
    action: BASE_URL + (uploadUrl ? uploadUrl : ""),
    data: data,
    headers: {
      "X-CSRFToken": `${getCookie("csrftoken")}`,
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
    withCredentials: true,
  };

  const customUploadProps = {
    beforeUpload: (file: UploadFile, fileList: UploadFile[]) => {
      beforeUploadCallback && beforeUploadCallback(multiple ? fileList : file);
      return false;
    },
    onRemove: (file: UploadFile) => {
      beforeUploadCallback && beforeUploadCallback(null);
      return true;
    },
  };

  return type === "default" ? (
    <Tooltip
      title={
        disabled && hasToolTipWhenDisabled && toolTipText ? toolTipText : ""
      }
    >
      <AntUpload
        className={className}
        maxCount={multiple ? undefined : 1}
        multiple={multiple}
        showUploadList={showFileList}
        accept={accept}
        disabled={disabled}
        defaultFileList={defaultFileList}
        onChange={(info) => {
          if (info.file.status === "uploading") {
            setUploading(true);
          }
          if (info.file.status === "done") {
            Modal.success({
              title: "Upload Status",
              content: `Upload Message: ${
                info.file.response && info.file.response.Message
                  ? info.file.response.Message
                  : "Successful"
              }`,
            });
            setUploading(false);
          } else if (info.file.status === "error") {
            Modal.error({
              title: "Upload Failed",
              content: `Upload Error: ${
                info.file.response && info.file.response.Message
                  ? info.file.response.Message
                  : "Execution Timed out"
              }`,
            });
            setUploading(false);
          }
        }}
        {...uploadProps}
        {...(customUpload ? customUploadProps : {})}
      >
        <Button
          loading={uploading}
          disabled={disabled}
          icon={<UploadOutlined />}
        >
          {buttonText}
        </Button>
      </AntUpload>
    </Tooltip>
  ) : type === "dropdown" && menu ? (
    <span className="flex flex--row">
      <AntUpload
        className="upload-element"
        maxCount={multiple ? undefined : 1}
        multiple
        disabled={disabled}
        accept={accept}
        defaultFileList={defaultFileList}
        showUploadList={showFileList}
        onChange={(info) => {
          if (info.file.status === "uploading") {
            setUploading(true);
          }
          if (info.file.status === "done") {
            Modal.success({
              title: "Upload Status",
              content: `Upload Message: ${
                info.file.response && info.file.response.Message
                  ? info.file.response.Message
                  : info.file.response
              }`,
            });
            setUploading(false);
          } else if (info.file.status === "error") {
            Modal.error({
              title: "Upload Failed",
              content: `Upload Error: ${
                info.file.response && info.file.response.Message
                  ? info.file.response.Message
                  : "Execution Timed out"
              }`,
            });
            setUploading(false);
          }
        }}
        {...uploadProps}
        {...(customUpload ? customUploadProps : {})}
      >
        <Button
          loading={uploading}
          disabled={disabled}
          icon={<UploadOutlined />}
        >
          {buttonText}
        </Button>
      </AntUpload>
      <Dropdown
        className="dropdown-button"
        placement="bottomLeft"
        arrow
        disabled={disabled}
        overlay={menu}
      >
        <Button icon={<EllipsisOutlined />} />
      </Dropdown>
    </span>
  ) : null;
};

export default Upload;
