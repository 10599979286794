export const planningSteps = [
  {
    selector: ".selection-table-max-width-50",
    content: "Select all the tasks",
  },
  {
    selector: ".start-execution",
    content: "Click the button to start the task execution",
  },
  {
    selector: ".execution-data-table",
    content: "Run status will be appended to execution data table",
  },
  {
    selector: ".refresh-button",
    content:
      "If you do not see the status of current execution, refresh the table by clicking on the button",
  },
];
