import React from "react";
import { Button as AntButton } from "antd";

interface ButtonPropsType {
  type?:
    | "link"
    | "text"
    | "ghost"
    | "default"
    | "primary"
    | "dashed"
    | undefined;
  onClick?: () => void;
  shape?: "circle" | "round" | undefined;
  disabled?: boolean;
  icon?: React.ReactNode;
  size?: "large" | "middle" | "small";
  loading?: boolean;
  className?: string;
  style?: any;
}

export const Button: React.FC<ButtonPropsType> = (props) => {
  return <AntButton {...props}>{props.children}</AntButton>;
};
