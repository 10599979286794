import React from "react";
import { Input } from "antd";
import { EyeInvisibleOutlined, EyeOutlined } from "@ant-design/icons";

import "./styles.scss";

const { TextArea, Search, Password } = Input;

interface InputFieldType {
  placeholder?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  type?: "text-area" | "search" | "password";
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  allowClear?: boolean;
  className?: string;
}

export const InputField: React.FC<InputFieldType> = (props) => {
  return props.type === "text-area" ? (
    <TextArea {...(props as any)} />
  ) : props.type === "search" ? (
    <Search {...props} />
  ) : props.type === "password" ? (
    <Password
      {...props}
      iconRender={(visible) =>
        visible ? <EyeOutlined /> : <EyeInvisibleOutlined />
      }
    />
  ) : (
    <Input {...props} />
  );
};
