import React, { useEffect, useState } from "react";
import { IButtonConfig } from "../../organisms/ActionButtons";
import ContentTemplate from "../../templates/ContentTemplate";
import PreCheckTable from "./PreCheckTable";
import { useSpinner } from "../../context/Spinner.context";
import { DownloadDb } from "../../utils/fileDownload";
import { DownloadUrls, PreCheckUrls } from "../../services/apiUrls";
import { useAuth } from "../../context/Auth";
import { preCheckSteps } from "./tourConfig";
import Tour from "reactour";
import { checkRole } from "../../services/authorization";
import API, { BASE_URL } from "../../services/API";
import {Modal} from "antd";

interface ITaskType {
  key: string;
  task: string;
}

const PreCheck: React.FC = () => {
  const { setSpinner } = useSpinner();
  const [iapTasks, setIAPTasks] = useState<Array<string>>([]);
  const [iapTasksLoading, setIAPTasksLoading] = useState<boolean>(false);
  const [selectedIAPTasks, setSelectedIAPTasks] = useState<Array<ITaskType>>(
    []
  );
  const [siteId, setSiteId] = useState<Array<string>>([]);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [reloadTable, setReloadTable] = useState(false);
  const { user } = useAuth();
  const hasAccess = checkRole(user?.role?.toString().toUpperCase(), "precheck");

  useEffect(() => {
    fetchIAPTasks();
  }, []);

  const isDisabled = () => {
    if (siteId.length !== 0) {
        return false;
    }
    return true;
  };

  const fetchIAPTasks = () => {
    setIAPTasksLoading(true);
    API.get(PreCheckUrls.iapExecutionUrl + PreCheckUrls.iapTasksParam)
      .then(({ data }) => {
        setIAPTasks(data.task_list);
        setSelectedIAPTasks(
          data.task_list.map((value: string, key: string) => value)
        );
      })
      .catch(({ response }) => {
        setIAPTasks([]);
      })
      .finally(() => setIAPTasksLoading(false));
  };

  const executeIap = () => {
    console.log(selectedIAPTasks);
    setSpinner(true);
    const formData = new FormData();
    formData.append(
      "task_types",
      JSON.stringify({
        iap: selectedIAPTasks.map((task ) => task),
      })
    );
    formData.append("site_list", JSON.stringify(siteId));
    API.post(
      PreCheckUrls.iapExecutionUrl + `?user=${user?.name}`,
      formData,
      true,
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .then((res) => {
        setSpinner(false);
        Modal.success({
          title: "Execution Started",
          content: res.data ? res.data.Message : "",
        });
      })
      .finally(() => {
        setSpinner(false);
      });
  };
  
  const download =  (
    url: string,
    fileName: string,
    siteList: string[],
    user: string | undefined
  ) => {
    setSpinner(true);
    API.get(url + `?site_list=${JSON.stringify(siteList)}&user=${user}`).then((response:any)=>{
        Modal.success({title:"PreCheck Download", content:response?.data?.Message})
        }
    ).catch(({response})=>{
        Modal.error({title:"PreCheck Download", content:response?.data?.Message})
    }).finally(()=>{
        setSpinner(false);
    })
  };

  const buttonsConfig: Array<IButtonConfig> = [
    {
      type: "primary",
      variant: "default",
      buttonText: "Download DB",
      disabled: isDisabled() || !hasAccess,
      className: "download-db-button",
      hasToolTipWhenDisabled: true,
      toolTipText: !hasAccess
        ? "Your profile is not eligible to perform this action"
        : isDisabled()
        ? "Please enter site number before downloading the db file"
        : undefined,
      onClick: () =>
        download(
          DownloadUrls.preCheckDbDownload,
          "pre-check.db",
          siteId,
          user?.name
        ),
    },
    {
      type: "default",
      variant: "custom-upload",
      buttonText: "Upload DB",
      className: "upload-db-button",
      disabled: isDisabled() || !hasAccess,
      hasToolTipWhenDisabled: true,
      toolTipText: !hasAccess
        ? "Your profile is not eligible to perform this action"
        : undefined,
      uploadConfig: {
        uploadUrl: BASE_URL + PreCheckUrls.dbUploadUrl + `?user=${user?.name}`,
        data: { site_list: JSON.stringify(siteId), override: false },
        disabled: isDisabled(),
        accept: ".db",
      },
    },
    {
      type: "default",
      variant: "default",
      buttonText: "Execute IAP",
      disabled: isDisabled() || !hasAccess,
      className: "download-db-button",
      hasToolTipWhenDisabled: true,
      toolTipText: !hasAccess
        ? "Your profile is not eligible to perform this action"
        : isDisabled()
        ? "Please enter site number before downloading the db file"
        : undefined,
      onClick: () => executeIap(),
    },
  ]
  return (
    <>
      <Tour
        steps={preCheckSteps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
      />
      <ContentTemplate
        title="Pre-Check"
        handleTourSelection={() => setIsTourOpen((open) => !open)}
        showButtons
        showSearchBar
        searchBarOptions={{
          onChangeHandler: (sel) => setSiteId(sel.sites),
          showInfoIcon: true,
          infoText: "Enter up-to 5 Sites",
          error: false,
          errorText: "Please enter only five sites",
          inputType: "multiple",
          disabled: !hasAccess,
          hasToolTipWhenDisabled: true,
          toolTipText: "Your profile is not eligible to perform this action",
        }}
        buttonsConfig={buttonsConfig}
        showMainTable
        mainTable={
          <PreCheckTable
            reloadTable={reloadTable}
            setReloadTable={() => setReloadTable((prev) => !prev)}
            showTitle
          />
        }
      />
    </>
  );
};

export default PreCheck;
