import React from "react";
import ContentTemplate from "../../templates/ContentTemplate";
import AllStageDetailsTable from "./AllStageDetailsTable";

const AllStageDetails: React.FC = () => {
  return (
    <ContentTemplate
      title="All Stage Details"
      showMainTable
      mainTable={<AllStageDetailsTable />}
    />
  );
};

export default AllStageDetails;
