import PlanningTable from "../pages/PlanningPage/PlanningTable";
import PostCheckTable from "../pages/PostCheckPage/PostCheckTable";
import PreStagingTable from "../pages/Pre-Staging/PreStagingTable";
import PreCheckTable from "../pages/PreCheckPage/PreCheckTable";
import StagingTable from "../pages/StagingPage/StagingTable";

export const getCustomTable = (tableName: string) => {
  switch (tableName) {
    case "planning":
      return PlanningTable;
    case "pre-staging":
      return PreStagingTable;
    case "staging":
      return StagingTable;
    case "pre-check":
      return PreCheckTable;
    case "post-check":
      return PostCheckTable;
    default:
      return PreStagingTable;
  }
};
