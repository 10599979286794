import { Tag } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

export const columns = [
  {
    title: "Device Type",
    dataIndex: "device_type",
    key: "device_type",
  },
  {
    title: "Serial Number",
    dataIndex: "serial",
    key: "serial",
  },
  {
    title: "Mac Address",
    dataIndex: "mac_address",
    key: "mac_address",
  },
  {
    title: "Device Label",
    dataIndex: "device_label",
    key: "device_label",
  },
  {
    title: "Status",
    dataIndex: "validation_result",
    key: "validation_result",
    render: (status: string, data: any) =>
      status === "Passed" ? (
        <Tag icon={<CheckCircleOutlined />} color="success">
          Passed
        </Tag>
      ) : status === "In Progress" ? (
        <Tag icon={<SyncOutlined spin />} color="processing">
          In Progress
        </Tag>
      ) : (
        <Tag icon={<CloseCircleOutlined />} color="error">
          Error
        </Tag>
      ),
  },
  {
    title: "Workbook validation Error",
    dataIndex: "validation_message",
    key: "validation_message",
  },
];


export const statusColumns = [
  {
    title: "Tasks",
    dataIndex: "task",
    key: "key",
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "key",
    render: (status: string) => (
      <Tag
        icon={
          status === "Not Executed" ? undefined : 
          status === "In Progress" ? (
            <SyncOutlined spin />
          ) : status === "Successfully executed" ? (
            <CheckCircleOutlined />
          ) : (
            <CloseCircleOutlined />
          )
        }
        color={
          status === "Not Executed" ? undefined : status === "In Progress"
            ? "processing"
            : status === "Successfully executed"
            ? "success"
            : "error"
        }
      >
        {status}
      </Tag>
    ),
  },
];