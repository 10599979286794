export const AuthUrls = {
  login: "/accounts/login/",
  logout: "/accounts/logout/",
  resetPassword: "/accounts/change-password/",
  test: "/accounts/test/",
  verify: "/accounts/check-logged-on/",
  samlToken: "/accounts/get-saml-token/",
  adminVerify: "/staging/admin_page_redirection/",
};

export const FeedbackUrl = "/feedback/feedback/";

export const DownloadUrls = {
  downloadUrl: "/prestaging/download/",
  bulkSiteTemplate: "?type=site_template",
  missingVariableTemplate: "?type=missing_var_template",
  preCheckDbDownload: "/precheck/execute-precheck/",
  postCheckDbDownload: "/postcheck/execute-postcheck/",
  manualDownload: "/prestaging/download_manuals/",
};

export const StagingUrls = {
  baseStagingUrl: "/staging/execute_staging/",
  switchTasksParam: "?type=staging_switch_task_list",
  iapTasksParam: "?type=staging_iap_task_list",
  planningStagingTasks: "?type=planning_task_list",
  rollback: "/staging/execute_staging_rollback/",
  workbook: "/staging/execute_staging_workbook/",
  executionTableUrl: "/execution/details/?phase=Staging",
  taskStatusUrl: "/staging/status-staging/",
  taskTerminationUrl: "/staging/terminate_celery_task/",
  gxoWorkbookStatus: "/staging/gxo-workbook",
  gxoExecutionStatus: "/staging/gxo-staging",
  gxoDeviceStatus: "/staging/gxo-device-stg",
  siteValidation: "/staging/site_validation/",
  get_pod_ids: "/staging/get_pod_ids/",
  execute_staging_validation: "/staging/execute_staging_validation/",
  deviceStatusUrl: "/staging/execute_staging_offline_device/",
  setStoreComplete: "/staging/set_store_complete/",
  collect_sites: "/staging/collect_sites/",
};

export const PreStagingUrls = {
  startExecution: "/prestaging/execute-pre-staging/",
  switchTasksUrl:
    "/prestaging/execute-pre-staging/?type=prestaging_switch_task_list",
  centralTasksUrl:
    "/prestaging/execute-pre-staging/?type=prestaging_central_tasks",
  iapTasksUrl: "/prestaging/execute-pre-staging/?type=prestaging_iap_task_list",
  csrConfigUrl: "/prestaging/upload-csr-config/",
  masterSheet: "/prestaging/upload-master-sheet/",
  executionTableUrl: "/execution/details/?phase=PreStaging",
  taskStatusUrl: "/prestaging/status-pre-staging/",
};

export const PlanningUrls = {
  startExecution: "/planning/execute_planning/",
  srcTasks: "/planning/execute_planning/?type=planning_src_task_list",
  executionTableUrl: "/execution/details/?phase=Planning",
  taskStatusUrl: "/planning/status-planning/",
};

export const PreCheckUrls = {
  dbUploadUrl: "/precheck/execute-precheck/",
  logUploadurl: "/precheck/upload-log/",
  executionTableUrl: "/execution/details/?phase=PreCheck",
  iapExecutionUrl: "/precheck/iap_precheck_execution/",
  iapTasksParam: "?type=iap_task_list",
};

export const PostCheckUrls = {
  dbUploadUrl: "/postcheck/execute-postcheck/",
  logUploadurl: "/postcheck/upload-log/",
  executionTableUrl: "/execution/details/?phase=PostCheck",
  baseCopySiteValidation: "postcheck/postcheck_basecopy_check/",
  iapExecutionUrl: "/postcheck/iap_postcheck_execution/",
  iapTasksParam: "?type=iap_task_list",
};

export const AllStageDetailsUrls = {
  executionTableUrl: "/execution/sitewise/?",
};

export const ActiveSchedulingJobsUrls = {
  activeSchedulingTableUrl: "/feedback/active_scheduling_jobs/",
  bannerNotification: "/feedback/banner_notification/",
};