import { getColumnSearchProps } from "../../utils/getColumnSearch";

export const columns = [
  {
    title: "Job ID",
    dataIndex: "scheduled_job_id",
    key: "scheduled_job_id",
    sorter: true,
    ...getColumnSearchProps("scheduled_job_id"),
  },
  {
    title: "Job Name",
    dataIndex: "scheduled_job_name",
    key: "scheduled_job_name",
    ...getColumnSearchProps("scheduled_job_name"),
  },
  {
    title: "Job Status",
    dataIndex: "scheduled_job_state",
    key: "scheduled_job_state",
    ...getColumnSearchProps("scheduled_job_state"),
  },
  {
      title: "Job Arguments",
      dataIndex: "scheduled_job_args",
      key: "scheduled_job_args",
      ...getColumnSearchProps("scheduled_job_args"),
    },
  {
    title: "Job Result",
    dataIndex: "scheduled_job_result",
    key: "scheduled_job_result",
    ...getColumnSearchProps("scheduled_job_result"),
  },
  {
    title: "Job Start Time",
    dataIndex: "scheduled_job_started",
    key: "scheduled_job_started",
    sorter: true,
  },
  {
    title: "Job End time",
    dataIndex: "scheduled_job_endtime",
    key: "scheduled_job_endtime",
    sorter: true,
  },
];

export const data = [
  {
    key: 1,
    scheduled_job_id: "001",
    site_name: "ST0001",
    stage_name: "planning",
    type: "Switch",
    task_name: "Switch task name",
    execution_start_time: "12:03",
    exec_requested_by_user: "Yashas",
  },
  {
    key: 2,
    site_name: "ST0002",
    scheduled_job_id: "002",
    stage_name: "planning",
    type: "IAP",
    task_name: "IAP task name",
    execution_start_time: "12:03",
    exec_requested_by_user: "Yashas",
  },
];
