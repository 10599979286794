import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Tag,
  Checkbox,
  Modal,
  Divider,
  Button,
  Tooltip,
  Select,
  TablePaginationConfig,
  Collapse,
} from "antd";
import { StagingExecutionData } from "../../../types";
import API from "../../../services/API";
import { DownloadUrls, StagingUrls } from "../../../services/apiUrls";
import ExpandableTable from "../../../organisms/ExpandableTable";
import { Text } from "../../../atoms";
import { TaskTypeDropDownColumns } from "../../../constants/otherTableConfigs";
import { useAuth } from "../../../context/Auth";
import {
  ReloadOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import { DownloadFile } from "../../../utils/fileDownload";
import { DOWNLOAD_TYPES } from "../../../constants/downloadConstants";
import { RadioGroup } from "../../../atoms/RadioGroup";
import ContentTemplate from "../../../templates/ContentTemplate";
import { iapColumns, switchColumns } from "../../../constants/mockData";
import { checkRole } from "../../../services/authorization";
import { tableFilterParser } from "../../../utils";
import StagingTable from "../StagingTable";
import { modalPopUp } from "../StagingTable/config/stagingExecutionTableConfig";

const { Panel } = Collapse;

interface StagingTableType {
  showTitle?: boolean;
  prevStep?: () => void;
  nextStep?: () => void;
  site: string[];
}

interface ITaskType {
  key: string;
  task: string;
}

const PAGE_SIZE = 5;

const GxoExecution: React.FC<StagingTableType> = ({
  showTitle,
  prevStep,
  nextStep,
  site,
}) => {
  const [switchTableData, setSwitchTableData] = useState<
    StagingExecutionData[]
  >([]);
  const [selectedSwitchTasks, setSelectedSwitchTasks] = useState<
    Array<ITaskType>
  >([]);
  const [selectedIAPTasks, setSelectedIAPTasks] = useState<Array<ITaskType>>(
    []
  );
  const [SWPagination, setSWPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: PAGE_SIZE,
    total: undefined,
    showSizeChanger: true,
  });
  const [IAPPagination, setIAPPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: PAGE_SIZE,
    total: undefined,
    showSizeChanger: true,
  });
  const [iapTableData, setIapTableData] = useState<StagingExecutionData[]>([]);
  const [executeSwitch, setExecuteSwitch] = useState<boolean>(false);
  const [executeIap, setExecuteIAP] = useState<boolean>(false);
  const [startExec, setStartExec] = useState<boolean>(false);
  const [switchTableLoading, setSwitchTableLoading] = useState<boolean>(false);
  const [iapTableLoading, setIapTableLoading] = useState<boolean>(false);
  const [switchTasks, setSwitchTasks] = useState<Array<string>>([]);
  const [iapTasks, setIAPTasks] = useState<Array<string>>([]);
  const [switchOutputFile, setSwitchOutputFile] = useState<string>("");
  const [iapOutputFile, setIapOutputFile] = useState<string>("");
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [podIds, setPodIds] = useState<Array<any>>([]);
  const [selectedPodId, setSelectedPodId] = useState<string>("");
  const [execType, setExecType] = useState<"exec" | "rollback">("exec");
  const [rollbackLoading, setRollbackLoading] = useState(false);
  const [params, setParams] = useState("");
  const [reloadTable, setReloadTable] = useState(false);

  const { user } = useAuth();
  let siteOptions: any[] = [];
  const { Option } = Select;
  const hasAccess = checkRole(user?.role?.toString().toUpperCase(), "staging");

  site.forEach((siteNumber: string) => {
    siteOptions.push(<Option value={siteNumber}>{siteNumber}</Option>);
  });

  useEffect(() => {
    getPodIds();
    fetchSwitchTasks();
    fetchIAPTasks();
    setSwitchTableLoading(true);
    setIapTableLoading(true);
    setStartExec(false);
    setSelectedSite(site[0]);
    API.get(StagingUrls.gxoExecutionStatus + `/?site=${site[0]}`)
      .then((res) => {
        if (res.status === 200) {
          setSwitchTableData(res?.data?.data?.switch);
          setIapTableData(res?.data?.data?.iap);
          setSwitchOutputFile(res?.data?.sw_report_file);
          setIapOutputFile(res?.data?.iap_report_file);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setSwitchTableLoading(false);
    setIapTableLoading(false);
  }, []);

  useEffect(() => {
    API.get(StagingUrls.gxoExecutionStatus + `/?site=${site[0]}`)
      .then((res) => {
        if (res.status === 200) {
          setSwitchTableData(res?.data?.data?.switch);
          setIapTableData(res?.data?.data?.iap);
          setStartExec(!res?.data?.exec_status);
          setSwitchOutputFile(res?.data?.sw_report_file);
          setIapOutputFile(res?.data?.iap_report_file);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const columns = [
    {
      title: "Device",
      dataIndex: "host_name",
      key: "host_name",
    },
    {
      title: "Serial Number/ Mac Address",
      dataIndex: "serial",
      key: "serial",
      render: (text: any, data: any) => (
        <>
          {text} <br />
          {data["mac_address"].toUpperCase()}
        </>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (status: string, data: any) => (
        <Row>
          <Col>
            <Tag
              icon={
                status === "Completed" ? (
                  <CheckCircleOutlined />
                ) : status === "In Progress" ? (
                  <LoadingOutlined />
                ) : status === "Not Executed" ? undefined : (
                  <CloseCircleOutlined />
                )
              }
              color={
                status === "Completed"
                  ? "success"
                  : status === "In Progress"
                  ? "processing"
                  : status === "Not Executed"
                  ? "default"
                  : "error"
              }
            >
              {status === "Completed"
                ? "Passed"
                : status === "In Progress"
                ? "In Progress"
                : status === "Not Executed"
                ? "Not Executed"
                : "Failed"}
            </Tag>
          </Col>
          {status === "Not Executed" ? undefined : (
            <Col
              style={{ cursor: "pointer" }}
              onClick={() => showPopUp(data["status_exe_id"], data["serial"])}
            >
              <Tooltip placement="left" title={data["validation_message"]}>
                <InfoCircleOutlined />
              </Tooltip>
            </Col>
          )}
        </Row>
      ),
    },
  ];

  function showPopUp(status_exe_id: string, serial:string) {
    modalPopUp(status_exe_id, serial);
    refreshSwitch();
    refreshIap();
  };

  const getPodIds = () => {
    API.get(StagingUrls.get_pod_ids)
      .then((res) => {
        if (res.status === 200) {
          let podIds: Array<any> = [];
          if (res.data?.pods) {
            res.data?.pods.forEach((pod: string) => {
              podIds.push(<Option value={pod}>{pod}</Option>);
            });
            setPodIds(podIds);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const swTableChangeHandler = ({ pagination, filters, sorter }: any) => {
    setSWPagination(pagination);
    setParams(tableFilterParser({ pagination, filters, sorter }));
  };

  const iapTableChangeHandler = ({ pagination, filters, sorter }: any) => {
    setIAPPagination(pagination);
    setParams(tableFilterParser({ pagination, filters, sorter }));
  };

  const refreshSwitch = () => {
    setSwitchTableLoading(true);
    API.get(
      StagingUrls.gxoExecutionStatus + `/?site=${selectedSite}&type=switch`
    )
      .then((res) => {
        if (res.status === 200) {
          setSwitchTableData(res?.data?.data?.switch);
          setSwitchOutputFile(res?.data?.sw_report_file);
        }
        setSwitchTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setSwitchTableLoading(false);
      });
  };

  const refreshIap = () => {
    setIapTableLoading(true);
    API.get(StagingUrls.gxoExecutionStatus + `/?site=${selectedSite}&type=iap`)
      .then((res) => {
        if (res.status === 200) {
          setIapTableData(res?.data?.data?.iap);
          setIapOutputFile(res?.data?.iap_report_file);
        }
        setIapTableLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIapTableLoading(false);
      });
  };

  const startExecution = () => {
    const formData = new FormData();
    formData.append("site_list", JSON.stringify([selectedSite]));
    formData.append("pod", JSON.stringify(selectedPodId));
    if (executeSwitch && executeIap) {
      formData.append(
        "task_types",
        JSON.stringify({
          switch: executeSwitch ? switchTasks.map((task) => task) : [],
          iap: [],
        })
      );
      gxoStagingStartExecution(formData);
      formData.delete("task_types");
      formData.append(
        "task_types",
        JSON.stringify({
          switch: [],
          iap: executeIap ? iapTasks.map((task) => task) : [],
        })
      );
      gxoStagingStartExecution(formData);
    } else {
      formData.append(
        "task_types",
        JSON.stringify({
          switch: executeSwitch ? switchTasks.map((task) => task) : [],
          iap: executeIap ? iapTasks.map((task) => task) : [],
        })
      );
      gxoStagingStartExecution(formData);
    }
  };

  const gxoStagingStartExecution = (formData: any) => {
    setStartExec(true);
    API.post(
      StagingUrls.baseStagingUrl + `?user=${user?.name}`,
      formData,
      true,
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .then((res) => {
        if (res?.data) {
          Modal.destroyAll();
          Modal.success({
            title: "Execution Started",
            content: res.data ? res.data.Message : "",
          });
          refreshSwitch();
          refreshIap();
        }
      })
      .catch(({ response }) => {
        Modal.destroyAll();
        response &&
          Modal.error({
            title: "Error while Execution",
            content: response ? response.data.Message : "Execution Timed Out",
          });
        setStartExec(false);
      });
  };

  const fetchSwitchTasks = () => {
    API.get(StagingUrls.baseStagingUrl + StagingUrls.switchTasksParam)
      .then(({ data }) => setSwitchTasks(data.task_list))
      .catch(({ response }) => {
        setSwitchTasks([]);
      });
  };

  const fetchIAPTasks = () => {
    API.get(StagingUrls.baseStagingUrl + StagingUrls.iapTasksParam)
      .then(({ data }) => setIAPTasks(data.task_list))
      .catch(({ response }) => {
        setIAPTasks([]);
      });
  };

  const selectExecution = (e: any) => {
    setStartExec(false);
    e.target.value === "switch"
      ? setExecuteSwitch(!executeSwitch)
      : setExecuteIAP(!executeIap);
  };

  const download = (url: string, fileName: string, type: string) => {
    DownloadFile(url, { fileName, phase: "Staging", type });
  };

  const changeSite = (value: any) => {
    setSelectedSite(value);
    API.get(StagingUrls.gxoExecutionStatus + `/?site=${value}`)
      .then((res) => {
        if (res.status === 200) {
          setSwitchTableData(res?.data?.data?.switch);
          setIapTableData(res?.data?.data?.iap);
          setSwitchOutputFile(res?.data?.sw_report_file);
          setIapOutputFile(res?.data?.iap_report_file);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  function podChanged(pod: string) {
    setSelectedPodId(pod);
  }

  const changeExecType = (e: any) => {
    setExecType(e.target.value);
    setSelectedPodId("");
    setExecuteSwitch(false);
    setExecuteIAP(false);
  };

  const handleSwitchSelection = (keys: Array<string>, records: Array<any>) => {
    console.log(records);
    setSelectedSwitchTasks(records);
  };

  const isDisabled = () => {
    if (site.length !== 0) {
      if (selectedSwitchTasks.length !== 0 || selectedIAPTasks.length !== 0)
        return false;
    }
    return true;
  };

  const handleIAPSelection = (keys: Array<string>, records: any) => {
    setSelectedIAPTasks(records);
  };

  const startRollback = () => {
    const formData = new FormData();
    formData.append(
      "task_types",
      JSON.stringify({
        switch: selectedSwitchTasks.map(({ task }) => task),
        iap: selectedIAPTasks.map(({ task }) => task),
      })
    );
    formData.append("site_list", JSON.stringify([selectedSite]));

    setRollbackLoading(true);
    API.post(StagingUrls.rollback + `?user=${user?.name}`, formData, true, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        Modal.success({
          title: "Rollback Started",
          content: res.data ? res.data.Message : "",
        });
        refreshSwitch();
        refreshIap();
      })
      .catch(({ response }) => {
        response &&
          Modal.error({
            title: "Error while Rollback",
            content: response ? response.data.Message : "Rollback Timed Out",
          });
      })
      .finally(() => {
        setRollbackLoading(false);
      });
  };

  return (
    <>
      <Row justify="space-between" style={{ marginBottom: "10px" }}>
        <Col>
          <Text strong>Site ID selected: </Text>
          <Select
            size="small"
            defaultValue={site[0]}
            onChange={changeSite}
            style={{ width: 100 }}
          >
            {siteOptions}
          </Select>
        </Col>
        <Col>
          <span className="flex flex--row">
            <Button onClick={prevStep} className="refresh-button gap--right">
              Go back
            </Button>
            <Button
              onClick={nextStep}
              className="next-store-btn"
              type="primary"
            >
              Validate Result
            </Button>
          </span>
        </Col>
      </Row>
      <Divider style={{ marginTop: "0" }} />
      <Row justify="space-between">
        <Col>
          {execType === "exec" ? (
            <>
              <Checkbox
                onChange={selectExecution}
                className="device-checkbox"
                value="switch"
              >
                <h4>Switch Tasks</h4>
              </Checkbox>
              <Checkbox onChange={selectExecution} value="iap">
                <h4>IAP Tasks</h4>
              </Checkbox>
            </>
          ) : undefined}
        </Col>
        <Col>
          <RadioGroup
            style={{ marginRight: "70px" }}
            onChange={changeExecType}
            optionType="button"
            size="small"
            value={execType}
            options={[
              {
                value: "exec",
                label: "Execution Tasks",
              },
              {
                value: "rollback",
                label: "Rollback Tasks",
              },
            ]}
          ></RadioGroup>
        </Col>
      </Row>
      {execType === "exec" ? (
        <>
          <Row style={{ marginTop: "15px" }}>
            <span style={{ color: "red" }}>* </span> Enter Pod ID:
            <Select
              size="small"
              onChange={podChanged}
              style={{
                width: "150px",
                paddingLeft: "10px",
                marginBottom: "10px",
              }}
            >
              {podIds}
            </Select>
          </Row>
          <Tooltip
            title={
              !((executeSwitch || executeIap) && selectedPodId)
                ? "Select device type & pod ID to start the execution"
                : undefined
            }
            placement="right"
          >
            <Button
              onClick={startExecution}
              className="gap--top execute-button"
              type="primary"
              disabled={!((executeSwitch || executeIap) && selectedPodId)}
              loading={startExec}
            >
              Execute
            </Button>
          </Tooltip>
        </>
      ) : (
        <>
          <ContentTemplate
            showButtons
            showSelectionTables
            showSwitchTasksSelection
            switchTableData={{
              dataSource: switchTasks
                .filter((val) =>
                  ["SW-SITE-DEVICE", "SW-UPLOAD-VARIABLE"].includes(val)
                )
                .map((value, i) => ({
                  key: i,
                  task: value,
                })),
              columns: switchColumns,
              onSelectionHandler: handleSwitchSelection,
              selectedRowKeys: selectedSwitchTasks.map((val) => val.key),
            }}
            showIAPTasksSelection
            iapTableData={{
              dataSource: iapTasks
                .filter((val) =>
                  ["IAP-SITE-DEVICE", "IAP-UPLOAD-VARIABLE"].includes(val)
                )
                .map((value, i) => ({
                  key: i,
                  task: value,
                })),
              columns: iapColumns,
              onSelectionHandler: handleIAPSelection,
              selectedRowKeys: selectedIAPTasks.map((val) => val.key),
            }}
            buttonsConfig={[
              {
                type: "primary",
                variant: "default",
                buttonText: "Rollback",
                disabled: isDisabled() || !hasAccess,
                className: "rollback-button",
                hasToolTipWhenDisabled: true,
                toolTipText: !hasAccess
                  ? "Your profile is not eligible to perform this action"
                  : "Please select at least 1 task to proceed with Rollback",
                loading: rollbackLoading,
                onClick: startRollback,
              },
            ]}
          />
        </>
      )}
      <Collapse className="gap--top" defaultActiveKey={["1", "2"]}>
        <Panel header="Device level status" key="1">
          <Row justify="space-around" gutter={16}>
            <Col span={12}>
              <ExpandableTable
                phase="Staging"
                className="execution-data-table gap--top"
                tableProps={{
                  dataSource: switchTableData,
                  columns: columns,
                  className: "selection-table-max-width-100",
                  pagination: SWPagination,
                  onChange: (pagination, filters, sorter) =>
                    swTableChangeHandler({ pagination, filters, sorter }),
                  title: showTitle
                    ? () => (
                        <div className="flex flex--row">
                          <Text className="flex--grow" title level={5}>
                            Switch Execution status
                          </Text>
                          {switchOutputFile ? (
                            <Tooltip
                              title="Download Switch execution report"
                              placement="left"
                            >
                              <Button
                                onClick={() =>
                                  download(
                                    DownloadUrls.downloadUrl,
                                    switchOutputFile,
                                    DOWNLOAD_TYPES.OUTPUT_FILE
                                  )
                                }
                                className="gap--right"
                                icon={<DownloadOutlined />}
                                shape="circle"
                                size="small"
                                loading={switchTableLoading}
                              ></Button>
                            </Tooltip>
                          ) : null}
                          <Tooltip title="Fetch live results" placement="left">
                            <Button
                              onClick={refreshSwitch}
                              className="refresh-button"
                              type="primary"
                              icon={<ReloadOutlined />}
                              shape="circle"
                              size="small"
                              loading={switchTableLoading}
                            ></Button>
                          </Tooltip>
                        </div>
                      )
                    : null,
                  loading: switchTableLoading,
                }}
                expandedSelectionColumnValues={TaskTypeDropDownColumns}
              />
            </Col>
            <Col span={12}>
              <ExpandableTable
                phase="Staging"
                className="execution-data-table gap--top"
                tableProps={{
                  dataSource: iapTableData,
                  columns: columns,
                  className: "selection-table-max-width-100",
                  pagination: IAPPagination,
                  onChange: (pagination, filters, sorter) =>
                    iapTableChangeHandler({ pagination, filters, sorter }),
                  title: showTitle
                    ? () => (
                        <div className="flex flex--row">
                          <Text className="flex--grow" title level={5}>
                            IAP Execution status
                          </Text>
                          {iapOutputFile ? (
                            <Tooltip
                              title="Download IAP execution report"
                              placement="left"
                            >
                              <Button
                                disabled={switchTableLoading}
                                onClick={() =>
                                  download(
                                    DownloadUrls.downloadUrl,
                                    iapOutputFile,
                                    DOWNLOAD_TYPES.OUTPUT_FILE
                                  )
                                }
                                className="gap--right"
                                icon={<DownloadOutlined />}
                                shape="circle"
                                size="small"
                                loading={switchTableLoading}
                              ></Button>
                            </Tooltip>
                          ) : null}
                          <Tooltip title="Fetch live results" placement="left">
                            <Button
                              disabled={iapTableLoading}
                              onClick={refreshIap}
                              className="refresh-button"
                              type="primary"
                              icon={<ReloadOutlined />}
                              size="small"
                              shape="circle"
                              loading={iapTableLoading}
                            ></Button>
                          </Tooltip>
                        </div>
                      )
                    : null,
                  loading: iapTableLoading,
                }}
                expandedSelectionColumnValues={TaskTypeDropDownColumns}
              />
            </Col>
          </Row>
        </Panel>
        <Panel header={"Execution details for " + selectedSite} key="2">
          <StagingTable
            reloadTable={reloadTable}
            setReloadTable={() => setReloadTable((prev) => !prev)}
            showTitle
            site={selectedSite}
            allStage={true}
          />
        </Panel>
      </Collapse>
    </>
  );
};

export default GxoExecution;
