import { Typography } from "antd";
import React from "react";

import "./styles.scss";

const { Text: AntText, Title } = Typography;

interface TextPropsType {
  title?: boolean;
  level?: 5 | 1 | 2 | 3 | 4;
  children?: React.ReactNode;
  type?: "secondary" | "success" | "warning" | "danger";
  disabled?: boolean;
  strong?: boolean;
  styles?: any;
  className?: string;
}

export const Text: React.FC<TextPropsType> = (props) => {
  const { title, level, children, type, disabled, strong, styles, className } =
    props;
  return title ? (
    <Title style={styles} className={"text " + className} level={level}>
      {children}
    </Title>
  ) : (
    <AntText
      style={styles}
      className={"text " + className}
      type={type}
      disabled={disabled}
      strong={strong}
    >
      {children}
    </AntText>
  );
};
