import React, { useEffect, useState } from "react";
import { Avatar, Layout, Tooltip, Typography, Alert } from "antd";
import { LogoutOutlined } from "@ant-design/icons";
import { Text } from "../../atoms";
import { useHistory } from "react-router-dom";
import "./styles.scss";
import { Colors } from "../../common/styles/_colors";
import { useAuth } from "../../context/Auth";
import API from "../../services/API";
import { ActiveSchedulingJobsUrls } from "../../services/apiUrls";

const { Header: AntHeader } = Layout;
const { Link } = Typography;

const Header = () => {
  const { user, signOut } = useAuth();
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string | null>("");
  const history = useHistory();

  useEffect(() => {
    const maintanence_alert = localStorage.getItem("maintanence_alert");
    setAlertMessage(localStorage.getItem("maintanence_message"));
    maintanence_alert === "true" ? setShowAlert(true) : setShowAlert(false);
    if (!(maintanence_alert || maintanence_alert === "true" || alertMessage)) {
      setTimeout(() => {
        API.get(ActiveSchedulingJobsUrls.bannerNotification)
          .then((response) => {
            if (response?.data) {
              if (response?.data?.status) {
                localStorage.setItem("maintanence_alert", "true");
                localStorage.setItem(
                  "maintanence_message",
                  response?.data?.Message
                );
                setAlertMessage(response?.data?.Message);
                setShowAlert(true);
              } else {
                setAlertMessage("");
                setShowAlert(false);
              }
            }
          })
          .catch(({ err }) => {
            console.log(err);
          });
      }, 500);
    }
  }, []);

  const onClose = () => {
    localStorage.setItem("maintanence_alert", "false");
    localStorage.removeItem("maintanence_message");
    setShowAlert(false);
  };

  return (
    <>
      <AntHeader className="header">
        <div onClick={() => history.push("/")} className="logo">
          <img src="TheHomeDepot.svg" alt="THD" />
          <Text title level={4}>
            The Home Depot
            {/* <span style={{ fontSize: "14px", color: "#f5821f" }}>
            | Aruba Migration Manager
          </span> */}
          </Text>
        </div>
        <div>
          <Tooltip title={user?.role?.toString()} trigger={["hover", "focus"]}>
            <span>
              <Avatar
                className="avatar"
                style={{
                  color: Colors.primaryOrange,
                  backgroundColor: Colors.liteOrange,
                }}
              >
                {user?.name[0].toUpperCase()}
              </Avatar>
              <Link href="#" className="user-name">
                {user?.name}
              </Link>
            </span>
          </Tooltip>

          <Tooltip title="logout" trigger={["hover", "focus"]}>
            <LogoutOutlined
              onClick={() => signOut()}
              className="logout-button"
            />
          </Tooltip>
        </div>
      </AntHeader>
      {showAlert && alertMessage ? (
        <Alert
          message={alertMessage ? alertMessage : ""}
          showIcon
          closable
          onClose={onClose}
        />
      ) : undefined}
    </>
  );
};

export default Header;
