import React from "react";
import { Layout } from "antd";
import { Menu } from "../../atoms";
import "./styles.scss";
import { HPEMenuItems, GXOMenuItems, AdminMenuItems } from "./config";
import { useAuth } from "../../context/Auth";

const { Sider } = Layout;

const SideNav: React.FC = () => {
  const { user } = useAuth();

  return user?.role[0].toLowerCase() === "gxo_staging_user" ? (
    <Sider theme="light" width={230} collapsible defaultCollapsed>
      <Menu menuItems={GXOMenuItems} />
    </Sider>
  ) : user?.role?.includes("Admin_USER") ? (
    <Sider theme="light" width={230} collapsible defaultCollapsed>
      <Menu menuItems={AdminMenuItems} />
    </Sider>
  ) : (
    <Sider theme="light" width={230} collapsible defaultCollapsed>
      <Menu menuItems={HPEMenuItems} />
    </Sider>
  );
};

export default SideNav;
