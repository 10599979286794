import React from "react";
import FeedbackForm from "../../organisms/FeedbackForm";
import ContentTemplate from "../../templates/ContentTemplate";

const Feedback: React.FC = () => {
  return (
    <ContentTemplate title="Feedback">
      <div
        style={{
          width: "40%",
          display: "block",
          marginLeft: "auto",
          marginRight: "auto",
        }}
        className="form-container"
      >
        <FeedbackForm />
      </div>
    </ContentTemplate>
  );
};

export default Feedback;
