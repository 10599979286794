import React from "react";
import { CheckboxOptionType, Radio } from "antd";
import { RadioGroupButtonStyle } from "antd/lib/radio";

interface RadioGroupPropsType {
  style?: any;
  defaultValue?: string;
  buttonStyle?: RadioGroupButtonStyle;
  disabled?: boolean;
  name?: string;
  size?: "large" | "middle" | "small";
  value?: string;
  options?: (string | CheckboxOptionType)[];
  optionType?: "default" | "button";
  onChange?: (e: any) => void;
}

export const RadioGroup: React.FC<RadioGroupPropsType> = (props) => {
  return (
    <Radio.Group
      defaultValue={props.defaultValue}
      buttonStyle={props.buttonStyle}
      disabled={props.disabled}
      optionType={props.optionType}
      value={props.value}
      options={props.options}
      name={props.name}
      size={props.size}
      style={props.style}
      onChange={props.onChange}
    >
    </Radio.Group>
  );
};
