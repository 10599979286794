import { Divider, Space, Typography } from "antd";
import { formatFileList } from ".";

export const renderFileList = (
  text: any,
  splitter: string,
  callback: (fileName: string) => void
) => {
  return (
    <Space
      className="gap-0-imp"
      direction="vertical"
      split={<Divider style={{ margin: 5 }} type="horizontal" />}
    >
      {typeof text == "string"
        ? formatFileList(text, splitter).map((fileName: string) => (
            <Typography.Link
              onClick={() => callback(fileName.trim())}
              style={{ wordWrap: "break-word", wordBreak: "break-word" }}
            >
              {fileName.trim()}
            </Typography.Link>
          ))
        : text.map((fileName: any) => (
            <Typography.Link
              onClick={() => callback(fileName)}
              style={{ wordWrap: "break-word", wordBreak: "break-word" }}
            >
              {fileName}
            </Typography.Link>
          ))}
    </Space>
  );
};
