export const gxoInitialStep = [
  {
    selector: ".upload-files",
    content: "Select the workbook file",
  },
  {
    selector: "",
    content:
      "Site name for the execution will be read from the file name selected",
  },
  {
    selector: "",
    content:
      "If you do not want to override the uploaded workbook file, you can cancel the override confirmation and continue with the old devices",
  },
  {
    selector: ".next-button",
    content: "Click the button to see the workbook validation results",
  },
];

export const gxoWorkbookStep = [
  {
    selector: ".site-confirmation",
    content: "Check correct site is selected for execution",
  },
  {
    selector: ".switch-count",
    content: "Check for the no. of devices uploaded in the workbook",
  },
  {
    selector: ".ant-table-tbody",
    content: "Workbook validation report will be published here",
  },
  {
    selector: ".goto-execute",
    content: "Move to execute step",
  },
];

export const gxoExecutionStep = [
  {
    selector: ".device-checkbox",
    content: "Choose the device type for the execution",
  },
  {
    selector: ".execute-button",
    content: "Click to start the execution",
  },
  {
    selector: ".ant-table-tbody",
    content: "Previous and current execution results can be tracked here",
  },
  {
    selector: "next-store-btn",
    content: "Stage next store once all the devices are staged"
  },
];