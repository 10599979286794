import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { DateFormats } from "../../constants/dateFormats";
import { Status, statusFilter } from "../../constants/status";
import { getColumnSearchProps } from "../../utils";
import { PhaseType, StatusType } from "../../types";
import { DOWNLOAD_TYPES } from "../../constants/downloadConstants";
import { renderFileList } from "../../utils/renderFileList";
import TerminateTask from "../../organisms/TerminateTask";
dayjs.extend(utc);

export const columns = (
  download: (fileName: string, phase: PhaseType, type: string) => void,
  allStage?: boolean
) => [
  {
    title: "Execution ID",
    dataIndex: "exe_id",
    key: "exe_id",
    width: 120,
    fixed: "left",
    ...getColumnSearchProps("exe_id"),
  },
  {
    title: "Site Name",
    dataIndex: "site",
    key: "site__site_name",
    ...(allStage ? {} : getColumnSearchProps("site__site_name")),
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: StatusType) => Status(text),
    ...statusFilter,
  },
  {
    title: "Execution Start time",
    dataIndex: "starttime",
    key: "starttime",
    sorter: true,
    render: (text: string) => (
      <>{text ? dayjs(text).format(DateFormats.inTableData) : ""}</>
    ),
    ...getColumnSearchProps("starttime", "date"),
  },
  {
    title: "Execution End time",
    dataIndex: "endtime",
    key: "endtime",
    sorter: true,
    render: (text: string) => (
      <>{text ? dayjs(text).format(DateFormats.inTableData) : ""}</>
    ),
    ...getColumnSearchProps("endtime", "date"),
  },
  {
    title: "Input File",
    dataIndex: "input_file_list",
    key: "input_file_list",
    render: (text: any) =>
      renderFileList(text, " ", (fileName: string) =>
        download(fileName, "PreStaging", DOWNLOAD_TYPES.INPUT_FILE)
      ),
  },
  {
    title: "Output File",
    dataIndex: "report_file_list",
    key: "report_file_list",
    width: 150,
    render: (text: any) =>
      renderFileList(text, " ", (fileName: string) =>
        download(fileName, "PreStaging", DOWNLOAD_TYPES.OUTPUT_FILE)
      ),
  },
  {
    title: "Executed By User",
    dataIndex: "username",
    key: "user__username",
    ...getColumnSearchProps("user__username"),
  },
  {
    title: "Task Action",
    dataIndex: "celery_task_id",
    key: "celery_task_id",
    width: 120,
    fixed: "right",
    render: (text: any, exe_id: any) => (
      <TerminateTask task_id={text} phase="PreStaging"></TerminateTask>
    ),
  },
];
