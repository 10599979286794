import {
  Row,
  Col,
  Table,
  Modal,
  Divider,
  Button,
  Select,
  TablePaginationConfig,
  Tooltip,
  Checkbox,
  Tag,
  Popconfirm,
} from "antd";
import API from "../../../services/API";
import { useAuth } from "../../../context/Auth";
import React, { useEffect, useState } from "react";
import { StagingUrls } from "../../../services/apiUrls";
import {
  columns,
  statusColumns,
} from "../StagingTable/config/validationTableConfig";
import { tableFilterParser } from "../../../utils";
import { StagingValidationData } from "../../../types";
import { Text } from "../../../atoms";
import { useSpinner } from "../../../context/Spinner.context";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import "../styles.css";

interface StagingTableType {
  showTitle?: boolean;
  prevStep?: () => void;
  nextStep?: () => void;
  site: string[];
}

const PAGE_SIZE = 10;

const ValidationResult: React.FC<StagingTableType> = ({
  showTitle,
  prevStep,
  nextStep,
  site,
}) => {
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [executeSwitch, setExecuteSwitch] = useState<boolean>(false);
  const [executeIap, setExecuteIAP] = useState<boolean>(false);
  const [tableData, setTableData] = useState<StagingValidationData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [params, setParams] = useState("");
  const [overallStatus, setOverallStatus] = useState("Passed");
  const [totalSwitchCount, setTotalSwitchCount] = useState<number>(0);
  const [totalIapCount, setTotalIapCount] = useState<number>(0);
  const [failedSwitchCount, setFailedSwitchCount] = useState<number>(0);
  const [failedIapCount, setFailedIapCount] = useState<number>(0);
  const [pagination, setPagination] = useState<TablePaginationConfig>({
    current: 1,
    pageSize: PAGE_SIZE,
    total: undefined,
    showSizeChanger: true,
  });
  let siteOptions: any[] = [];
  const { user } = useAuth();
  const { Option } = Select;
  const { setSpinner } = useSpinner();
  site.forEach((siteNumber: string) => {
    siteOptions.push(<Option value={siteNumber}>{siteNumber}</Option>);
  });

  useEffect(() => {
    setSelectedSite(site[0]);
  }, []);

  const changeSite = (value: any) => {
    setSelectedSite(value);
    setTableData([]);
    getOverallStatus([]);
  };

  const validateStaging = () => {
    if (executeSwitch && executeIap) {
      const formData = new FormData();
      formData.append("site", selectedSite);
      formData.append("type", "both");
      validateExecution(formData);
    } 
    else if (executeIap) {
      const formData = new FormData();
      formData.append("site", selectedSite);
      formData.append("type", "iap");
      validateExecution(formData);
    } else if (executeSwitch) {
      const formData = new FormData();
      formData.append("site", selectedSite);
      formData.append("type", "switch");
      validateExecution(formData);
    } else {
      const formData = new FormData();
      formData.append("site", selectedSite);
      formData.append("type", "both");
      validateExecution(formData);
    }
  };

  const setStoreComplete = () => {
    setSpinner(true);
    const formData = new FormData();
    formData.append("site", selectedSite);
    API.post(
      StagingUrls.setStoreComplete + `?user=${user?.name}`,
      formData,
      true,
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .then((res) => {
        if (res?.data) {
          Modal.success({
            title: "Set Store status",
            content: res.data
              ? res.data.Message
              : "Store status is set to complete",
          });
          validateStaging();
        }
      })
      .catch(({ response }) => {
        response &&
          Modal.error({
            title: "Set Store status",
            content: response
              ? response.data?.Message
              : "Error occured while setting the store status as complete",
          });
      })
      .finally(() => {
        setSpinner(false);
      });
  }

  const validateExecution = (formData: any) => {
    tableData.length ? setTableLoading(true) : setSpinner(true);
    API.post(
      StagingUrls.execute_staging_validation + `?user=${user?.name}`,
      formData,
      true,
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .then((res) => {
        if (res?.data) {
          setTableData(res.data?.data);
          getOverallStatus(res.data?.data);
        }
      })
      .catch(({ response }) => {
        Modal.destroyAll();
        response &&
          Modal.error({
            title: "Error",
            content: response ? response.data.Message : "Execution Timed Out",
          });
      })
      .finally(() => {
        setSpinner(false);
        setTableLoading(false);
      });
  };

  const tableChangeHandler = ({ pagination, filters, sorter }: any) => {
    setPagination({
      current: 1,
      pageSize: PAGE_SIZE,
      total: undefined,
      showSizeChanger: true,
    });
    setPagination(pagination);
    setParams(tableFilterParser({ pagination, filters, sorter }));
  };

  function dataFormatter(dict: any): any {
    let tableData = Object.entries(dict).map(([key, value], i) => {
      return {
        key: i,
        task: key,
        message: value,
      };
    });
    return tableData;
  }

  const selectExecution = (e: any) => {
    e.target.value === "switch"
      ? setExecuteSwitch(!executeSwitch)
      : setExecuteIAP(!executeIap);
  };

  const getOverallStatus = (tableData: StagingValidationData[]) => {
    let iapTotalCount = 0;
    let swTotalCount = 0;
    let iapFailCount = 0;
    let swFailCount = 0;
    tableData.map((val: StagingValidationData): void => {
      if (val.device_type === "IAP") {
        iapTotalCount = iapTotalCount + 1;
        if (val.status === "Failed") {
          iapFailCount = iapFailCount + 1;
        }
      } else {
        swTotalCount = swTotalCount +1;
        if (val.status === "Failed") {
          swFailCount = swFailCount + 1;
        }
      }
    });
    swFailCount + iapFailCount > 0 ? setOverallStatus("Error") : setOverallStatus("Passed")
    setFailedSwitchCount(swFailCount);
    setFailedIapCount(iapFailCount);
    setTotalSwitchCount(swTotalCount);
    setTotalIapCount(iapTotalCount);
  };

  return (
    <div className="without-animation">
      <Row justify="space-between" style={{ marginBottom: "10px" }}>
        <Col>
          <Text strong>Site ID selected: </Text>
          <Select
            size="small"
            defaultValue={site[0]}
            onChange={changeSite}
            style={{ width: 100 }}
          >
            {siteOptions}
          </Select>
        </Col>
        <Col>
          <span className="flex flex--row">
            <Button onClick={prevStep} className="gap--right">
              Go Back
            </Button>
            <Popconfirm
              title="Set 'is_staging' variable value to 'N'?"
              onConfirm={setStoreComplete}
              onCancel={() => {}}
              okText="Yes"
              cancelText="No"
            >
              <Tooltip
                title="sets is_Staging variable to N for switches and APs"
                placement="bottom"
              >
                <Button className="gap--right">
                  Mark {selectedSite} as complete
                </Button>
              </Tooltip>
            </Popconfirm>
            <Button
              onClick={nextStep}
              className="next-store-btn"
              type="primary"
            >
              Stage Next Store
            </Button>
          </span>
        </Col>
      </Row>
      <Divider style={{ marginTop: "0" }} />
      <Row className="gap--top">
        <Checkbox
          onChange={selectExecution}
          className="device-checkbox"
          value="switch"
        >
          <h4>Validate Switch</h4>
        </Checkbox>
        <Checkbox onChange={selectExecution} value="iap">
          <h4>Validate IAP</h4>
        </Checkbox>
      </Row>
      <Button
        onClick={validateStaging}
        className="gap--top execute-button"
        type="primary"
        disabled={!(executeSwitch || executeIap)}
        loading={tableLoading}
      >
        Validate
      </Button>
      {tableData.length ? (
        <Table
          className="gap--top"
          columns={columns}
          onChange={(pagination, filters, sorter) =>
            tableChangeHandler({ pagination, filters, sorter })
          }
          pagination={pagination}
          rowKey="serial"
          loading={tableLoading}
          title={() => (
            <div className="flex flex--row">
              <Text className="flex--grow" title level={5}>
                Central validation Results{" "}
                <Tooltip
                  title={
                    "Switch: " +
                    failedSwitchCount +
                    "/" +
                    totalSwitchCount +
                    " Error | IAP: " +
                    failedIapCount +
                    "/" +
                    totalIapCount +
                    " Error"
                  }
                  placement="top"
                >
                  {overallStatus === "Passed" ? (
                    <Tag
                      icon={<CheckCircleOutlined />}
                      color="success"
                      style={{ cursor: "pointer" }}
                    >
                      Passed
                    </Tag>
                  ) : (
                    <Tag
                      icon={<CloseCircleOutlined />}
                      color="error"
                      style={{ cursor: "pointer" }}
                    >
                      Error
                    </Tag>
                  )}
                </Tooltip>
              </Text>
            </div>
          )}
          expandable={{
            expandedRowRender: (record: any) => (
              <Table
                columns={statusColumns}
                pagination={false}
                dataSource={dataFormatter(record?.details)}
                size="small"
              />
            ),
          }}
          dataSource={tableData}
        />
      ) : undefined}
    </div>
  );
};

export default ValidationResult;
