import React, { useEffect, useState } from "react";
import ContentTemplate from "../../templates/ContentTemplate";
import StagingTable from "../StagingPage/StagingTable";

const StagingExecutionData: React.FC = () => {
  const [reloadTable, setReloadTable] = useState(false);

  return (
    <ContentTemplate
      title="Staging Execution Data"
      showMainTable
      mainTable={
        <StagingTable
          reloadTable={reloadTable}
          setReloadTable={() => setReloadTable((prev) => !prev)}
          showTitle
        />
      }
    />
  );
};

export default StagingExecutionData;
