import React, { createContext, useContext, useEffect, useState } from "react";
import API from "../services/API";
import { AuthUrls } from "../services/apiUrls";
import { useHistory } from "react-router-dom";

interface IUser {
  name: string;
  role: string;
}

interface IAuthContextType {
  user: IUser | null;
  signOut: () => void;
  signIn: (user: IUser, accessToken: string) => void;
}

const Auth = createContext<IAuthContextType>({} as IAuthContextType);

export const AuthContext: React.FC = (props) => {
  const [user, setUser] = useState<IUser | null>(null);
  const history = useHistory();

  useEffect(() => {
    const user = localStorage.getItem("user");
    if (user) {
      setUser(JSON.parse(user));
      API.post(AuthUrls.verify, {})
      .then((data: any) => {
        if (data?.data?.groups?.length === 0) {
          signOut();
          history.replace("/forbidden", {
            from: "/login",
          });
        }
      }).catch((err) => {
        if (err.response && err.response.status === 401) signOut();
      });
    } else {
      localStorage.clear();
      setUser(null);
    }
  }, []);

  const signOut = () => {
    API.post(AuthUrls.logout, {}).then((res) => console.log(res));
    localStorage.clear();
    setUser(null);
  };

  const signIn = (user: IUser, accessToken: string) => {
    setUser(user);
    localStorage.setItem("token", accessToken);
    localStorage.setItem("user", JSON.stringify(user));
  };

  const initialAuthObj = {
    user: user,
    signOut,
    signIn,
  };

  return <Auth.Provider value={initialAuthObj}>{props.children}</Auth.Provider>;
};

export const useAuth = (): IAuthContextType => useContext(Auth);
