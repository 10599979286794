import { TablePaginationConfig } from "antd";
import { FilterValue } from "antd/lib/table/interface";

export interface ISortType {
  column?: Object | undefined;
  columnKey: string;
  order: string;
}

export const tableFilterParser = ({
  pagination,
  filters,
  sorter,
}: {
  pagination: TablePaginationConfig;
  filters: Record<string, FilterValue | null> | undefined;
  sorter: ISortType | undefined;
}): string => {
  const sort = sorter?.column
    ? `${sorter?.order === "ascend" ? "" : "-"}${sorter?.columnKey}`
    : null;
  const page = pagination.current;

  let appliedFilters = `&page=${page}&page_size=${pagination.pageSize}`;
  if (sort) appliedFilters = appliedFilters + `&ordering=${sort}`;
  Object.entries(filters!).forEach((val) => {
    const [key, value] = val;
    if (value) {
      (value as Array<string>).forEach((search) => {
        appliedFilters = appliedFilters + `&${key}=${search}`;
      });
    }
  });

  return appliedFilters;
};

export const uriParser = (parseObj: Object) => {
  let parsedString = "";
  Object.entries(parseObj).forEach((val) => {
    const [key, value] = val;
    parsedString = parsedString + `&${key}=${value}`;
  });
  return parsedString;
};
