export const taskDataSource = [
  {
    key: "001",
    task: "Fetch APs for site",
  },
  {
    key: "003",
    task: "Fetch Switch for site",
  },
  {
    key: "004",
    task: "Fetch Sites",
  },
];

export const switchColumns = [
  {
    title: "Switch Tasks",
    dataIndex: "task",
    key: "task",
    ellipsis: true,
  },
];

export const srcTaskColumns = [
  {
    title: "Source Tasks",
    dataIndex: "task",
    key: "task",
    ellipsis: true,
  },
];

export const destTaskColumns = [
  {
    title: "Destination Tasks",
    dataIndex: "task",
    key: "task",
    ellipsis: true,
  },
];

export const centralTaskColumns = [
  {
    title: "Central Tasks",
    dataIndex: "task",
    key: "task",
    ellipsis: true,
  },
];

export const iapColumns = [
  {
    title: "IAP Tasks",
    dataIndex: "task",
    key: "task",
    ellipsis: true,
  },
];
