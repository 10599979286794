import { Form, Input, Button, Space, notification } from "antd";
import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Text } from "../../atoms";
import { useAuth } from "../../context/Auth";
import { useSpinner } from "../../context/Spinner.context";
import API from "../../services/API";
import { AuthUrls } from "../../services/apiUrls";

const ResetPasswordPage: React.FC = () => {
  const location = useLocation();
  const history = useHistory();
  const { signOut } = useAuth();
  const { setSpinner } = useSpinner();
  const [form] = Form.useForm();

  let unblock = history.block(); // block the user from navigating
  // effect to redirect if the user directly opens this page
  useEffect(() => {
    if (location.state) {
      const { from } = location.state as any;
      if (from !== "login") {
        unblock();
        history.replace("/");
      }
    } else {
      unblock();
      history.replace("/");
    }
  }, []);

  const handleSubmit = (values: any) => {
    const { username, access } = location.state as any;
    const { password, new_password, confirm_new_password } = values;
    setSpinner(true);
    API.patch(
      AuthUrls.resetPassword,
      {
        username: username,
        old_password: password,
        new_password,
        confirm_new_password,
      },
      true,
      {
        Authorization: `Bearer ${access}`,
      }
    )
      .then((res) => {
        unblock();
        notification.success({
          message: "Password Changed Successfully!",
          description: "Please login again to continue",
          duration: 4,
        });
        signOut();
        history.replace("/login");
      })
      .catch(({ response }) => {
        form.setFields([
          {
            name: "new_password",
            errors: [
              response
                ? response.data.Message
                : "An error occurred while resetting your password",
            ],
          },
        ]);
      })
      .finally(() => setSpinner(false));
  };

  return (
    <section className="login-background">
      <section className={"login-container"}>
        <div className="title">
          <img src="TheHomeDepot.svg" alt="THD" />
          <Text title level={3}>
            The Home Depot
          </Text>
        </div>

        <Space direction="vertical">
          <Text title level={4}>
            Reset Your Password
          </Text>
          <Form
            layout="vertical"
            form={form}
            name="reset_password_form"
            className="reset-password-form"
            initialValues={{}}
            onFinish={handleSubmit}
          >
            <Form.Item
              label="Current Password"
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please enter your current Password",
                },
              ]}
            >
              <Input.Password
                type="password"
                placeholder="Enter Current Password"
              />
            </Form.Item>
            <Form.Item
              label="New Password"
              name="new_password"
              rules={[
                { required: true, message: "Please enter a new Password" },
                {
                  validator: (_, value) =>
                    (value as string).length >= 6
                      ? Promise.resolve()
                      : Promise.reject(
                          new Error(
                            "Password should contain at least 6 characters"
                          )
                        ),
                },
              ]}
            >
              <Input.Password
                type="password"
                placeholder="Enter New Password"
              />
            </Form.Item>
            <Form.Item
              label="Confirm New Password"
              name="confirm_new_password"
              rules={[
                { required: true, message: "Please confirm your Password" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("new_password") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The two passwords that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password type="password" placeholder="Confirm Password" />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Reset Password
              </Button>
            </Form.Item>
          </Form>
        </Space>
      </section>
    </section>
  );
};

export default ResetPasswordPage;
