import dayjs from "dayjs";
import { Text } from "../atoms";
import { DateFormats, DateDiffInSecs } from "./dateFormats";
import { Status } from "./status";

export const AllStageExpandedDropDownColumns = [
  {
    title: "All Stages",
    dataIndex: "title",
    key: "title",
  },
];

// TODO: get this from API
// this is for AllStage Screen
export const AllStageExpandedDropDownOptions = [
  {
    key: 1,
    title: "Planning",
  },
  {
    key: 2,
    title: "Pre-Staging",
  },
  {
    key: 3,
    title: "Staging",
  },
  {
    key: 4,
    title: "Pre-Check",
  },
  {
    key: 5,
    title: "Post-Check",
  },
];

export const DetailStatusColumns = [
  {
    title: "Task Name",
    dataIndex: "task_name",
    key: "task_name",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: any) => Status(text),
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
  }
];

// For the other screens
// columns
export const StagingStatusColumns = [
  {
    title: "Task Name",
    dataIndex: "task_name",
    key: "task_name",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (text: any) => Status(text),
  },
  {
    title: "Message",
    dataIndex: "details",
    key: "details",
  },
  {
    title: "Task Start time",
    dataIndex: "stime",
    key: "stime",
    render: (text: string) => (
      <>{text ? dayjs(text).format(DateFormats.inTableData) : ""}</>
    ),
  },
  {
    title: "Task End time",
    dataIndex: "etime",
    key: "etime",
    render: (text: string) => (
      <>{text ? dayjs(text).format(DateFormats.inTableData) : ""}</>
    ),
  },
  {
    title: "Time Taken",
    dataIndex: "ttime",
    key: "ttime",
    render: (text: string, record: any) => (
      <>
        {record.etime ? DateDiffInSecs(
          Date.parse(record.stime),
          Date.parse(record.etime)
        ) : undefined}
      </>
    ),
  },
];

export const PlanningExeTableStatusColumns = [
  {
    title: "Site Name",
    dataIndex: "site",
    key: "site_name",
  },
  {
    title: "Fetch Switch",
    dataIndex: "fetch_switch",
    key: "fetch_switch",
    render: (text: any) => Status(text),
  },
  {
    title: "Fetch AP",
    dataIndex: "fetch_ap",
    key: "fetch_ap",
    render: (text: any) => Status(text),
  },
  {
    title: "AP Message",
    dataIndex: "ap_details",
    key: "ap_details",
    render: (text: any) => Status(text.message),
  },
  {
    title: "Switch Message",
    dataIndex: "switch_details",
    key: "switch_details",
    render: (text: any) => Status(text.message),
  },
];

// DropDownOptions
export const TaskTypeDropDownColumns = [
  {
    title: "Task Type",
    dataIndex: "title",
    key: "title",
    render: (text: string) => {
      return <Text styles={{ fontWeight: "500" }}>{text}</Text>;
    },
  },
];

export const TaskTypeDropDownOptions = [
  {
    key: 1,
    title: "Switch Tasks",
  },
  {
    key: 2,
    title: "IAP Tasks",
  },
];

export const PlanningDropDownOptions = [
  {
    key: 1,
    title: "Task Statuses",
  },
];
