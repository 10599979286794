import React, { useEffect, useState } from "react";
import {
  centralTaskColumns,
  iapColumns,
  switchColumns,
} from "../../constants/mockData";
import ContentTemplate from "../../templates/ContentTemplate";
import PreStagingTable from "./PreStagingTable";
import { DownloadUrls, PreStagingUrls } from "../../services/apiUrls";
import { menu } from "../../constants/downloadMenu";
import API from "../../services/API";
import { Alert, Modal } from "antd";
import { UploadFile } from "antd/lib/upload/interface";
import { useAuth } from "../../context/Auth";
import { DownloadFile } from "../../utils/fileDownload";
import { useSpinner } from "../../context/Spinner.context";
import { DOWNLOAD_TYPES } from "../../constants/downloadConstants";
import ExecutionSteps from "../../organisms/ExecutionSteps";
import SelectionTable from "../../molecules/SelectionTable";
import { showConfirm, showErrorModel } from "../../utils";
import Tour from "reactour";
import { initialStep, siteStep, centralStep, csrStep, executionStep, missingVariablesStep } from "./tourConfig";
import { checkRole } from "../../services/authorization";

const PreStaging: React.FC = () => {
  const [selectedSwitchTasks, setSelectedSwitchTasks] = useState<Array<any>>(
    []
  );
  const [selectedIAPTasks, setSelectedIAPTasks] = useState<Array<any>>([]);
  const [selectedCentralTasks, setSelectedCentralTasks] = useState<Array<any>>(
    []
  );
  const [switchTasks, setSwitchTasks] = useState<Array<string>>([]);
  const [iapTasks, setIAPTasks] = useState<Array<string>>([]);
  const [centralTasks, setCentralTasks] = useState<Array<string>>([]);
  // const [switchTasksLoading, setSwitchTasksLoading] = useState<boolean>(false);
  // const [iapTasksLoading, setIAPTasksLoading] = useState<boolean>(false);
  const [masterSheetFile, setMasterSheetFile] = useState<UploadFile[] | null>(
    null
  );
  const [executionLoading, setExecutionLoading] = useState(false);
  const [siteId, setSiteId] = useState<Array<string>>([]);
  const [bulkSiteFile, setBulkSiteFile] = useState<UploadFile | null>(null);
  const [csrFileList, setCsrFileList] = useState<UploadFile[] | null>(null);
  const [missingVarFile, setMissingVarFile] = useState<UploadFile | null>(null);
  const [reloadTable, setReloadTable] = useState(false);
  const [masterSheetTruncate, setMasterSheetTruncate] = useState(false);
  const [isTourOpen, setIsTourOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);
  const [steps, setSteps] = useState(initialStep);
  const [tourIndex, setTourIndex] = useState(0);

  const { user } = useAuth();
  const hasAccess = checkRole(
    user?.role?.toString().toUpperCase(),
    "prestaging"
  );

  const { setSpinner } = useSpinner();
  useEffect(() => {
    // Effect to Fetch Switch Tasks and IAP tasks
    // clear all values and errors
    setSwitchTasks([]);
    setIAPTasks([]);
    setCentralTasks([]);
    // fetch
    fetchCentralTasks();
    fetchSwitchTasks();
    fetchIAPTasks();
  }, []);

  useEffect(() => {
    if (stepIndex === 0) {
      setSteps(initialStep);
    } else if (stepIndex === 1) {
      setSteps(siteStep);
    } else if (stepIndex === 2) {
      setSteps(centralStep);
    } else if (stepIndex === 3) {
      setSteps(csrStep);
    } else if (stepIndex === 4) {
      setSteps(executionStep);
    } else if (stepIndex === 5) {
      setSteps(missingVariablesStep);
    }
    setTourIndex(0);
  }, [stepIndex]);

  const cleanupAfterStep = (idx: number) => {
    setStepIndex(idx);
    setSelectedSwitchTasks([]);
    setSelectedIAPTasks([]);
    setSelectedCentralTasks([]);
  };

  const startExecution = (data?: any) => {
    // creating formData to send the file (custom upload)
    const formData = new FormData();

    bulkSiteFile && formData.append("site_list_file", bulkSiteFile as any);
    selectedSwitchTasks.forEach((task) => {
      if (task.task === "CHANGE-VARIABLES") {
        missingVarFile &&
          formData.append("missing_variables_files", missingVarFile as any);
      }
    });
    selectedIAPTasks.forEach((task) => {
      if (task.task === "CHANGE-VARIABLES") {
        missingVarFile &&
          formData.append("missing_variables_files", missingVarFile as any);
      }
    });
    
    console.log(selectedSwitchTasks, selectedIAPTasks, selectedCentralTasks);

    if (data?.csr_update_flag) {
      formData.append("csr_update_flag", data?.csr_update_flag);
    }

    if (data?.csr_update_flag) {
      formData.append("csr_update_flag", data?.csr_update_flag);
    }

    formData.append(
      "task_types",
      JSON.stringify({
        switch: selectedSwitchTasks.map(({ task }) => task),
        iap: selectedIAPTasks.map(({ task }) => task),
        central: selectedCentralTasks.map(({ task }) => task),
      })
    );
    formData.append("site_list", JSON.stringify(siteId));

    setExecutionLoading(true);
    API.post(
      PreStagingUrls.startExecution + `?user=${user?.name}`,
      formData,
      true,
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .then((res) => {
        console.log(res);
        Modal.success({
          title: "Execution Started",
          content: res.data ? res.data.Message : "",
        });
      })
      .catch(({ response }) => {
        if (response) {
          if (response.status === 406) {
            showConfirm(
              "Do you want to Override the data?",
              response.data.Message,
              () => startExecution({ csr_update_flag: "True" })
            );
          } else {
            showErrorModel(
              "Error while Execution",
              response.data ? response.data.Message : "Execution Timed Out"
            );
          }
        }
      })
      .finally(() => {
        setExecutionLoading(false);
        setReloadTable((prev) => !prev);
      });
  };

  const download = async (url: string, fileName: string, type: string) => {
    setSpinner(true);
    await DownloadFile(url, { fileName, phase: "PreStaging", type });
    setSpinner(false);
  };

  const isDisabled = () => {
    if (siteId.length !== 0 || bulkSiteFile !== null) {
      if (
        selectedSwitchTasks.length !== 0 ||
        selectedIAPTasks.length !== 0 ||
        selectedCentralTasks.length !== 0
      )
        return false;
    }
    return true;
  };

  const fetchSwitchTasks = () => {
    // setSwitchTasksLoading(true);
    API.get(PreStagingUrls.switchTasksUrl)
      .then(({ data }) => setSwitchTasks(data.task_list))
      .catch(({ response }) => {
        setSwitchTasks([]);
      });
    // .finally(() => setSwitchTasksLoading(false));
  };

  const fetchCentralTasks = () => {
    API.get(PreStagingUrls.centralTasksUrl)
      .then(({ data }) => setCentralTasks(data.task_list))
      .catch(({ response }) => {
        setCentralTasks([]);
      });
  };

  const fetchIAPTasks = () => {
    // setIAPTasksLoading(true);
    API.get(PreStagingUrls.iapTasksUrl)
      .then(({ data }) => setIAPTasks(data.task_list))
      .catch(({ response }) => {
        setIAPTasks([]);
      });
    // .finally(() => setIAPTasksLoading(false));
  };

  const handleCentralSelection = (keys: Array<string>, records: Array<any>) => {
    setSelectedCentralTasks(records);
  };

  const handleSwitchSelection = (keys: Array<string>, records: Array<any>) => {
    setSelectedSwitchTasks(records);
  };

  const handleIAPSelection = (keys: Array<string>, records: any) => {
    setSelectedIAPTasks(records);
  };

  const selectedFiles = (files: any) => {
    let fileSizeValidation = true;
    (files.fileList as []).forEach((file: any) => {
      console.log(files.fileList);
      const isLt6M = file.size / 1024 / 1024 < 6;
      if (!isLt6M) {
        fileSizeValidation = false;
        showErrorModel(
          "Error while Upload",
          "File size must be smaller than 6MB!"
        );
      }
    });
    if (fileSizeValidation) {
      setMasterSheetFile(files.fileList);
    }
  };

  const executionSteps = [
    {
      title: "Master Sheet",
      content: (
        <>
          <Alert
            banner
            message="Please Upload the Master Sheet before you move to the next steps to avoid Errors"
            type="info"
            showIcon
          />
          <ContentTemplate
            showButtons
            buttonsConfig={[
              {
                type: "default",
                variant: "upload-multiple",
                disabled: !hasAccess,
                hasToolTipWhenDisabled: true,
                toolTipText:
                  "Your profile is not eligible to perform this action",
                buttonText: "Select MasterSheet files",
                className: "upload-master-sheet",
                onChange: selectedFiles,
                maxCount: 1,
                uploadConfig: {
                  showFileList: true,
                  customUpload: true,
                  uploadUrl: PreStagingUrls.masterSheet,
                  multiple: true,
                  data: {
                    truncate_table_flag: masterSheetTruncate ? "True" : "False",
                  },
                  defaultFileList: masterSheetFile ? masterSheetFile : [],
                },
                showCheckBox: true,
                checkBoxText: "Overwrite MasterSheet if data already exists?",
                handleCheckBox: setMasterSheetTruncate,
              },
            ]}
          />
        </>
      ),
    },
    {
      title: "Site Collection",
      content: (
        <>
          <Alert
            banner
            message="Please Select a Site or Select a Bulk Site File for this Execution"
            type="info"
            showIcon
          />
          <ContentTemplate
            showSearchBar
            searchBarOptions={{
              onChangeHandler: (sel) => setSiteId(sel.sites),
              showInfoIcon: true,
              infoText:
                "Enter up-to 5 Sites, Please upload a file for bulk input.",
              error: false,
              errorText: "",
              inputType: "multiple",
              selectedId: siteId,
              disabled: !hasAccess,
              hasToolTipWhenDisabled: true,
              toolTipText:
                "Your profile is not eligible to perform this action",
            }}
            showButtons
            buttonsConfig={[
              {
                type: "default",
                disabled: !hasAccess,
                variant: "upload-dropdown",
                buttonText: "Upload Site Bulk File",
                accept: ".xlsx",
                uploadConfig: {
                  showFileList: true,
                  accept: ".xlsx",
                  customUpload: true,
                  beforeUploadCallback: setBulkSiteFile,
                  menu: menu([
                    {
                      menuText: "Download Sample file",
                      cb: () =>
                        download(
                          DownloadUrls.downloadUrl,
                          "Bulk_Site_template.xlsx",
                          DOWNLOAD_TYPES.SITE_TEMPLATE
                        ),
                    },
                  ]),
                  defaultFileList: bulkSiteFile ? [bulkSiteFile] : [],
                },
              },
            ]}
          />
        </>
      ),
    },
    {
      title: "Central Tasks",
      content: (
        <>
          <Alert
            banner
            message="Please Execute all the Central Tasks to avoid errors in the upcoming Steps"
            type="info"
            showIcon
          />
          <Alert
            banner
            message={
              <span>
                If the <b>Start Execution</b> button is not active then please
                make sure you have selected the <b>Site</b> in the Site
                Collection Step
              </span>
            }
            type="info"
            showIcon
          />
          <ContentTemplate
            showButtons
            showSelectionTables
            showCustomSelectionTable
            CustomSelectionTable={
              <SelectionTable
                onSelectionHandler={handleCentralSelection}
                className="selection-table-max-width-50" // custom class to set max width
                tableProps={{
                  dataSource: centralTasks.map((value, i) => ({
                    key: i,
                    task: value,
                  })),
                  columns: centralTaskColumns,
                }}
              />
            }
            buttonsConfig={[
              {
                type: "primary",
                variant: "default",
                buttonText: "Start Execution",
                className: "start-execution",
                disabled: isDisabled() || !hasAccess,
                hasToolTipWhenDisabled: true,
                toolTipText: !hasAccess
                  ? "Your profile is not eligible to perform this action"
                  : siteId.length === 0
                  ? "Please select a site before Start Execution"
                  : "Please select at least 1 task to proceed with Execution",
                loading: executionLoading,
                onClick: startExecution,
              },
            ]}
          />
        </>
      ),
    },
    {
      title: "Execute CSR",
      content: (
        <>
          <Alert
            banner
            message="Please make sure Central Tasks are executed before CSR Upload"
            type="info"
            showIcon
          />
          <ContentTemplate
            showButtons
            buttonsConfig={[
              {
                type: "default",
                variant: "upload-multiple",
                buttonText: "Upload CSR Config",
                uploadConfig: {
                  uploadUrl: PreStagingUrls.csrConfigUrl,
                  showFileList: true,
                  multiple: true,
                  beforeUploadCallback: setCsrFileList,
                  defaultFileList: csrFileList ? csrFileList : [],
                },
                disabled: !hasAccess,
                hasToolTipWhenDisabled: true,
                toolTipText:
                  "Your profile is not eligible to perform this action",
                showCheckBox: true,
                checkBoxText: "Update if the data already exists?",
                className: "csr-upload",
              },
            ]}
          />
        </>
      ),
    },
    {
      title: "Start Execution",
      content: (
        <>
          <Alert
            banner
            message="You can now start executing Switch and IAP tasks"
            type="info"
            showIcon
          />
          <ContentTemplate
            showButtons
            showSelectionTables
            showSwitchTasksSelection
            switchTableData={{
              dataSource: switchTasks.map((value, i) => ({
                key: i + value,
                task: value,
              })),
              columns: switchColumns,
              onSelectionHandler: handleSwitchSelection,
              selectedRowKeys: selectedSwitchTasks.map((val) => val.key),
            }}
            showIAPTasksSelection
            iapTableData={{
              dataSource: iapTasks.map((value, i) => ({
                key: i + value,
                task: value,
              })),
              columns: iapColumns,
              onSelectionHandler: handleIAPSelection,
              selectedRowKeys: selectedIAPTasks.map((val) => val.key),
            }}
            buttonsConfig={[
              {
                type: "primary",
                variant: "default",
                buttonText: "Start Execution",
                disabled: isDisabled() || !hasAccess,
                hasToolTipWhenDisabled: true,
                toolTipText: !hasAccess
                  ? "Your profile is not eligible to perform this action"
                  : "Please select at least 1 task to proceed with Execution",
                loading: executionLoading,
                onClick: startExecution,
                className: "start-execution",
              },
            ]}
          />
        </>
      ),
    },
    {
      title: "Missing Variables Upload",
      content: (
        <>
          <Alert
            banner
            message="Please Upload the Missing Variable File if there are any missing variables"
            type="info"
            showIcon
          />
          <ContentTemplate
            showButtons
            showSelectionTables
            showSwitchTasksSelection
            switchTableData={{
              dataSource: [
                {
                  key: 1,
                  task: "CHANGE-VARIABLES",
                },
              ],
              columns: switchColumns,
              onSelectionHandler: handleSwitchSelection,
            }}
            showIAPTasksSelection
            iapTableData={{
              dataSource: [
                {
                  key: 1,
                  task: "CHANGE-VARIABLES",
                },
              ],
              columns: iapColumns,
              onSelectionHandler: handleIAPSelection,
            }}
            buttonsConfig={[
              {
                type: "primary",
                variant: "default",
                buttonText: "Start Execution",
                disabled:
                  missingVarFile === null ? true : isDisabled() || !hasAccess,
                className: "start-execution",
                hasToolTipWhenDisabled: true,
                toolTipText: !hasAccess
                  ? "Your profile is not eligible to perform this action"
                  : missingVarFile === null
                  ? "Please upload the missing variable file to start execution"
                  : siteId.length === 0
                  ? "Please select a site before Start Execution"
                  : "Please select at least 1 task to proceed with Execution",
                loading: executionLoading,
                onClick: startExecution,
              },
              {
                type: "default",
                variant: "upload-dropdown",
                buttonText: "Upload Missing Variables File",
                accept: ".xlsx",
                className: "upload-missing-variable",
                disabled: !hasAccess,
                uploadConfig: {
                  menu: menu([
                    {
                      menuText: "Download switch template",
                      cb: () =>
                        download(
                          DownloadUrls.downloadUrl,
                          "Missing_Variable_template.xlsx",
                          DOWNLOAD_TYPES.MISSING_VAR_SWITCH_TEMPLATE
                        ),
                    },
                    {
                      menuText: "Download IAP template",
                      cb: () =>
                        download(
                          DownloadUrls.downloadUrl,
                          "Bulk_Site_template.xlsx",
                          DOWNLOAD_TYPES.MISSING_VAR_IAP_TEMPLATE
                        ),
                    },
                  ]),
                  customUpload: true,
                  showFileList: true,
                  beforeUploadCallback: setMissingVarFile,
                },
              },
            ]}
          />
        </>
      ),
    },
  ];

  return (
    <>
      <Tour
        steps={steps}
        isOpen={isTourOpen}
        onRequestClose={() => setIsTourOpen(false)}
        goToStep={tourIndex}
      />
      <ContentTemplate
        title="Pre-Staging"
        handleTourSelection={() => setIsTourOpen((open) => !open)}
        showMainTable
        mainTable={
          <PreStagingTable
            reloadTable={reloadTable}
            setReloadTable={() => setReloadTable((prev) => !prev)}
            showTitle
          />
        }
      >
        <ExecutionSteps
          steps={executionSteps}
          currentIndex={stepIndex}
          cleanupAfterStep={cleanupAfterStep}
        />
      </ContentTemplate>
    </>
  );
};

export default PreStaging;
