import React, { useState, useEffect } from "react";
import { Steps, Button, Tooltip } from "antd";

import "./styles.scss";

const { Step } = Steps;

type steps = {
  title: string;
  content: any;
  description?: string;
  subTitle?: string;
  icon?: React.ReactNode;
  disabled?: boolean;
  toolTipText?: string;
  hideButton?: boolean;
};

interface IExecutionStepsType {
  type?: "default" | "navigation" | undefined;
  steps: steps[];
  dotStyle?: boolean;
  currentIndex?: number;
  cleanupAfterStep?: (index: number) => void;
}

const ExecutionSteps: React.FC<IExecutionStepsType> = ({
  steps,
  dotStyle,
  type,
  currentIndex,
  cleanupAfterStep,
}) => {
  const [current, setCurrent] = useState(0);

  useEffect(() => {}, [current]);

  useEffect(() => {
    setCurrent(currentIndex ? currentIndex : 0);
  }, [current, currentIndex]);
  
  const next = (idx: number) => {
    setCurrent(idx);
    cleanupAfterStep && cleanupAfterStep(idx);
  };

  const prev = (idx: number) => {
    setCurrent(idx);
    cleanupAfterStep && cleanupAfterStep(idx);
  };

  return (
    <section className="steps-container">
      <Steps
        current={current}
        progressDot={dotStyle}
        type={type ? type : "default"}
        className={type ? "site-navigation-steps" : undefined}
        size="small"
      >
        {steps.map((item) => (
          <Step
            key={item.title}
            title={item.title}
            description={item.description}
            subTitle={item.subTitle}
          />
        ))}
      </Steps>
      <div className="steps-content">{steps[current].content}</div>
      {steps[current].hideButton ? undefined : (
        <div className="steps-action">
          {current > 0 && (
            <Button
              style={{ margin: "0 8px" }}
              onClick={() => prev(current - 1)}
            >
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Tooltip
              title={
                steps[current].disabled && steps[current].toolTipText
                  ? steps[current].toolTipText
                  : ""
              }
            >
              <Button
                type="primary"
                onClick={() => next(current + 1)}
                className="next-button"
                disabled={steps[current].disabled}
              >
                Next
              </Button>
            </Tooltip>
          )}
        </div>
      )}
    </section>
  );
};

export default ExecutionSteps;
