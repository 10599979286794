import { Form, Input, Button, Space, Modal, Divider } from "antd";
import React, { useState } from "react";
import { Text } from "../../atoms";
import { useAuth } from "../../context/Auth";
import { UserOutlined, LockOutlined, KeyOutlined } from "@ant-design/icons";
import { useHistory, useLocation } from "react-router-dom";
import {
  CheckCircleFilled,
  LoadingOutlined,
  CloseCircleTwoTone,
} from "@ant-design/icons";

import "./styles.scss";
import API from "../../services/API";
import { AuthUrls } from "../../services/apiUrls";
import FeedbackForm from "../../organisms/FeedbackForm";
import { useSpinner } from "../../context/Spinner.context";
import { getCookie } from "../../utils";

export const SSO_URL: any = process.env.REACT_APP_SSO_URL;

const LoginPage: React.FC = () => {
  const { signIn } = useAuth();
  const [form] = Form.useForm();
  const { setSpinner } = useSpinner();
  const history = useHistory();
  const location = useLocation();
  const [current, setCurrent] = useState<"login" | "feedback">("login");

  const handleLogin = (values: any) => {
    const { username, password } = values;
    setSpinner(true);
    API.post(AuthUrls.login, {
      username,
      password,
    })
      .then((res) => {
        const { data } = res;
        if (data?.groups?.length === 0) {
          history.replace("/forbidden", {
            from: "/login",
          });
        } else if (!data?.firstLoginFlag) {
          if (
            data?.groups?.length === 1 &&
            data?.groups[0].toLowerCase() === "gxo_staging_user"
          ) {
            signIn({ name: data.username, role: data.groups }, data.access);
            history.replace("/", {
              from: "login",
            });
          } else {
            signIn({ name: data.username, role: data.groups }, data.access);
            history.replace("/", {
              from: "login",
            });
          }
        } else {
          history.replace("/password-reset", {
            from: "login",
            username: username,
            access: data.access,
          });
        }
      }) // log the user in
      .catch((err) => {
        if (err.response) {
          const { data } = err.response;
          form.setFields([
            {
              name: "password",
              errors: [data.Message],
            },
          ]);
        } else {
          Modal.error({
            title: "Error while Login",
            content: JSON.stringify(err.Message),
          });
        }
      })
      .finally(() => setSpinner(false));
  };

  const samlLogin = () => {
      const emailAddress = getCookie('mail')
      const data = {
        mail: emailAddress,
      };
      API.post(AuthUrls.samlToken, data)
        .then((res) => {
          const { data } = res;
          if (data?.groups?.length === 0) {
            history.replace("/forbidden", {
              from: "/login",
            });
          } else {
            if (
              data?.groups?.length === 1 &&
              data?.groups[0].toLowerCase() === "gxo_staging_user"
            ) {
              signIn({ name: data.username, role: data.groups }, data.access);
              history.replace("/", {
                from: "login",
              });
            } else {
              signIn({ name: data.username, role: data.groups }, data.access);
              history.replace("/", {
                from: "login",
              });
            }
          }
        })
        .catch((err) => {
          if (err.response) {
            const { data } = err.response;
            Modal.error({
              title: "Error while Login",
              content: JSON.stringify(data.detail),
              afterClose: () => history.replace("/", { from: "login#success" }),
            });
          }
        })
        .finally(() => setSpinner(false));
  };

  if (location.hash === "#success") {
    samlLogin();

    return (
      <section className="login-background">
        <section className="login-container">
          <h3 style={{ margin: 0 }}>
            <CheckCircleFilled
              style={{ marginRight: "10px", color: "#52c41a" }}
            />
            Login Success, Redirecting to Home screen
            <LoadingOutlined style={{ marginLeft: "10px" }} />
          </h3>
        </section>
      </section>
    );
  }

  if (location.hash === "#error") {
    // setTimeout(() => history.replace("/login"), 3000);
    return (
      <section className="login-background">
        <section className="login-container">
          <h3 style={{ margin: 0 }}>
            <CloseCircleTwoTone
              style={{ marginRight: "10px" }}
              twoToneColor="#ff4d4f"
            />
            Login Failed, Please try to{" "}
            <Button
              className="m-0 p-0"
              type="link"
              size="large"
              onClick={() => history.replace("/login")}
            >
              Login
            </Button>{" "}
            again
          </h3>
        </section>
      </section>
    );
  }
  return (
    <section className="login-background">
      <section className="login-container">
        {current === "login" ? (
          <React.Fragment>
            <div className="title">
              <img src="TheHomeDepot.svg" alt="THD" />
              <Text title level={3}>
                The Home Depot
              </Text>
            </div>

            <Space direction="vertical">
              <Text title level={4}>
                Login
              </Text>
              <Form
                form={form}
                name="normal_login"
                className="login-form"
                initialValues={{}}
                onFinish={handleLogin}
              >
                <Form.Item
                  name="username"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                  ]}
                >
                  <Input
                    prefix={<UserOutlined className="site-form-item-icon" />}
                    placeholder="Email address"
                  />
                </Form.Item>
                <Form.Item
                  name="password"
                  rules={[
                    { required: true, message: "Please input your Password!" },
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    type="password"
                    placeholder="Password"
                  />
                </Form.Item>
                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="login-form-button"
                  >
                    Log in
                  </Button>
                  Or{" "}
                  <Button
                    className="m-0 p-0"
                    type="link"
                    onClick={() => setCurrent("feedback")}
                  >
                    need help?
                  </Button>
                </Form.Item>
                <Divider style={{ fontWeight: "normal", fontSize: "small" }}>
                  Or log in with
                </Divider>
                <Form.Item>
                  <Button
                    icon={<KeyOutlined />}
                    type="default"
                    className="login-form-button"
                    onClick={() => window.location.href = SSO_URL}
                  >
                    HPE SSO SignIn
                  </Button>
                </Form.Item>
              </Form>
            </Space>
          </React.Fragment>
        ) : current === "feedback" ? (
          <FeedbackForm
            showTitle
            fromLogin
            onAbort={() => setCurrent("login")}
          />
        ) : null}
      </section>
    </section>
  );
};

export default LoginPage;
