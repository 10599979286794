import React, { useEffect, useState } from "react";
import DataTable from "../../atoms/Table";
import API from "../../services/API";
import {
  PlanningUrls,
  PreStagingUrls,
  StagingUrls,
} from "../../services/apiUrls";
import { PhaseType } from "../../types";
import { formatTaskListData, tableFilterParser } from "../../utils";

type taskTypes = "Switch Tasks" | "IAP Tasks" | "Task Statuses";

interface IInnerTableType {
  columns: Array<any>;
  taskTitle: taskTypes;
  phase: PhaseType;
  data: {
    exe_id: string;
    site: string;
  };
  expandedRow: { expanded: boolean; record: any };
}

const PAGE_SIZE = 10;

const InnerTable: React.FC<IInnerTableType> = ({
  columns,
  phase,
  data,
  taskTitle,
  expandedRow,
}) => {
  const [tableData, setTableData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: PAGE_SIZE,
    total: undefined,
    showSizeChanger: true,
  });
  const [params, setParams] = useState("");

  const getUrl = () => {
    switch (phase) {
      case "Planning":
        return PlanningUrls.taskStatusUrl;
      case "PreStaging":
        return PreStagingUrls.taskStatusUrl;
      case "Staging":
        return StagingUrls.taskStatusUrl;
      default:
        return PreStagingUrls.taskStatusUrl;
    }
  };

  const getType = () => {
    switch (taskTitle) {
      case "Switch Tasks":
        return "switch";
      case "IAP Tasks":
        return "iap";
      case "Task Statuses":
        return null;
      default:
        return "switch";
    }
  };

  const url = getUrl();
  const type = getType();

  useEffect(() => {
    if (expandedRow.expanded && expandedRow.record.title === taskTitle) {
      setLoading(true);
      API.get(url + "?" + params, {
        ...data,
        type,
      })
        .then((response) => {
          if (phase === "Planning") {
            setTableData(formatTaskListData(response.data.data.results, phase));
            setPagination((prev) => ({
              ...prev,
              total: response.data.data.count,
            }));
          } else if (phase === "Staging") {
            setTableData(response.data.data);
          } else if (phase === "PreStaging") {
            setTableData(response.data.data);
          } else {
            setTableData(formatTaskListData(response.data.data, phase));
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, expandedRow]);

  const tableChangeHandler = ({ pagination, filters, sorter }: any) => {
    setPagination(pagination);
    setParams(tableFilterParser({ pagination, filters, sorter }));
  };

  return (
    <DataTable
      onChange={
        phase === "Planning"
          ? (pagination, filters, sorter, extra) =>
              tableChangeHandler({ pagination, filters, sorter, extra })
          : undefined
      }
      bordered
      size="small"
      dataSource={tableData}
      columns={columns}
      loading={loading}
      pagination={phase === "Planning" ? pagination : false}
      style={{ width: "100%",}}
    />
  );
};

export default InnerTable;
