import React, { useEffect } from "react";
import DataTable, { IDataTableType } from "../../atoms/Table";

import "./styles.scss";

interface ISelectionTableType {
  style?: any;
  className?: string;
  tableProps: IDataTableType;
  onSelectionHandler: (keys: any, records: any) => void;
  error?: string | null;
  selectedRowKeys?: Array<any>;
  getCheckboxProps?: any;
}

const SelectionTable: React.FC<ISelectionTableType> = ({
  style,
  className,
  tableProps,
  onSelectionHandler,
  error,
  selectedRowKeys,
  getCheckboxProps,
}) => {
  useEffect(() => {
    return () => {
      // on component unmount
      onSelectionHandler([], []);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className={"selection-table " + className} style={style}>
      <DataTable
        rowSelection={{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectionHandler,
          getCheckboxProps: getCheckboxProps,
        }}
        pagination={false}
        bordered
        size="small"
        showHeader
        scroll={{ y: 200 }}
        {...tableProps}
      />
    </div>
  );
};

export default SelectionTable;
