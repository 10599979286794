import React, { useState } from "react";
import { IDataTableType } from "../../atoms";
import DataTable from "../../atoms/Table";
import InnerTable from "../../molecules/InnerTable";
import { PhaseType } from "../../types";
import { getColumns, getStagingColumns } from "../../utils";
import { getCustomTable } from "../../utils/getCustomTable";

export interface IExpandableTableType {
  style?: any;
  className?: string;
  tableProps: IDataTableType;
  expandedSelectionColumnValues: Array<any>;
  useCustomExpandComponent?: boolean;
  phase?: PhaseType;
  expandability?: boolean;
}

const ExpandableTable: React.FC<IExpandableTableType> = ({
  className,
  style,
  tableProps,
  expandedSelectionColumnValues,
  useCustomExpandComponent,
  phase,
  expandability,
}) => {
  const [expandedRow, setExpandedRow] = useState<{
    expanded: boolean;
    record: any;
  }>({
    expanded: false,
    record: {},
  });

  return (
    <div className={" " + className} style={style}>
      <DataTable
        showHeader
        bordered
        {...tableProps}
        expandable={expandability ? {
          expandedRowRender: (parentRecord: any) => {
            return (
              <DataTable
                bordered
                showHeader={false}
                pagination={false}
                columns={expandedSelectionColumnValues}
                dataSource={parentRecord.downDownTableData}
                expandable={{
                  expandedRowRender: (record: any) => {
                    const CustomComponent = getCustomTable(
                      record.title.toLowerCase()
                    );
                    return useCustomExpandComponent ? (
                      <CustomComponent allStage site={parentRecord.site_name} />
                    ) : (
                      <InnerTable
                        columns={
                          phase === "Staging"
                            ? getStagingColumns(record.title.toLowerCase())
                            : getColumns(record.title.toLowerCase())
                        }
                        taskTitle={record.title}
                        expandedRow={expandedRow}
                        phase={phase ? phase : "PreStaging"}
                        data={{
                          exe_id: parentRecord.exe_id,
                          site: parentRecord.site,
                        }}
                      />
                    );
                  },
                  onExpand: (expanded: any, record: any) =>
                    setExpandedRow({ expanded, record }),
                }}
              />
            );
          },
          rowExpandable: (record: any) => record.expand,
        } : null}
      />
    </div>
  );
};

export default ExpandableTable;
