import Upload, { IUploadType } from "../../molecules/Upload";
import React from "react";
import {
  Modal,
  Space,
  Tooltip,
  Button,
  Checkbox,
  Upload as AntUpload,
  Form,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import API from "../../services/API";
import { useSpinner } from "../../context/Spinner.context";
import { showConfirm, showErrorModel, showSuccessModel } from "../../utils";
import { useAuth } from "../../context/Auth";
import { getCookie } from "../../utils/cookies";
import { SizeType } from "antd/lib/config-provider/SizeContext";

export interface IButtonConfig {
  type: "primary" | "default";
  variant:
    | "default"
    | "upload"
    | "upload-dropdown"
    | "upload-multiple"
    | "custom-upload";
  buttonText?: string;
  disabled?: boolean;
  hasToolTipWhenDisabled?: boolean;
  icon?: React.ReactNode;
  toolTipText?: string;
  size?: SizeType;
  onClick?: () => void;
  onChange?: (file: any | null) => void;
  loading?: boolean;
  uploadConfig?: Omit<IUploadType, "type" | "buttonText">;
  showCheckBox?: boolean;
  checkBoxText?: string;
  handleCheckBox?: (val: boolean) => void;
  className?: string;
  accept?: string;
  maxCount?: number;
  defaultUpload?: boolean;
}

interface IActionButton {
  buttonsConfig: Array<IButtonConfig>;
}

const ActionButtons: React.FC<IActionButton> = ({ buttonsConfig }) => {

  const customUpload = (url:string | undefined, file: any, data: any, override:boolean, retry: boolean) => {
    const isLt6M = file.size / 1024 / 1024 < 6;
    if (!isLt6M) {
      showErrorModel("Error while Upload", "File size must be smaller than 6MB!");
      return 0;
    } else {
      setSpinner(true);
      const formData = new FormData();
      formData.append("file", file.originFileObj as any);
      formData.append("site_list", data.site_list);
      formData.append("override", `${override}`);
      API.post(buttonsConfig[1]?.uploadConfig?.uploadUrl, formData)
        .then((res) => {
          Modal.success({
            title: "Upload Started",
            content: res.data ? res.data.Message : "",
          });
        })
        .catch(({ response }) => {
          if (response) {
            if (response.data.override_flag && retry) {
              showConfirm(
                "Do you want to Override the data?",
                response.data.Message,
                () => customUpload(url, file, data, true, false)
              );
            } else {
              console.log(response);
              showErrorModel(
                "Error while Execution",
                response.data?.Message
                  ? response.data?.Message
                  : "Execution Timed Out"
              );
            }
          }
        })
        .finally(() => {
          setSpinner(false);
        });
    }
  }

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const { setSpinner } = useSpinner();
  const { user } = useAuth();

  const handleSubmit = (values: any) => {
    const formData = new FormData();
    if (values.file.length > 0) {
      (values.file as []).forEach((file: any) => {
        formData.append("file", file.originFileObj as any);
      });
    }
    buttonsConfig[0].defaultUpload
      ? formData.append("override", "true")
      : values.override
      ? formData.append("override", values.override)
      : formData.append("override", "false");

    setSpinner(true);
    API.post(buttonsConfig[0].uploadConfig?.uploadUrl + `?user=${user?.name}`, formData, true, {
      "Content-Type": "multipart/form-data",
    })
      .then((res) => {
        showSuccessModel("Upload status", res.data.Message);
      })
      .catch(({ response }) => {
        showErrorModel(
          "Error",
          response && response.data.Message
            ? response.data.Message
            : "Error while uploading files"
        );
      })
      .finally(() => setSpinner(false));
  };

  return (
    <Space
      wrap
      style={{
        alignItems: "flex-start",
      }}
      direction="horizontal"
    >
      {buttonsConfig.map((buttonConfig, i) => {
        switch (buttonConfig.variant) {
          case "default":
            return (
              <Space key={i} direction="vertical">
                <Tooltip
                  key={i}
                  title={
                    buttonConfig.disabled &&
                    buttonConfig.hasToolTipWhenDisabled &&
                    buttonConfig.toolTipText
                      ? buttonConfig.toolTipText
                      : ""
                  }
                >
                  <Button
                    key={i}
                    className={buttonConfig.className}
                    loading={buttonConfig.loading}
                    disabled={buttonConfig.disabled}
                    type={buttonConfig.type}
                    onClick={buttonConfig.onClick}
                    icon={buttonConfig.icon}
                    size={buttonConfig.size}
                  >
                    {buttonConfig.buttonText ? buttonConfig.buttonText : ""}
                  </Button>
                </Tooltip>
                {buttonConfig.showCheckBox && buttonConfig.checkBoxText && (
                  <Checkbox
                    onChange={(e) =>
                      buttonConfig.handleCheckBox &&
                      buttonConfig.handleCheckBox(e.target.checked)
                    }
                    disabled={buttonConfig.disabled}
                  >
                    {buttonConfig.checkBoxText}
                  </Checkbox>
                )}
              </Space>
            );
          case "upload":
            return (
              <Tooltip
                title={
                  buttonConfig.disabled &&
                  buttonConfig.hasToolTipWhenDisabled &&
                  buttonConfig.toolTipText
                    ? buttonConfig.toolTipText
                    : ""
                }
              >
                <Space key={i} direction="vertical">
                  <Upload
                    key={i}
                    accept={buttonConfig.accept}
                    type="default"
                    disabled={buttonConfig.disabled}
                    toolTipText={buttonConfig.toolTipText}
                    buttonText={buttonConfig.buttonText ? buttonConfig.buttonText : ""}
                    className={buttonConfig.className}
                    {...buttonConfig.uploadConfig}
                  />

                  {buttonConfig.showCheckBox && buttonConfig.checkBoxText && (
                    <Checkbox
                      onChange={(e) =>
                        buttonConfig.handleCheckBox &&
                        buttonConfig.handleCheckBox(e.target.checked)
                      }
                      disabled={buttonConfig.disabled}
                    >
                      {buttonConfig.checkBoxText}
                    </Checkbox>
                  )}
                </Space>
              </Tooltip>
            );
          case "upload-dropdown":
            return (
              <Space key={i} direction="vertical">
                <Upload
                  key={i}
                  type="dropdown"
                  disabled={buttonConfig.disabled}
                  accept={buttonConfig.accept}
                  className={buttonConfig.className}
                  buttonText={buttonConfig.buttonText ? buttonConfig.buttonText : ""}
                  {...buttonConfig.uploadConfig}
                />
                {buttonConfig.showCheckBox && buttonConfig.checkBoxText && (
                  <Checkbox
                    onChange={(e) =>
                      buttonConfig.handleCheckBox &&
                      buttonConfig.handleCheckBox(e.target.checked)
                    }
                    disabled={buttonConfig.disabled}
                  >
                    {buttonConfig.checkBoxText}
                  </Checkbox>
                )}
              </Space>
            );
          case "upload-multiple":
            return (
              <Form onFinish={handleSubmit}>
                <Form.Item
                  name="file"
                  valuePropName="fileList"
                  getValueFromEvent={normFile}
                >
                  <AntUpload
                    multiple
                    accept={buttonConfig.accept}
                    className="upload-files"
                    beforeUpload={() => false}
                    disabled={buttonConfig.disabled}
                    {...buttonConfig.uploadConfig}
                    onChange={buttonConfig.onChange}
                    showUploadList={true}
                    maxCount={buttonConfig.maxCount}
                  >
                    <Tooltip
                      key={i}
                      title={
                        buttonConfig.disabled &&
                        buttonConfig.hasToolTipWhenDisabled &&
                        buttonConfig.toolTipText
                          ? buttonConfig.toolTipText
                          : ""
                      }
                    >
                      <Button
                        disabled={buttonConfig.disabled}
                        icon={<UploadOutlined />}
                      >
                        {buttonConfig.buttonText}
                      </Button>
                    </Tooltip>
                  </AntUpload>
                </Form.Item>
                {buttonConfig.showCheckBox && buttonConfig.checkBoxText && (
                  <Tooltip
                    key={i}
                    title={
                      buttonConfig.disabled &&
                      buttonConfig.hasToolTipWhenDisabled &&
                      buttonConfig.toolTipText
                        ? buttonConfig.toolTipText
                        : ""
                    }
                  >
                    <Form.Item name="override" valuePropName="checked">
                      <Checkbox
                        disabled={buttonConfig.disabled}
                        onChange={(e) =>
                          buttonConfig.handleCheckBox &&
                          buttonConfig.handleCheckBox(e.target.checked)
                        }
                      >
                        {buttonConfig.checkBoxText}
                      </Checkbox>
                    </Form.Item>
                  </Tooltip>
                )}

                <Form.Item>
                  <Space>
                    <Tooltip
                      key={i}
                      title={
                        buttonConfig.disabled &&
                        buttonConfig.hasToolTipWhenDisabled &&
                        buttonConfig.toolTipText
                          ? buttonConfig.toolTipText
                          : ""
                      }
                    >
                      {buttonConfig.defaultUpload ? undefined : (
                        <Button
                          disabled={buttonConfig.disabled}
                          type="primary"
                          htmlType="submit"
                          className="upload-button"
                        >
                          Upload
                        </Button>
                      )}
                    </Tooltip>
                  </Space>
                </Form.Item>
              </Form>
            );
          case "custom-upload":
            return (
              <AntUpload
                action={buttonConfig.uploadConfig?.uploadUrl}
                data={buttonConfig.uploadConfig?.data}
                withCredentials={true}
                headers={{
                  "X-CSRFToken": `${getCookie("csrftoken")}`,
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                }}
                accept={buttonConfig.accept}
                className="upload-files"
                disabled={buttonConfig.disabled}
                {...buttonConfig.uploadConfig}
                showUploadList={false}
                maxCount={1}
                onChange={(info) => {
                  setSpinner(true);
                  if (info.file.status === "done") {
                    Modal.success({
                      title: "Upload Status",
                      content: `Upload Message: ${
                        info.file.response && info.file.response.Message
                          ? info.file.response.Message
                          : "Successful"
                      }`,
                    });
                    setSpinner(false);
                  } else if (info.file.status === "error") {
                    if (info?.file?.response?.override_flag) {
                      showConfirm(
                        "Do you want to Override the data?",
                        info?.file?.response?.Message,
                        () => {
                          customUpload(
                            buttonConfig.uploadConfig?.uploadUrl,
                            info?.file,
                            buttonConfig.uploadConfig?.data,
                            true,
                            true
                          );
                        }
                      );
                      setSpinner(false);
                    } else {
                      showErrorModel(
                        "Error while Execution",
                        info?.file?.response && info?.file?.response.Message
                          ? info?.file?.response.Message
                          : "Execution Timed Out"
                      );
                      setSpinner(false);
                    }
                  }
                }}
              >
                <Tooltip
                  key={i}
                  title={
                    buttonConfig.disabled &&
                    buttonConfig.hasToolTipWhenDisabled &&
                    buttonConfig.toolTipText
                      ? buttonConfig.toolTipText
                      : ""
                  }
                >
                  <Button
                    disabled={buttonConfig.disabled}
                    icon={<UploadOutlined />}
                  >
                    {buttonConfig.buttonText}
                  </Button>
                </Tooltip>
              </AntUpload>
            );
        }
        return true;
      })}
    </Space>
  );
};

export default ActionButtons;
