import { Alert, Modal, Select, Tooltip, Button, Divider } from "antd";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/Auth";
import { useSpinner } from "../../context/Spinner.context";
import ExecutionSteps from "../../organisms/ExecutionSteps";
import { checkRole, checkAdmin } from "../../services/authorization";
import ContentTemplate from "../../templates/ContentTemplate";
import PostCheckTable from "./PostCheckTable";
import ActionButtons, { IButtonConfig } from "../../organisms/ActionButtons";
import { DownloadUrls, PostCheckUrls, StagingUrls } from "../../services/apiUrls";
import API, { BASE_URL } from "../../services/API";
import { showErrorModel } from "../../utils";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Text } from "../../atoms";

interface ITaskType {
  key: string;
  task: string;
}

const PostCheck: React.FC = () => {
  const [stepIndex, setStepIndex] = useState(0);
  const [reloadTable, setReloadTable] = useState(false);
  const [siteId, setSiteId] = useState<Array<string>>([]);
  const [validationStep, setValidationStep] = useState<boolean>(false);
  const [selectedSite, setSelectedSite] = useState<string>("");
  const [iapTasks, setIAPTasks] = useState<Array<string>>([]);
  const [iapTasksLoading, setIAPTasksLoading] = useState<boolean>(false);
  const [selectedIAPTasks, setSelectedIAPTasks] = useState<Array<ITaskType>>(
    []
  );
  const [baseCopy, setBaseCopy] = useState(false);
  const { Option } = Select;
  const { setSpinner } = useSpinner();
  const { user } = useAuth();
  const hasAccess = checkRole(user?.role?.toString().toUpperCase(), "postcheck");
  const isAdmin = checkAdmin(user?.role?.toString().toUpperCase());
  var siteOptions: any[] = [];

  useEffect(() => {
    fetchIAPTasks();
  }, []);

  useEffect(() => {
    siteId.forEach((siteNumber: string) => {
      siteOptions.push(<Option value={siteNumber}>{siteNumber}</Option>);
    });
    if (stepIndex === 1) {
      API.get(PostCheckUrls.baseCopySiteValidation + `?site_name=${siteId[0]}`)
        .then((res) => {
          console.log(res.data);
          if (res.status === 200) {
            setBaseCopy(true);
          }
        })
        .catch((err) => {
          console.log(err);
          setBaseCopy(false);
          Modal.error({
            title: "Device error",
            content: `There aren't any devices associated to site: ${siteId[0]}`,
          });
        });
    }
  }, [stepIndex]);

  const isDisabled = () => {
    if (siteId.length !== 0) {
      return false;
    }
    return true;
  };

  const fetchIAPTasks = () => {
    setIAPTasksLoading(true);
    API.get(PostCheckUrls.iapExecutionUrl + PostCheckUrls.iapTasksParam)
      .then(({ data }) => {
        setIAPTasks(data.task_list);
        setSelectedIAPTasks(
          data.task_list.map((value: string, key: string) => value)
        );
      })
      .catch(({ response }) => {
        setIAPTasks([]);
      })
      .finally(() => setIAPTasksLoading(false));
  };

  const executeIap = () => {
    console.log(selectedIAPTasks);
    setSpinner(true);
    const formData = new FormData();
    formData.append(
      "task_types",
      JSON.stringify({
        iap: selectedIAPTasks.map((task ) => task),
      })
    );
    formData.append("site_list", JSON.stringify(siteId));
    API.post(
      PostCheckUrls.iapExecutionUrl + `?user=${user?.name}`,
      formData,
      true,
      {
        "Content-Type": "multipart/form-data",
      }
    )
      .then((res) => {
        setSpinner(false);
        Modal.success({
          title: "Execution Started",
          content: res.data ? res.data.Message : "",
        });
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  const buttonsConfig: Array<IButtonConfig> = [
    {
      type: "primary",
      variant: "default",
      buttonText: "Download DB",
      disabled: isDisabled() || !hasAccess,
      className: "download-db-button",
      hasToolTipWhenDisabled: true,
      toolTipText: !hasAccess
        ? "Your profile is not eligible to perform this action"
        : isDisabled()
        ? "Please enter site number before downloading the db file"
        : undefined,
      onClick: () =>
        download(
          DownloadUrls.postCheckDbDownload,
          "post-check.db",
          siteId,
          user?.name
        ),
    },
    {
      type: "default",
      variant: "custom-upload",
      buttonText: "Upload DB",
      className: "upload-db-button",
      disabled: isDisabled() || !hasAccess,
      hasToolTipWhenDisabled: true,
      toolTipText: !hasAccess
        ? "Your profile is not eligible to perform this action"
        : undefined,
      uploadConfig: {
        uploadUrl: BASE_URL + PostCheckUrls.dbUploadUrl + `?user=${user?.name}`,
        data: { site_list: JSON.stringify(siteId), override: baseCopy },
        accept: ".db",
        }
      },
      
    
     {
       type: "default",
       variant: "default",
       buttonText: "Execute IAP",
       disabled: isDisabled() || !hasAccess,
       className: "download-db-button",
       hasToolTipWhenDisabled: true,
       toolTipText: !hasAccess
         ? "Your profile is not eligible to perform this action"
         : isDisabled()
         ? "Please enter site number before downloading the db file"
         : undefined,
       onClick: () => executeIap()
     },
  ];

  const changeSite = (value: any) => {
    setSelectedSite(value);
    API.get(PostCheckUrls.baseCopySiteValidation + `/?site=${value}`)
      .then((res) => {
        console.log(res.data)
        if (res.status === 200) {
          setBaseCopy(true);
        }
      })
      .catch((err) => {
        console.log(err);
        setBaseCopy(false);
        Modal.error({
          title: "Device error",
          content: `There aren't any devices associated to site: ${value}`,
        });
      });
  };

  const ComparisionButtonConfig: Array<IButtonConfig> = [
    {
      type: "primary",
      variant: "custom-upload",
      buttonText: "Post-Check to Post-Check Comparision",
      className: "upload-db-button",
      disabled: isDisabled() || !hasAccess || !baseCopy,
      hasToolTipWhenDisabled: true,
      toolTipText: !hasAccess
        ? "Your profile is not eligible to perform this action"
        : !baseCopy ? "Base copy not present for the site to compare the db file" : undefined,
      uploadConfig: {
        uploadUrl: BASE_URL + '/' + PostCheckUrls.baseCopySiteValidation + `?user=${user?.name}`,
        data: { site_list: JSON.stringify(siteId), override: baseCopy },
        accept: ".db",
      },
    },
  ];

  const cleanUpAfterStep = (idx: number) => {
    setStepIndex(idx);
  };
  
  const download = (
    url: string,
    fileName: string,
    siteList: string[],
    user: string | undefined
  ) => {
    setSpinner(true);
    API.get(url + `?site_list=${JSON.stringify(siteList)}&user=${user}`)
      .then((response: any) => {
        Modal.success({
          title: "PostCheck Download",
          content: response?.data?.Message,
        });
      })
      .catch(({ response }) => {
        Modal.error({
          title: "PostCheck Download",
          content: response?.data?.Message,
        });
      })
      .finally(() => {
        setSpinner(false);
      });
  };

  function handleChange(value: any) {
    setValidationStep(false);
    if (value.length !== 0) {
      const formData = new FormData();
      formData.append("site_list", JSON.stringify(value));
      API.post(StagingUrls.siteValidation, formData, true)
        .then((res) => {
          if (res.status === 200) {
            setSiteId([...value]);
            setValidationStep(true);
          }
        })
        .catch(({ response }) => {
          siteId.length === 0 ? setValidationStep(false) : setValidationStep(true);
          showErrorModel(
            "Site ID validation error",
            response?.data ? response.data?.Message : "Execution Timed Out"
          );
        });
    } else {
      handleClear();
    }
  }

  function handleClear() {
    setSiteId([]);
    setValidationStep(false);
  }

  const ExecutionStep = [
    {
      title: "Upload or download db",
      content: (
        <>
          <Alert
            banner
            message="Enter Site Id, download the db file and perform switch & iap execution using executables before uploading the db file"
            type="info"
            showIcon
          />
          <ContentTemplate
            showMainTable
            mainTable={
              <>
                <div style={{ paddingBottom: "8px" }}>
                  Enter Site Ids{" "}
                  <Tooltip
                    placement="right"
                    title="Click outside the textbox after entering the site number or separate each site using ',' separator"
                  >
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
                <Select
                  mode="tags"
                  showSearch={false}
                  showArrow={false}
                  allowClear={true}
                  defaultActiveFirstOption={false}
                  filterOption={false}
                  placeholder="ST0001, ST0002, ST0003..."
                  style={{ width: "55%" }}
                  onChange={handleChange}
                  value={siteId}
                  tokenSeparators={[",", "\n"]}
                  notFoundContent={null}
                  onClear={handleClear}
                  disabled={!hasAccess}
                ></Select>
                <div className="action-buttons-container gap--top gap--bottom">
                  <ActionButtons
                    buttonsConfig={buttonsConfig ? buttonsConfig : []}
                  />
                </div>
                <Tooltip
                  title={
                    siteId
                      ? validationStep
                        ? ""
                        : "Check the site ID format entered"
                      : "Enter site ID/ upload workbook"
                  }
                  placement="right"
                >
                  <Button
                    onClick={() => setStepIndex(() => stepIndex + 1)}
                    className="next-store-btn"
                    type="primary"
                    disabled={!validationStep}
                  >
                    Next
                  </Button>
                </Tooltip>
              </>
            }
          />
        </>
      ),
      hideButton: true,
      toolTipText: "select site workbook file to stage the devices",
    },
    {
      title: "DB Comparision",
      content: (
        <>
          <div style={{ marginBottom: "10px" }}>
            <Text strong>Site ID selected: </Text>
            <Select
              size="small"
              defaultValue={siteId[0]}
              onChange={changeSite}
              style={{ width: 100 }}
            >
              {siteOptions}
            </Select>
          </div>
          <Divider style={{ marginTop: "0" }} />
          <ActionButtons
            buttonsConfig={
              ComparisionButtonConfig ? ComparisionButtonConfig : []
            }
          />
        </>
      ),
    },
  ];
  
  return (
    <>
      <ContentTemplate
        title="Post-Check"
        showMainTable
        mainTable={
          <PostCheckTable
            reloadTable={reloadTable}
            setReloadTable={() => setReloadTable((prev) => !prev)}
            showTitle
          />
        }
      >
        <ExecutionSteps
          dotStyle
          steps={ExecutionStep}
          type="default"
          currentIndex={stepIndex}
          cleanupAfterStep={cleanUpAfterStep}
        />
      </ContentTemplate>
    </>
  );
};

export default PostCheck;
