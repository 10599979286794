import { Tag } from "antd";
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  ClockCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import { StatusType } from "../types";

export const Status = (status: StatusType, value?: string) => {
  let displayValue: any = status;
  if (value) displayValue = value;
  switch (status) {
    case "Waiting":
      return (
        <Tag icon={<ClockCircleOutlined />} color="default">
          {displayValue}
        </Tag>
      );
    case "In Progress":
      return (
        <Tag icon={<SyncOutlined spin />} color="processing">
          {displayValue}
        </Tag>
      );
    case "Completed":
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {displayValue}
        </Tag>
      );
    case "Failed":
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          {displayValue}
        </Tag>
      );
    case "Error":
      return (
        <Tag icon={<CloseCircleOutlined />} color="error">
          {displayValue}
        </Tag>
      );
    case "Passed":
      return (
        <Tag icon={<CheckCircleOutlined />} color="success">
          {displayValue}
        </Tag>
      );
    default:
      return <Tag color="default">{displayValue}</Tag>;
  }
};

export const statusFilter = {
  filters: [
    { text: "Completed", value: "Completed" },
    { text: "In Progress", value: "In Progress" },
    { text: "Not Executed", value: "Not Executed" },
    { text: "Error", value: "Error" },
  ],
};
