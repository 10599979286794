export const initialStep = [
  {
    selector: ".upload-files",
    content: "upload master excel file here to populate db with the group list",
  },
  {
    selector: ".ant-checkbox-wrapper",
    content:
      "Check the override checkbox if you want to override the existing data",
  },
  {
    selector: ".upload-button",
    content: "Upload the selected mastersheet",
  },
  {
    selector: ".next-button",
    content: "Click next to proceed with the execution",
  },
];
export const siteStep = [
  {
    selector: ".site-input-box",
    content: "Enter upto 5 site number here for execution",
  },
  {
    selector: ".ant-upload",
    content:
      "If you got more than 5 sites to run, fill the details to the excel template and upload it here",
  },
  {
    selector: ".next-button",
    content: "Click next to proceed with the execution",
  },
];
export const centralStep = [
  {
    selector: ".ant-table-tbody",
    content: "Execute all of the central tasks",
  },
  {
    selector: ".start-execution",
    content: "Click the button to start the task execution",
  },
  {
    selector: ".next-button",
    content: "Click next to proceed with the execution",
  },
];
export const csrStep = [
  {
    selector: ".upload-files",
    content: "Upload the CSR configuration file here to populate MDF values",
  },
  {
    selector: ".ant-checkbox-wrapper",
    content:
      "Check the override checkbox if you want to override the existing data",
  },
  {
    selector: ".upload-button",
    content: "Upload the selected CSR files",
  },
  {
    selector: ".next-button",
    content: "Click next to proceed with the execution",
  },
];
export const executionStep = [
  {
    selector: ".ant-table-tbody",
    content: "Select the task from the list to execute",
  },
  {
    selector: ".start-execution",
    content: "Click the button to start the task execution",
  },
  {
    selector: ".next-button",
    content: "Click next to proceed with the execution",
  },
];
export const missingVariablesStep = [
  {
    selector: ".ant-table-tbody",
    content:
      "Select the Task based on whether you want to change switch/ IAP task",
  },
  {
    selector: ".upload-missing-variable",
    content: "Then upload the missing variable template here",
  },
  {
    selector: ".start-execution",
    content: "Click the button to start the task execution",
  },
  {
    selector: ".execution-data-table",
    content: "Run status will be appended to execution data table",
  },
  {
    selector: ".refresh-button",
    content:
      "If you do not see the status of current execution, refresh the table by clicking on the button",
  },
];