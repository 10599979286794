import { Typography } from "antd";
const { Text } = Typography;

export const columns = [
  {
    title: "Device",
    dataIndex: "host_name",
    key: "host_name",
  },
  {
    title: "Serial Number",
    dataIndex: "serial",
    key: "serial",
    render: (text: any) => (
      <Text type="danger">
        {text}
      </Text>
    ),
  },
  {
    title: "MAC Address",
    dataIndex: "mac_address",
    key: "mac_address",
  },
];
