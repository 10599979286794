export const DOWNLOAD_TYPES = {
  SITE_TEMPLATE: "site_template",
  MISSING_VAR_SWITCH_TEMPLATE: "missing_var_switch_template",
  MISSING_VAR_IAP_TEMPLATE: "missing_var_iap_template",
  OUTPUT_FILE: "output_file",
  INPUT_FILE: "input_file",
  MANUAL_FILE: "manuals",
};

export const EXE_DOWNLOAD_URLS = {
  PRECHECK:
     "https://hpe.sharepoint.com/:f:/s/msteams_17dc05/Eg6QKgr3u8JAuHqNS_hMjxUBLrQq6kwARCGq4g_HoqwYWQ?e=mL3I7U",
   POSTCHECK:
	     "https://hpe.sharepoint.com/:f:/s/msteams_17dc05/EgU8twca8ytMrcMuO0vv9zwBLGnuCtUUBMeEWg_K1rO47Q?e=vlsE4H",
};
