import { Tag } from "antd";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";

export const columns = [
  {
    title: "Device Type",
    dataIndex: "device_type",
    key: "device_type",
  },
  {
    title: "Serial Number",
    dataIndex: "serial",
    key: "serial",
  },
  {
    title: "Host Name",
    dataIndex: "device_name",
    key: "device_name",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "status",
    render: (status: string) => (
      <Tag
        icon={
          status === "Not Executed" ? (
            <CloseCircleOutlined />
          ) : status === "Passed" ? (
            <CheckCircleOutlined />
          ) : (
            <CloseCircleOutlined />
          )
        }
        color={
          status === "Not Executed"
            ? "default"
            : status === "Passed"
            ? "success"
            : "error"
        }
      >
        {status === "Not Executed"
          ? "Not Executed"
          : status === "Passed"
          ? "Passed"
          : "Failed"}
      </Tag>
    ),
  },
];

export const statusColumns = [
  {
    title: "Tasks",
    dataIndex: "task",
    key: "key",
  },
  {
    title: "Status",
    dataIndex: "status",
    key: "stat",
    render: (text: any, data: any) => (
      <>
        {data?.message?.status === "Passed" ? (
          <Tag icon={<CheckCircleOutlined />} color="success">
            Passed
          </Tag>
        ) : (
          <Tag icon={<CloseCircleOutlined />} color="error">
            Failed
          </Tag>
        )}
      </>
    ),
  },
  {
    title: "Message",
    dataIndex: "message",
    key: "message",
    render: (text: any, data: any) => <>{data?.message?.Message}</>,
  },
];
