import React, { useEffect, useState } from "react";
import { Button, Text } from "../../../atoms";
import { TaskTypeDropDownColumns } from "../../../constants/otherTableConfigs";
import { useSpinner } from "../../../context/Spinner.context";
import ExpandableTable from "../../../organisms/ExpandableTable";
import API from "../../../services/API";
import { DownloadUrls, PlanningUrls } from "../../../services/apiUrls";
import { ExecutionTableData, PhaseType } from "../../../types";
import { DownloadFile, formatExecutionData, tableFilterParser } from "../../../utils";
import { columns } from "../tableConfig";
import { FunnelPlotOutlined } from "@ant-design/icons";


interface PlanningTableType {
  showTitle?: boolean;
  reloadTable?: boolean;
  setReloadTable?: () => void;
  allStage?: boolean;
  site?: string;
}

const PAGE_SIZE = 5;

const PlanningTable: React.FC<PlanningTableType> = ({
  showTitle,
  reloadTable,
  setReloadTable,
  allStage,
  site,
}) => {
  const [mainTableLoading, setMainTableLoading] = useState(false);
  const [mainTableData, setMainTableData] = useState<Array<ExecutionTableData>>(
    []
  );
  const [filters, setFilters] = useState<any>();
  const [sorter, setSorter] = useState<any>();
  const [params, setParams] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: PAGE_SIZE,
    total: undefined,
    showSizeChanger: true,
  });
  const { setSpinner } = useSpinner();

  useEffect(() => {
    setMainTableLoading(true);
    setMainTableData([]);
    API.get(
      PlanningUrls.executionTableUrl +
        (allStage && site ? `&site__site_name=${site}` : "") +
        params
    )
      .then((res) => {
        if (res.status === 200) {
          setMainTableData(
            formatExecutionData(res.data ? res.data.results : [], "Planning")
          );
          setPagination((prev) => ({ ...prev, total: res.data.count }));
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setMainTableLoading(false));
  }, [params, reloadTable]);

    const download = async (
      fileName: string,
      phase: PhaseType,
      type: string
    ) => {
      setSpinner(true);
      await DownloadFile(DownloadUrls.downloadUrl, {
        fileName,
        phase,
        type,
      });
      setSpinner(false);
    };

  const clearFilter = () => {
    Object.keys(filters).forEach(
      (keys: string) => (filters[keys] = null)
    );
    setFilters(null);
    setParams(tableFilterParser({ pagination, filters, sorter: undefined }));
  }

  const tableChangeHandler = ({ pagination, filters, sorter }: any) => {
    setSorter(sorter);
    setPagination(pagination);
    Object.keys(filters).forEach((keys: string) =>
      filters[keys] !== null ? setFilters(filters) : undefined
    );
    setParams(tableFilterParser({ pagination, filters, sorter }));
  };

  return (
    <ExpandableTable
      className="execution-data-table"
      phase="Planning"
      expandability={true}
      tableProps={{
        onChange: (pagination, filters, sorter, extra) =>
          tableChangeHandler({ pagination, filters, sorter, extra }),
        dataSource: mainTableData,
        columns: columns(download),
        title: showTitle
          ? () => (
              <div className="flex flex--row">
                <Text className="flex--grow" title level={5}>
                  Execution Data
                </Text>
                {filters ? (
                  <Button
                    onClick={clearFilter}
                    size="small"
                    loading={mainTableLoading}
                    style={{ marginRight: "10px" }}
                  >
                    Clear Filter
                  </Button>
                ) : null}
                <Button
                  className="refresh-button"
                  onClick={setReloadTable}
                  type="primary"
                  size="small"
                  loading={mainTableLoading}
                >
                  Refresh
                </Button>
              </div>
            )
          : null,
        loading: mainTableLoading,
        pagination: pagination,
      }}
      expandedSelectionColumnValues={TaskTypeDropDownColumns}
    />
  );
};

export default PlanningTable;
