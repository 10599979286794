import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

import "./App.less";
import { AuthContext } from "./context/Auth";
import { SpinnerContext } from "./context/Spinner.context";
import MainLayout from "./pages";

function App() {
  return (
    <Router>
      <AuthContext>
        <SpinnerContext>
          <MainLayout />
        </SpinnerContext>
      </AuthContext>
    </Router>
  );
}

export default App;
