import { MenuItem } from "../../atoms";
import { DownloadManual } from "../../utils";
import { PhaseType, ManualType } from "../../types";
import {
  ProjectOutlined,
  BookOutlined,
  BugOutlined,
  HomeOutlined,
  DownloadOutlined,
  ControlOutlined,
  AppstoreAddOutlined,
} from "@ant-design/icons";
import { DOWNLOAD_TYPES } from "../../constants/downloadConstants";
import { EXE_DOWNLOAD_URLS } from "../../constants/downloadConstants";
import API from "../../services/API";
import { AuthUrls } from "../../services/apiUrls";
import { Modal } from "antd";
export const SSO_URL: any = process.env.REACT_APP_SSO_URL;

const download = (
  fileName: string,
  phase: PhaseType | ManualType,
  type: string
) => {
  DownloadManual(fileName, { phase, type });
};

const AdminRedirect = () => {
  const user = localStorage.getItem("user");
  if (user) {
    const userParsed = JSON.parse(user);
    API.post(AuthUrls.adminVerify, {
      redirection: true,
      user: userParsed?.name,
    })
      .then(() => {
        window.location.href = SSO_URL;
      })
      .catch(({ response }) => {
        Modal.error({
          title: "Access issue",
          content: response && response.data.Message
            ? response.data.Message
            : "Only admin users can visit config page"
        });
      });
  } else {
    Modal.error({
      title: "User error",
      content: "Failed to fetch user records",
    });
  }
};

export const HPEMenuItems: Array<MenuItem> = [
  {
    type: "menu-item",
    displayText: "Home",
    icon: HomeOutlined,
    key: "home",
    link: "/",
  },
  {
    type: "sub-menu",
    displayText: "Execution Data",
    icon: ControlOutlined,
    key: "execution-data",
    items: [
      {
        type: "menu-item",
        displayText: "Staging Execution Data",
        key: "staging-details",
        link: "/staging-details",
      },
      {
        type: "menu-item",
        displayText: "All Stage Data",
        key: "all-stage-details",
        link: "/all-stage-details",
      },
    ],
  },
  {
    type: "menu-item",
    displayText: "Active Schedule Jobs",
    icon: ProjectOutlined,
    key: "active-scheduled-jobs",
    link: "/active-jobs",
  },
  {
    type: "sub-menu",
    displayText: "User Manual",
    icon: BookOutlined,
    key: "user-manual",
    items: [
      {
        type: "sub-menu",
        displayText: "Stages",
        key: "stages",
        items: [
          {
            type: "menu-item",
            displayText: "Planning",
            key: "planning",
            onClickCallback: () =>
              download("Planning.docx", "Planning", DOWNLOAD_TYPES.MANUAL_FILE),
          },
          {
            type: "menu-item",
            displayText: "Pre-Staging",
            key: "pre-staging",
            onClickCallback: () =>
              download(
                "PreStaging.docx",
                "PreStaging",
                DOWNLOAD_TYPES.MANUAL_FILE
              ),
          },
          {
            type: "menu-item",
            displayText: "Staging",
            key: "staging",
            onClickCallback: () =>
              download("Staging.docx", "Staging", DOWNLOAD_TYPES.MANUAL_FILE),
          },
          {
            type: "menu-item",
            displayText: "Pre-Check",
            key: "pre-check",
            onClickCallback: () =>
              download("PreCheck.docx", "PreCheck", DOWNLOAD_TYPES.MANUAL_FILE),
          },
          {
            type: "menu-item",
            displayText: "Post-Check",
            key: "post-check",
            onClickCallback: () =>
              download(
                "PostCheck.docx",
                "PostCheck",
                DOWNLOAD_TYPES.MANUAL_FILE
              ),
          },
        ],
      },
      {
        type: "sub-menu",
        displayText: "Other Documents",
        key: "general",
        items: [
          {
            type: "menu-item",
            displayText: "User Roles",
            key: "user_roles",
            onClickCallback: () => {
              return download(
                "UserRoles.docx",
                "user_roles",
                DOWNLOAD_TYPES.MANUAL_FILE
              );
            },
          },
          {
            type: "menu-item",
            displayText: "All Stage Details",
            key: "all_stage_details",
            onClickCallback: () =>
              download(
                "AllStageDetails.docx",
                "all_stage_details",
                DOWNLOAD_TYPES.MANUAL_FILE
              ),
          },
          {
            type: "menu-item",
            displayText: "Active Scheduling Jobs",
            key: "active_scheduling_jobs",
            onClickCallback: () =>
              download(
                "ActiveSchedulingJobs.docx",
                "active_scheduling_jobs",
                DOWNLOAD_TYPES.MANUAL_FILE
              ),
          },
          {
            type: "menu-item",
            displayText: "Feedback",
            key: "feedback_doc",
            onClickCallback: () =>
              download("feedback.docx", "feedback", DOWNLOAD_TYPES.MANUAL_FILE),
          },
          {
            type: "menu-item",
            displayText: "Login",
            key: "login",
            onClickCallback: () =>
              download("login.docx", "login", DOWNLOAD_TYPES.MANUAL_FILE),
          },
        ],
      },
    ],
  },
  // {
  //   type: "menu-item",
  //   displayText: "Tutorial",
  //   icon: VideoCameraOutlined,
  //   key: "tutorial",
  //   link: "/tutorial",
  // },
  {
    type: "menu-item",
    displayText: "Feedback",
    icon: BugOutlined,
    key: "feedback",
    link: "/feedback",
  },
  {
    type: "sub-menu",
    displayText: "Download Latest EXE",
    icon: DownloadOutlined,
    key: "download",
    items: [
      {
        type: "menu-item",
        displayText: "Pre-check EXEs",
        key: "pre-check-download",
        onClickCallback: () => window.open(EXE_DOWNLOAD_URLS.PRECHECK),
      },
      {
        type: "menu-item",
        displayText: "Post-check EXEs",
        key: "post-check-download",
        onClickCallback: () => window.open(EXE_DOWNLOAD_URLS.POSTCHECK),
      },
    ],
  },
];

export const GXOMenuItems: Array<MenuItem> = [
  {
    type: "menu-item",
    displayText: "Home",
    icon: HomeOutlined,
    key: "home",
    link: "/",
  },
  {
    type: "menu-item",
    displayText: "Staging Execution Data",
    icon: ControlOutlined,
    key: "staging-details",
    link: "/staging-details",
  },
  {
    type: "menu-item",
    displayText: "Staging user manual",
    icon: BookOutlined,
    key: "User manual",
    onClickCallback: () =>
      download("Staging.docx", "Staging", DOWNLOAD_TYPES.MANUAL_FILE),
  },
  {
    type: "menu-item",
    displayText: "Feedback",
    icon: BugOutlined,
    key: "feedback",
    link: "/feedback",
  },
];

export const AdminMenuItems: Array<MenuItem> = [
  {
    type: "menu-item",
    displayText: "Home",
    icon: HomeOutlined,
    key: "home",
    link: "/",
  },
  {
    type: "sub-menu",
    displayText: "Execution Data",
    icon: ControlOutlined,
    key: "execution-data",
    items: [
      {
        type: "menu-item",
        displayText: "Staging Execution Data",
        key: "staging-details",
        link: "/staging-details",
      },
      {
        type: "menu-item",
        displayText: "All Stage Data",
        key: "all-stage-details",
        link: "/all-stage-details",
      },
    ],
  },
  {
    type: "menu-item",
    displayText: "Active Schedule Jobs",
    icon: ProjectOutlined,
    key: "active-scheduled-jobs",
    link: "/active-jobs",
  },
  {
    type: "sub-menu",
    displayText: "User Manual",
    icon: BookOutlined,
    key: "user-manual",
    items: [
      {
        type: "sub-menu",
        displayText: "Stages",
        key: "stages",
        items: [
          {
            type: "menu-item",
            displayText: "Planning",
            key: "planning",
            onClickCallback: () =>
              download("Planning.docx", "Planning", DOWNLOAD_TYPES.MANUAL_FILE),
          },
          {
            type: "menu-item",
            displayText: "Pre-Staging",
            key: "pre-staging",
            onClickCallback: () =>
              download(
                "PreStaging.docx",
                "PreStaging",
                DOWNLOAD_TYPES.MANUAL_FILE
              ),
          },
          {
            type: "menu-item",
            displayText: "Staging",
            key: "staging",
            onClickCallback: () =>
              download("Staging.docx", "Staging", DOWNLOAD_TYPES.MANUAL_FILE),
          },
          {
            type: "menu-item",
            displayText: "Pre-Check",
            key: "pre-check",
            onClickCallback: () =>
              download("PreCheck.docx", "PreCheck", DOWNLOAD_TYPES.MANUAL_FILE),
          },
          {
            type: "menu-item",
            displayText: "Post-Check",
            key: "post-check",
            onClickCallback: () =>
              download(
                "PostCheck.docx",
                "PostCheck",
                DOWNLOAD_TYPES.MANUAL_FILE
              ),
          },
        ],
      },
      {
        type: "sub-menu",
        displayText: "Other Documents",
        key: "general",
        items: [
          {
            type: "menu-item",
            displayText: "User Roles",
            key: "user_roles",
            onClickCallback: () => {
              return download(
                "UserRoles.docx",
                "user_roles",
                DOWNLOAD_TYPES.MANUAL_FILE
              );
            },
          },
          {
            type: "menu-item",
            displayText: "All Stage Details",
            key: "all_stage_details",
            onClickCallback: () =>
              download(
                "AllStageDetails.docx",
                "all_stage_details",
                DOWNLOAD_TYPES.MANUAL_FILE
              ),
          },
          {
            type: "menu-item",
            displayText: "Active Scheduling Jobs",
            key: "active_scheduling_jobs",
            onClickCallback: () =>
              download(
                "ActiveSchedulingJobs.docx",
                "active_scheduling_jobs",
                DOWNLOAD_TYPES.MANUAL_FILE
              ),
          },
          {
            type: "menu-item",
            displayText: "Feedback",
            key: "feedback_doc",
            onClickCallback: () =>
              download("feedback.docx", "feedback", DOWNLOAD_TYPES.MANUAL_FILE),
          },
          {
            type: "menu-item",
            displayText: "Login",
            key: "login",
            onClickCallback: () =>
              download("login.docx", "login", DOWNLOAD_TYPES.MANUAL_FILE),
          },
        ],
      },
    ],
  },
  // {
  //   type: "menu-item",
  //   displayText: "Tutorial",
  //   icon: VideoCameraOutlined,
  //   key: "tutorial",
  //   link: "/tutorial",
  // },
  {
    type: "menu-item",
    displayText: "Feedback",
    icon: BugOutlined,
    key: "feedback",
    link: "/feedback",
  },
  {
    type: "sub-menu",
    displayText: "Download Latest EXE",
    icon: DownloadOutlined,
    key: "download",
    items: [
      {
        type: "menu-item",
        displayText: "Pre-check EXEs",
        key: "pre-check-download",
        onClickCallback: () => window.open(EXE_DOWNLOAD_URLS.PRECHECK),
      },
      {
        type: "menu-item",
        displayText: "Post-check EXEs",
        key: "post-check-download",
        onClickCallback: () => window.open(EXE_DOWNLOAD_URLS.POSTCHECK),
      },
    ],
  },
  {
    type: "menu-item",
    displayText: "Admin Panel",
    icon: AppstoreAddOutlined,
    key: "admin-panel",
    onClickCallback: () => AdminRedirect(),
  },
];
