import { renderAllStageStatus } from "../../utils";
import { getColumnSearchProps } from "../../utils/getColumnSearch";

export const columns = [
  {
    title: "Site Name",
    dataIndex: "site_name",
    key: "site_name",
    sorter: true,
    width: 120,
    fixed: "left",
    ...getColumnSearchProps("site_name"),
  },
  {
    title: "Planning Status",
    dataIndex: "Planning",
    key: "planning_status",
    render: (data: any) => renderAllStageStatus(data),
  },
  {
    title: "Pre-Staging Status",
    dataIndex: "PreStaging",
    key: "pre_staging_status",
    render: (data: any) => renderAllStageStatus(data),
  },
  {
    title: "Staging Status",
    dataIndex: "Staging",
    key: "staging_status",
    render: (data: any) => renderAllStageStatus(data),
  },
  {
    title: "Pre-Check Status",
    dataIndex: "PreCheck",
    key: "pre_check_status",
    render: (data: any) => renderAllStageStatus(data),
  },
  {
    title: "Post-Check Status",
    dataIndex: "PostCheck",
    key: "post_check_status",
    render: (data: any) => renderAllStageStatus(data),
  },
];
