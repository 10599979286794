import { Modal } from "antd";
import FileSaver from "file-saver";
import API, { BASE_URL } from "../services/API";
import { PhaseType, ManualType } from "../types";
import { DownloadUrls } from "../services/apiUrls";

export const DownloadFile = async (
  url: string,
  data: {
    type: string;
    phase: PhaseType | ManualType;
    fileName: string;
  }
) => {
  try {
    const res = await API.get(
      url,
      {
        type: data.type,
        phase: data.phase.toLowerCase(),
        file_name: data.fileName,
      },
      {},
      true
    );
    let blob = new Blob([res.data]);
    FileSaver.saveAs(blob, data.fileName);
  } catch (err: any) {
    err.response &&
      Modal.error({
        title: "Error while Download",
        content:
          err.response && err.response.data.Message
            ? err.response.data.Message
            : "Server Error",
      });
  }
};

export const DownloadDb = async (
  url: string,
  data: {
    fileName: string;
    siteList: string[];
    user: string | undefined;
  }
) => {
  try {
    API.get(
      url + `?site_list=${JSON.stringify(data.siteList)}&user=${data?.user}`
    );
    FileSaver.saveAs(
      BASE_URL +
        url +
        `?site_list=${JSON.stringify(data.siteList)}&user=${data?.user}`,
      data.fileName
    );
  } catch (err: any) {
    err.response &&
      Modal.error({
        title: "Error while Download",
        content:
          err.response && err.response.data.Message
            ? err.response.data.Message
            : "Server Error",
      });
  }
};

export const DownloadManual = async (
  fileName: string,
  data: {
    phase: PhaseType | ManualType;
    type: string;
  }
) => {
  try {
    const res = await API.get(
      DownloadUrls.manualDownload +
        `?phase=${data?.phase?.toLowerCase()}&type=${data?.type.toLowerCase()}`,
      {},
      {},
      true
    );
    let blob = new Blob([res.data]);
    FileSaver.saveAs(blob, fileName);
  } catch (err: any) {
    err.response &&
      Modal.error({
        title: "Error while Downloading the manual",
        content:
          err.response && err.response.data.Message
            ? err.response.data.Message
            : "Server Error",
      });
  }
};
